import api from './axios';
import type { Reward, RewardFilters } from '@/types/reward';

export const rewardService = {
  async getRewards(filters?: RewardFilters) {
    const { data } = await api.get<{ data: Reward[] }>('/rewards', { params: filters });
    return data;
  },

  async claimReward(id: string) {
    const { data } = await api.post(`/rewards/${id}/claim`);
    return data;
  },

  async checkAchievements() {
    const { data } = await api.post('/rewards/check-achievements');
    return data;
  },
};