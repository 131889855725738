import api from './axios';
import type { Settings } from '@/types/settings';
import type { ApiResponse } from '@/types/response';

export const settingsService = {
  async getSettings() {
    try {
      const { data } = await api.get<ApiResponse<Settings>>('/settings');
      return data;
    } catch (error) {
      console.error('Get settings error:', error);
      throw error;
    }
  },

  async getUserSettings(userId: string) {
    try {
      const { data } = await api.get<ApiResponse<Settings>>(`/admin/users/${userId}/settings`);
      return data;
    } catch (error) {
      console.error('Get user settings error:', error);
      throw error;
    }
  },

  async updateCurrency(currency: string) {
    try {
      const { data } = await api.put<ApiResponse<Settings>>('/settings/currency', { currency });
      return data;
    } catch (error) {
      console.error('Update currency error:', error);
      throw error;
    }
  },

  async updateEmailPreferences(preferences: {
    marketing: boolean;
    newsletter: boolean;
    updates: boolean;
  }) {
    try {
      const { data } = await api.put<ApiResponse<Settings>>('/settings/email-preferences', preferences);
      return data;
    } catch (error) {
      console.error('Update email preferences error:', error);
      throw error;
    }
  },

  async updateNotifications(notifications: {
    email: boolean;
    push: boolean;
    sms: boolean;
  }) {
    try {
      const { data } = await api.put<ApiResponse<Settings>>('/settings/notifications', { notifications });
      return data;
    } catch (error) {
      console.error('Update notifications error:', error);
      throw error;
    }
  },

  async updatePaymentPreferences(paymentDetails: {
    method: 'bank' | 'crypto';
    details: {
      bank?: {
        accountName: string;
        accountNumber: string;
        routingNumber: string;
        bankName: string;
      };
      crypto?: {
        walletAddress: string;
        network: string;
      };
    };
  }) {
    try {
      const { data } = await api.put<ApiResponse<Settings>>('/settings/payment-preferences', paymentDetails);
      return data;
    } catch (error) {
      console.error('Update payment preferences error:', error);
      throw error;
    }
  }
};