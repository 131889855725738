import { ethers } from 'ethers';
import type { TransactionLog } from '../types';

export function findTransferEvent(
  receipt: ethers.providers.TransactionReceipt,
  contract: ethers.Contract,
  to: string
): TransactionLog | undefined {
  return receipt.logs.find((log: TransactionLog) => {
    try {
      const parsed = contract.interface.parseLog(log);
      return parsed?.name === 'Transfer' &&
             parsed.args.from === ethers.constants.AddressZero &&
             parsed.args.to.toLowerCase() === to.toLowerCase();
    } catch {
      return false;
    }
  });
}