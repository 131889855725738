import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAtom } from 'jotai';
import { authAtom } from '../store';
import { authService } from '@/services/api/auth';
import { userService } from '@/services/api/user';
import { referralService } from '@/services/api/referrals';
import { getSignedUrl } from '@/utils/s3';
import toast from 'react-hot-toast';
import type { User } from '@/types/user';
import type { LoginCredentials, SignupData, AuthResponse } from '@/types/auth';

export function useAuth() {
  const [auth, setAuth] = useAtom(authAtom);
  const navigate = useNavigate();

  const updateAuthState = useCallback((newState: Partial<AuthResponse>) => {
    setAuth(prev => ({ ...prev, ...newState }));
  }, [setAuth]);

  const clearAuthData = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('verificationEmail');
    setAuth({
      user: null,
      isAuthenticated: false,
      token: null,
      verificationEmail: null,
      referralCode: null // Add this
    });
  }, [setAuth]);

  const updateUser = useCallback(async (userData: Partial<User>) => {
    setAuth(prev => {
      if (!prev.user) return prev;
      
      const updatedUser = {
        ...prev.user,
        ...userData,
        role: prev.user.role,
        isVerified: prev.user.isVerified,
        referralCode: prev.user.referralCode,
      };

      localStorage.setItem('user', JSON.stringify(updatedUser));
      
      return {
        ...prev,
        user: updatedUser
      };
    });
  }, [setAuth]);

  const login = async (credentials: LoginCredentials) => {
    try {
      const response = await authService.login(credentials);
      if (response.success && response.token && response.user) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify(response.user));
        
        // Get signed URL for avatar if it exists
        let userWithAvatar = response.user;
        if (response.user.avatar && response.user.avatar.includes('amazonaws.com')) {
          try {
            const signedUrl = await getSignedUrl(response.user.avatar);
            userWithAvatar = { ...response.user, avatar: signedUrl };
          } catch (error) {
            console.error('Failed to get signed avatar URL:', error);
          }
        }

        setAuth({
          user: userWithAvatar,
          isAuthenticated: true,
          token: response.token,
          verificationEmail: null,
          referralCode: null // Add this
        });

        return response;
      }
      throw new Error(response.message || 'Login failed');
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  const signup = async (data: SignupData) => {
    try {
      const response = await authService.signup(data);
      if (response.success) {
        // Store email for verification
        localStorage.setItem('verificationEmail', data.email);
        updateAuthState({ 
          verificationEmail: data.email,
          referralCode: data.referralCode // Store referral code
        });
        return response;
      }
      throw new Error(response.message || 'Registration failed');
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  };

  const verifyEmail = async (otp: string) => {
    const email = auth.verificationEmail || localStorage.getItem('verificationEmail');
    if (!email) {
      throw new Error('Verification email not found');
    }

    try {
      const response = await authService.verifyOTP(email, otp);
      if (response.success && response.token && response.user) {
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify(response.user));
        localStorage.removeItem('verificationEmail');

        // Track referral if code exists
        const referralCode = auth.referralCode;
        if (referralCode && response.user.id) {
          try {
            await referralService.trackReferralSignup(referralCode, response.user.id);
          } catch (error) {
            console.error('Failed to track referral:', error);
            // Don't block verification if referral tracking fails
          }
        }

        setAuth({
          user: response.user,
          isAuthenticated: true,
          token: response.token,
          verificationEmail: null,
          referralCode: null
        });

        // Navigate to dashboard after successful verification
        navigate('/dashboard', { replace: true });
        return response;
      }
      throw new Error(response.message || 'Verification failed');
    } catch (error) {
      console.error('Verify email error:', error);
      throw error;
    }
  };

  return {
    user: auth.user,
    isAuthenticated: auth.isAuthenticated,
    verificationEmail: auth.verificationEmail,
    updateAuthState,
    updateUser,
    clearAuthData,
    login,
    signup,
    verifyEmail,
    logout: () => {
      authService.logout();
      clearAuthData();
      toast.success('Successfully logged out');
      navigate('/login');
    }
  };
}