import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Save, Loader } from 'lucide-react';
import { 
  PropertyBasicInfo,
  PropertyLocation,
  PropertyFinancials,
  PropertyImages,
  PropertyFeatures,
  PropertyDocuments,
  PropertyFinancialsForm
} from '.';
import { adminService } from '@/services/api/admin';
import { documentService } from '@/services/api/properties/documents';
import toast from 'react-hot-toast';
import type { PropertyFormData } from '@/types/property/form';
import type { DocumentFormData } from '@/types/document';
import { transformPropertyToFormData } from '@/utils/property-transforms';

const initialFormData: PropertyFormData = {
  title: '',
  description: {
    summary: '',
    full: '',
    highlights: []
  },
  location: {
    address: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    coordinates: {
      latitude: 0,
      longitude: 0
    }
  },
  type: 'residential',
  status: 'active',
  targetAmount: 0,
  raisedAmount: 0,
  minInvestment: 0,
  maxInvestment: 0,
  investors: 0,
  expectedReturn: 0,
  features: [],
  amenities: [],
  images: [],
  purchasePrice: 0,
  currentValue: 0,
  financials: {
    purchasePrice: 0,
    currentValue: 0,
    expectedValue: 0,
    rentalIncome: {
      monthly: 0,
      occupancyRate: 0,
      netOperatingIncome: 0
    },
    expenses: []
  }
};

export function PropertyForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<PropertyFormData>(initialFormData);
  const [images, setImages] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [documents, setDocuments] = useState<DocumentFormData[]>([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchProperty = async () => {
      if (!id) return;
  
      try {
        setIsLoading(true);
        const response = await adminService.getProperty(id);
        if (response.success && response.data) {
          const transformedData = transformPropertyToFormData(response.data);
          setFormData(transformedData);
          setImageUrls(response.data.images || []);
        }
      } catch (error) {
        console.error('Failed to load property:', error);
        toast.error('Failed to load property');
        navigate('/admin/properties');
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProperty();
  }, [id, navigate]);

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    if (!formData.title) newErrors.title = 'Title is required';
    if (!formData.description?.summary) newErrors['description.summary'] = 'Summary is required';
    if (!formData.description?.full) newErrors['description.full'] = 'Full description is required';
    if (!formData.type) newErrors.type = 'Property type is required';
    if (!formData.location?.address) newErrors['location.address'] = 'Address is required';
    if (!formData.location?.city) newErrors['location.city'] = 'City is required';
    if (!formData.location?.state) newErrors['location.state'] = 'State is required';
    if (!formData.location?.country) newErrors['location.country'] = 'Country is required';
    if (!formData.location?.zipCode) newErrors['location.zipCode'] = 'ZIP code is required';
    if (!formData.purchasePrice) newErrors.purchasePrice = 'Purchase price is required';
    if (!formData.targetAmount) newErrors.targetAmount = 'Target amount is required';
    if (!formData.minInvestment) newErrors.minInvestment = 'Minimum investment is required';
    if (!formData.maxInvestment) newErrors.maxInvestment = 'Maximum investment is required';
    if (formData.maxInvestment < formData.minInvestment) {
      newErrors.maxInvestment = 'Maximum investment must be greater than minimum investment';
    }
    if (!formData.expectedReturn) newErrors.expectedReturn = 'Expected return is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsSaving(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('data', JSON.stringify(formData));
      
      images.forEach(image => {
        formDataToSend.append('images', image);
      });

      let response;
      if (id) {
        response = await adminService.updateProperty(id, formDataToSend);
      } else {
        response = await adminService.createProperty(formDataToSend);
      }

      if (!response.success) {
        throw new Error(response.message || `Failed to ${id ? 'update' : 'create'} property`);
      }

      const propertyId = id || response.data?.id;
      if (propertyId && documents.length > 0) {
        await Promise.all(documents.map(async (doc) => {
          if (!doc.file) return;

          const docFormData = new FormData();
          docFormData.append('title', doc.title);
          docFormData.append('type', doc.type);
          docFormData.append('document', doc.file);
          docFormData.append('isPublic', String(doc.isPublic));
          
          await documentService.uploadDocument(propertyId, docFormData);
        }));
      }

      toast.success(id ? 'Property updated successfully' : 'Property created successfully');
      navigate('/admin/properties');
    } catch (error) {
      console.error('Property form error:', error);
      toast.error(id ? 'Failed to update property' : 'Failed to create property');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
        <div className="h-96 bg-gray-200 rounded-xl"></div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="max-w-4xl mx-auto space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">
          {id ? 'Edit Property' : 'Add New Property'}
        </h1>
      </div>

      <PropertyBasicInfo
        formData={formData}
        onChange={setFormData}
        errors={errors}
      />

      <PropertyLocation
        formData={formData}
        onChange={setFormData}
        errors={errors}
      />

      <PropertyFinancials
        formData={formData}
        onChange={setFormData}
        errors={errors}
      />

      <PropertyFinancialsForm
        formData={formData}
        onChange={setFormData}
        errors={errors}
      />

      <PropertyImages
        images={images}
        imageUrls={imageUrls}
        onImagesChange={setImages}
        errors={errors}
      />

      <PropertyFeatures
        formData={formData}
        onChange={setFormData}
        errors={errors}
      />

      <PropertyDocuments
        documents={documents}
        onDocumentsChange={setDocuments}
        errors={errors}
      />

      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={() => navigate('/admin/properties')}
          className="px-6 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={isSaving}
          className="px-6 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 disabled:opacity-50 flex items-center gap-2"
        >
          {isSaving ? (
            <>
              <Loader className="w-4 h-4 animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="w-4 h-4" />
              {id ? 'Update Property' : 'Create Property'}
            </>
          )}
        </button>
      </div>
    </form>
  );
}