import { useState, useEffect, useCallback } from 'react';
import { investmentService } from '@/services/api/investments';
import type { Investment } from '@/types/investment';
import toast from 'react-hot-toast';

export function useInvestments() {
  const [investments, setInvestments] = useState<Investment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('All');
  const [sortBy, setSortBy] = useState('value');

  const fetchInvestments = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await investmentService.getInvestments();
      
      if (!response.success || !Array.isArray(response.data)) {
        throw new Error(response.message || 'Failed to load investments');
      }

      setInvestments(response.data);
    } catch (error) {
      console.error('Failed to load investments:', error);
      setError(error instanceof Error ? error.message : 'Failed to load investments');
      toast.error('Failed to load investments');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInvestments();
  }, [fetchInvestments]);

  const filteredInvestments = investments
    .filter(investment => {
      if (!investment?.property) return false;

      const locationString = typeof investment.property.location === 'string'
        ? investment.property.location
        : `${investment.property.location.city}, ${investment.property.location.state}`;

      const matchesSearch = 
        investment.property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        locationString.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesType = selectedType === 'All' || 
                        investment.property.type.toLowerCase() === selectedType.toLowerCase();
      
      return matchesSearch && matchesType;
    })
    .sort((a, b) => {
      if (!a || !b) return 0;

      switch (sortBy) {
        case 'value':
          return Number(b.currentValue) - Number(a.currentValue);
        case 'return':
          return Number(b.returnToDate) - Number(a.returnToDate);
        case 'date':
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        default:
          return 0;
      }
    });

  return {
    investments,
    isLoading,
    error,
    filteredInvestments,
    searchTerm,
    setSearchTerm,
    selectedType,
    setSelectedType,
    sortBy,
    setSortBy,
    refreshInvestments: fetchInvestments
  };
}