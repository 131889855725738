// Network-specific contract addresses
export const NFT_CONTRACT_ADDRESSES: Record<number, string> = {
  1: import.meta.env.VITE_ETH_NFT_CONTRACT || '0x86aE19310f31ad7780177078c7cE3ED85D6B5Dd6', // Ethereum Mainnet
  137: import.meta.env.VITE_POLYGON_NFT_CONTRACT || '0x86aE19310f31ad7780177078c7cE3ED85D6B5Dd6', // Polygon Mainnet
  56: import.meta.env.VITE_BSC_NFT_CONTRACT || '0x3041AadE455d62b8A0Bc3623912247B2C5519b72'  // BNB Smart Chain
};

// Default contract address for fallback
export const DEFAULT_NFT_CONTRACT = '0x86aE19310f31ad7780177078c7cE3ED85D6B5Dd6';

// Use the actual ABI from the contract
export const NFT_CONTRACT_ABI = [
  {
    "inputs": [],
    "name": "name",
    "outputs": [{"internalType": "string", "name": "", "type": "string"}],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {"internalType": "address", "name": "to", "type": "address"},
      {"internalType": "string", "name": "uri", "type": "string"}
    ],
    "name": "safeMint",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
      {"indexed": true, "internalType": "address", "name": "from", "type": "address"},
      {"indexed": true, "internalType": "address", "name": "to", "type": "address"},
      {"indexed": true, "internalType": "uint256", "name": "tokenId", "type": "uint256"}
    ],
    "name": "Transfer",
    "type": "event"
  }
];