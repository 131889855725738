import React from 'react';
import { X } from 'lucide-react';
import { DocumentPreview } from './DocumentPreview';
import type { VerificationRequest } from '@/types/verification';

interface VerificationModalProps {
  request: VerificationRequest;
  rejectionReason: string;
  onReasonChange: (reason: string) => void;
  onApprove: (userId: string) => void;
  onReject: (userId: string) => void;
  onClose: () => void;
  isSubmitting: boolean;
}

export function VerificationModal({
  request,
  rejectionReason,
  onReasonChange,
  onApprove,
  onReject,
  onClose,
  isSubmitting
}: VerificationModalProps) {
  // Log the request data for debugging
  console.log('Verification request in modal:', request);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose}></div>
      <div className="relative bg-white rounded-xl shadow-xl max-w-3xl w-full mx-4 p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h3 className="text-xl font-semibold">Verification Documents</h3>
            <p className="text-sm text-gray-600 mt-1">
              Submitted by {request.userName} ({request.userEmail})
            </p>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="grid md:grid-cols-2 gap-6">
          <div>
            <h4 className="font-medium mb-2">ID Document</h4>
            <DocumentPreview
              document={request.documents?.idDocument}
              title="ID Document"
              className="h-full"
            />
          </div>
          <div>
            <h4 className="font-medium mb-2">Proof of Address</h4>
            <DocumentPreview
              document={request.documents?.proofOfAddress}
              title="Proof of Address"
              className="h-full"
            />
          </div>
        </div>

        {request.status === 'pending' && (
          <div className="mt-6 pt-6 border-t border-gray-200">
            <div className="flex gap-4">
              <button
                onClick={() => onApprove(request.userId)}
                disabled={isSubmitting}
                className="flex-1 bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition-colors disabled:opacity-50"
              >
                {isSubmitting ? 'Processing...' : 'Approve'}
              </button>
              <div className="flex-1">
                <textarea
                  placeholder="Reason for rejection..."
                  value={rejectionReason}
                  onChange={(e) => onReasonChange(e.target.value)}
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-red-500 focus:border-red-500 mb-2"
                  rows={2}
                />
                <button
                  onClick={() => onReject(request.userId)}
                  disabled={isSubmitting || !rejectionReason}
                  className="w-full bg-red-600 text-white py-2 rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? 'Processing...' : 'Reject'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}