import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Image as ImageIcon } from 'lucide-react';
import { S3Image } from '@/components/S3Image';

interface PropertyImageCarouselProps {
  images: string[];
  title: string;
  className?: string;
  linkTo?: string;
  publicAccess?: boolean;
}

export function PropertyImageCarousel({ 
  images = [], 
  title, 
  className = '',
  linkTo,
  publicAccess = true
}: PropertyImageCarouselProps) {
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();

  if (!images.length) {
    return (
      <div className={`rounded-xl overflow-hidden bg-gray-100 flex items-center justify-center ${className}`}>
        <div className="text-center">
          <ImageIcon className="w-12 h-12 text-gray-400 mx-auto mb-2" />
          <p className="text-gray-500">No images available</p>
        </div>
      </div>
    );
  }

  const handleClick = (e: React.MouseEvent) => {
    if (linkTo) {
      e.preventDefault();
      navigate(linkTo);
    }
  };

  const handleNavigation = (e: React.MouseEvent, direction: 'prev' | 'next') => {
    e.preventDefault();
    e.stopPropagation();
    
    if (direction === 'prev') {
      setCurrentImage(prev => (prev === 0 ? images.length - 1 : prev - 1));
    } else {
      setCurrentImage(prev => (prev === images.length - 1 ? 0 : prev + 1));
    }
  };

  return (
    <div 
      className={`relative ${className} ${linkTo ? 'cursor-pointer' : ''}`}
      onClick={handleClick}
    >
      <S3Image
        src={images[currentImage]}
        alt={`${title} - Image ${currentImage + 1}`}
        className="w-full h-full object-cover rounded-t-xl"
        publicAccess={publicAccess}
      />
      
      {images.length > 1 && (
        <>
          <button
            onClick={(e) => handleNavigation(e, 'prev')}
            className="absolute left-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            <ChevronLeft className="w-5 h-5" />
          </button>
          <button
            onClick={(e) => handleNavigation(e, 'next')}
            className="absolute right-2 top-1/2 -translate-y-1/2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
          >
            <ChevronRight className="w-5 h-5" />
          </button>

          <div className="absolute bottom-2 left-1/2 -translate-x-1/2 bg-black/50 text-white text-sm px-3 py-1 rounded-full">
            {currentImage + 1} / {images.length}
          </div>
        </>
      )}
    </div>
  );
}