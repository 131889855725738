import React from 'react';
import { CreditCard, Bitcoin } from 'lucide-react';

interface PaymentMethodSelectorProps {
  method: 'stripe' | 'crypto';
  onChange: (method: 'stripe' | 'crypto') => void;
  disabled?: boolean;
}

export function PaymentMethodSelector({
  method,
  onChange,
  disabled = false
}: PaymentMethodSelectorProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Payment Method
      </label>
      <div className="grid grid-cols-2 gap-4">
        <button
          type="button"
          onClick={() => onChange('stripe')}
          disabled={disabled}
          className={`flex items-center justify-center gap-2 p-4 rounded-lg border ${
            method === 'stripe'
              ? 'border-primary-500 bg-primary-50 text-primary-700'
              : 'border-gray-200 hover:border-primary-500'
          } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <CreditCard className="w-5 h-5" />
          <span>Card</span>
        </button>
        <button
          type="button"
          onClick={() => onChange('crypto')}
          disabled={disabled}
          className={`flex items-center justify-center gap-2 p-4 rounded-lg border ${
            method === 'crypto'
              ? 'border-primary-500 bg-primary-50 text-primary-700'
              : 'border-gray-200 hover:border-primary-500'
          } ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
          <Bitcoin className="w-5 h-5" />
          <span>Crypto</span>
        </button>
      </div>
    </div>
  );
}