import { adminPropertyService } from './admin/properties/index';
import { settingsService } from './admin/settings';
import { verificationService } from './admin/verifications';
import { statsService } from './admin/stats';
import { userService } from './admin/users';

export const adminService = {
  ...adminPropertyService,
  ...settingsService,
  ...verificationService,
  ...statsService,
  ...userService
};

export type { Property } from '@/types/property';
export type { Investment } from '@/types/investment';
export type { AdminSettings, AdminStats } from '@/types/admin';
export type { VerificationRequest } from '@/types/verification';