import React, { useState } from 'react';
import { Save } from 'lucide-react';
import { settingsService } from '@/services/api/settings';
import { PaymentMethodSelector } from './payment/PaymentMethodSelector';
import { BankTransferForm } from './payment/BankTransferForm';
import { CryptoWalletForm } from './payment/CryptoWalletForm';
import type { Settings } from '@/types/settings';
import type { BankDetails, CryptoDetails } from './payment/types';
import toast from 'react-hot-toast';

interface PaymentPreferencesProps {
  settings: Settings | null;
  onUpdate: (settings: Partial<Settings>) => void;
}

export function PaymentPreferences({ settings, onUpdate }: PaymentPreferencesProps) {
  const [paymentMethod, setPaymentMethod] = useState<'bank' | 'crypto'>(settings?.paymentMethod || 'bank');
  const [selectedCountry, setSelectedCountry] = useState('US');
  const [bankDetails, setBankDetails] = useState<BankDetails>({
    accountName: settings?.paymentDetails?.bank?.accountName || '',
    accountNumber: settings?.paymentDetails?.bank?.accountNumber || '',
    bankName: settings?.paymentDetails?.bank?.bankName || '',
    country: selectedCountry // Use selectedCountry state
  });
  const [cryptoDetails, setCryptoDetails] = useState<CryptoDetails>(settings?.paymentDetails?.crypto || {
    walletAddress: '',
    network: 'ethereum'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const paymentDetails = {
        method: paymentMethod,
        details: {
          [paymentMethod]: paymentMethod === 'bank' ? bankDetails : cryptoDetails
        }
      };

      const response = await settingsService.updatePaymentPreferences(paymentDetails);
      if (response.success) {
        onUpdate({ 
          paymentMethod,
          paymentDetails: {
            [paymentMethod]: paymentMethod === 'bank' ? bankDetails : cryptoDetails
          }
        });
        toast.success('Payment preferences updated successfully');
      }
    } catch (error) {
      toast.error('Failed to update payment preferences');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">Payment Preferences</h3>
        <p className="text-gray-600 mt-1">
          Choose how you want to receive your dividend payments
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <PaymentMethodSelector 
          method={paymentMethod} 
          onChange={setPaymentMethod} 
        />

        {paymentMethod === 'bank' ? (
          <BankTransferForm
            bankDetails={bankDetails}
            selectedCountry={selectedCountry}
            onBankDetailsChange={(field, value) => setBankDetails(prev => ({ ...prev, [field]: value }))}
            onCountryChange={setSelectedCountry}
          />
        ) : (
          <CryptoWalletForm
            cryptoDetails={cryptoDetails}
            onCryptoDetailsChange={(field, value) => setCryptoDetails(prev => ({ ...prev, [field]: value }))}
          />
        )}

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="w-5 h-5" />
              Save Payment Preferences
            </>
          )}
        </button>
      </form>
    </div>
  );
}