import React from 'react';
import { DollarSign, TrendingUp, Clock, Target } from 'lucide-react';
import { formatMilestoneValue } from '@/utils/milestone-calculations';
import type { MilestoneCategory } from '@/types/milestone';

const categoryIcons = {
  investment_value: DollarSign,
  portfolio_returns: TrendingUp,
  investment_duration: Clock,
  investment_diversity: Target
} as const;

const categoryColors = {
  investment_value: {
    bg: 'bg-primary-100',
    text: 'text-primary-600',
    progressBg: 'bg-primary-600',
    markerBg: 'bg-primary-600',
    markerBorder: 'border-primary-200'
  },
  portfolio_returns: {
    bg: 'bg-green-100',
    text: 'text-green-600',
    progressBg: 'bg-green-600',
    markerBg: 'bg-green-600',
    markerBorder: 'border-green-200'
  },
  investment_duration: {
    bg: 'bg-purple-100',
    text: 'text-purple-600',
    progressBg: 'bg-purple-600',
    markerBg: 'bg-purple-600',
    markerBorder: 'border-purple-200'
  },
  investment_diversity: {
    bg: 'bg-amber-100',
    text: 'text-amber-600',
    progressBg: 'bg-amber-600',
    markerBg: 'bg-amber-600',
    markerBorder: 'border-amber-200'
  }
} as const;

interface MilestoneCardProps {
  category: MilestoneCategory;
}

export function MilestoneCard({ category }: MilestoneCardProps) {
  const Icon = categoryIcons[category.category];
  const colorClasses = categoryColors[category.category];

  return (
    <div className="bg-gray-50 rounded-xl p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <div className={`p-2 rounded-lg ${colorClasses.bg}`}>
            <Icon className={`w-5 h-5 ${colorClasses.text}`} />
          </div>
          <div>
            <h4 className="font-semibold capitalize">
              {category.category.replace(/_/g, ' ')}
            </h4>
            <p className="text-sm text-gray-600">
              Current: {formatMilestoneValue(category.currentValue, category.category)}
            </p>
          </div>
        </div>
        {category.nextMilestone && (
          <div className="text-right">
            <div className="text-sm text-gray-600">Next Milestone</div>
            <div className={`font-semibold ${colorClasses.text}`}>
              {formatMilestoneValue(category.nextMilestone, category.category)}
            </div>
          </div>
        )}
      </div>

      <div className="relative">
        {/* Progress Bar */}
        <div className="h-2 bg-gray-200 rounded-full mb-8">
          <div
            className={`h-full ${colorClasses.progressBg} rounded-full transition-all duration-500`}
            style={{ width: `${Math.max(0, Math.min(category.progress, 100))}%` }}
          />
        </div>

        {/* Milestone Markers */}
        <div className="grid grid-cols-5 gap-4">
          {category.milestones.map((milestone, index) => (
            <div
              key={index}
              className={`text-center ${
                milestone.reached ? colorClasses.text : 'text-gray-400'
              }`}
            >
              <div className="relative mb-2">
                <div
                  className={`w-4 h-4 rounded-full mx-auto ${
                    milestone.reached
                      ? colorClasses.markerBg
                      : 'bg-gray-300'
                  }`}
                />
                {milestone.reached && (
                  <div
                    className={`absolute -inset-1 border-2 ${colorClasses.markerBorder} rounded-full animate-ping`}
                  />
                )}
              </div>
              <div className="text-sm font-medium mb-1">
                {formatMilestoneValue(milestone.value, category.category)}
              </div>
              <div className="text-xs text-gray-500">{milestone.reward}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}