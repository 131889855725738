import React from 'react';

interface PropertyStatsProps {
  minInvestment: number;
  investors: number;
  expectedReturn: number;
}

export function PropertyStats({ minInvestment, investors, expectedReturn }: PropertyStatsProps) {
  return (
    <div className="grid grid-cols-3 gap-4">
      <div>
        <div className="text-sm text-gray-600">Min. Investment</div>
        <div className="font-semibold">${minInvestment.toLocaleString()}</div>
      </div>
      <div>
        <div className="text-sm text-gray-600">Investors</div>
        <div className="font-semibold">{investors}</div>
      </div>
      <div>
        <div className="text-sm text-gray-600">Returns</div>
        <div className="font-semibold text-primary-600">{expectedReturn}%</div>
      </div>
    </div>
  );
}