import { useCallback, useState } from 'react';
import { useConnect, useDisconnect, useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { supportedChains } from '@/config/web3';
import toast from 'react-hot-toast';

export function useWallet() {
  const [isConnecting, setIsConnecting] = useState(false);
  const { connect, connectors, isLoading: wagmiLoading } = useConnect({
    onSuccess() {
      toast.success('Wallet connected successfully');
      setIsConnecting(false);
    },
    onError(error: Error) {
      setIsConnecting(false);
      if (error.name !== 'UserRejectedRequestError') {
        toast.error('Failed to connect wallet');
      }
    },
  });

  const { disconnect } = useDisconnect();
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const handleConnect = useCallback(async (chainId?: number) => {
    if (isConnecting) return;
    
    try {
      setIsConnecting(true);
      const connector = connectors[0];
      if (connector) {
        await connect({ connector });

        // Switch network if specified and different from current
        if (chainId && chain?.id !== chainId && switchNetwork) {
          await switchNetwork(chainId);
        }
      }
    } catch (error) {
      setIsConnecting(false);
      if (error instanceof Error && error.name !== 'UserRejectedRequestError') {
        toast.error('Failed to connect wallet');
      }
    }
  }, [connect, connectors, isConnecting, chain, switchNetwork]);

  const handleDisconnect = useCallback(() => {
    try {
      disconnect();
      toast.success('Wallet disconnected');
    } catch (error) {
      toast.error('Failed to disconnect wallet');
    }
  }, [disconnect]);

  const switchToNetwork = useCallback(async (chainId: number) => {
    if (!switchNetwork) {
      toast.error('Network switching not supported');
      return;
    }

    try {
      await switchNetwork(chainId);
    } catch (error) {
      console.error('Failed to switch network:', error);
      toast.error('Failed to switch network');
    }
  }, [switchNetwork]);

  return {
    connect: handleConnect,
    disconnect: handleDisconnect,
    switchNetwork: switchToNetwork,
    isLoading: isConnecting || wagmiLoading,
    isConnected,
    address,
    chain,
    supportedChains
  };
}