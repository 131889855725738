import { useState, useMemo } from 'react';
import type { ChartData, ChartOptions } from 'chart.js';
import type { Investment } from '@/types/investment';
import type { ChartInvestment } from '@/types/investment/chart';

interface Insight {
  message: string;
  colorClass: string;
}

export function usePortfolioChart(investments: Investment[]) {
  const [selectedInvestment, setSelectedInvestment] = useState<Investment | null>(null);

  const {
    chartData,
    chartOptions,
    totalValue,
    averageReturn,
    highestReturn,
    lowestReturn,
    insights,
    chartInvestments
  } = useMemo(() => {
    // Calculate total value
    const total = investments.reduce((sum, inv) => sum + Number(inv.currentValue), 0);

    // Transform investments for chart
    const transformedInvestments: ChartInvestment[] = investments.map(inv => ({
      id: inv.id,
      title: inv.property?.title || 'Unknown Property',
      currentValue: Number(inv.currentValue),
      allocation: total > 0 ? Math.round((Number(inv.currentValue) / total) * 100) : 0,
      returnToDate: Number(inv.returnToDate),
      location: inv.property?.location || 'Unknown Location',
      image: inv.property?.images?.[0] || ''
    }));

    // Calculate returns
    const returns = investments.map(inv => Number(inv.returnToDate));
    const avgReturn = returns.length > 0 
      ? returns.reduce((sum, ret) => sum + ret, 0) / returns.length 
      : 0;
    const maxReturn = returns.length > 0 ? Math.max(...returns) : 0;
    const minReturn = returns.length > 0 ? Math.min(...returns) : 0;

    // Generate insights
    const maxAllocation = Math.max(...transformedInvestments.map(inv => inv.allocation));
    const highPerformers = investments.filter(inv => Number(inv.returnToDate) > avgReturn);

    const insightsList: Insight[] = [
      maxAllocation > 40 && {
        message: 'Consider diversifying your portfolio to reduce risk. Some properties have high allocation percentages.',
        colorClass: 'text-amber-500'
      },
      highPerformers.length > 0 && {
        message: `${highPerformers.length} properties are performing above average (${avgReturn.toFixed(1)}% return)`,
        colorClass: 'text-green-500'
      },
      investments.length < 5 && {
        message: 'Adding more properties to your portfolio could help spread risk and increase potential returns.',
        colorClass: 'text-primary-500'
      }
    ].filter((insight): insight is Insight => Boolean(insight));

    const chartData: ChartData<'doughnut'> = {
      labels: transformedInvestments.map(inv => inv.title),
      datasets: [{
        data: transformedInvestments.map(inv => inv.allocation),
        backgroundColor: [
          'rgba(79, 70, 229, 0.8)',
          'rgba(16, 185, 129, 0.8)',
          'rgba(245, 158, 11, 0.8)',
          'rgba(239, 68, 68, 0.8)',
          'rgba(147, 51, 234, 0.8)',
        ],
        borderColor: [
          'rgba(79, 70, 229, 1)',
          'rgba(16, 185, 129, 1)',
          'rgba(245, 158, 11, 1)',
          'rgba(239, 68, 68, 1)',
          'rgba(147, 51, 234, 1)',
        ],
        borderWidth: 1,
        hoverOffset: 4
      }]
    };

    const chartOptions: ChartOptions<'doughnut'> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context) => {
              const value = context.raw as number;
              const investment = transformedInvestments[context.dataIndex];
              return [
                `Allocation: ${value}%`,
                `Value: $${investment.currentValue.toLocaleString()}`,
                `Return: ${investment.returnToDate}%`
              ];
            }
          }
        }
      },
      cutout: '75%'
    };

    return {
      chartData,
      chartOptions,
      totalValue: total,
      averageReturn: avgReturn,
      highestReturn: maxReturn,
      lowestReturn: minReturn,
      insights: insightsList,
      chartInvestments: transformedInvestments
    };
  }, [investments]);

  return {
    selectedInvestment,
    setSelectedInvestment,
    chartData,
    chartOptions,
    totalValue,
    averageReturn,
    highestReturn,
    lowestReturn,
    insights,
    chartInvestments
  };
}