import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Info } from 'lucide-react';
import { ChartLegend } from './portfolio/ChartLegend';
import { InvestmentDetails } from './portfolio/InvestmentDetails';
import { usePortfolioChart } from '@/hooks/usePortfolioChart';
import type { Investment } from '@/types/investment';

ChartJS.register(ArcElement, Tooltip, Legend);

interface PortfolioChartProps {
  data: Investment[];
}

export function PortfolioChart({ data }: PortfolioChartProps) {
  const [showTip, setShowTip] = useState(true);
  const [showInsights, setShowInsights] = useState(false);
  const {
    selectedInvestment,
    setSelectedInvestment,
    chartData,
    chartOptions,
    totalValue,
    averageReturn,
    highestReturn,
    lowestReturn,
    insights,
    chartInvestments
  } = usePortfolioChart(data);

  // Early return if no data
  if (!data || data.length === 0) {
    return (
      <div className="bg-white p-6 rounded-xl border border-gray-200 text-center">
        <p className="text-gray-600">No investments to display</p>
      </div>
    );
  }

  // Validate required data
  const hasValidData = data.every(investment => (
    investment.amount !== undefined &&
    investment.currentValue !== undefined &&
    investment.property?.title
  ));

  if (!hasValidData) {
    console.error('Invalid investment data structure:', data);
    return (
      <div className="bg-white p-6 rounded-xl border border-gray-200 text-center">
        <p className="text-gray-600">Unable to display portfolio data</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold">Portfolio Allocation</h3>
        <button
          onClick={() => setShowInsights(!showInsights)}
          className="flex items-center gap-2 text-primary-600 hover:text-primary-700"
        >
          <Info className="w-5 h-5" />
          <span className="text-sm font-medium">Portfolio Insights</span>
        </button>
      </div>

      {showInsights && insights.length > 0 && (
        <div className="mb-6 p-4 bg-gray-50 rounded-xl border border-gray-200 animate-fade-in">
          <div className="space-y-3">
            {insights.map((insight, index) => (
              <div key={index} className="flex items-start gap-3">
                <Info className={`w-5 h-5 mt-0.5 ${insight.colorClass}`} />
                <p className="text-sm text-gray-700">{insight.message}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      
      <div className="grid md:grid-cols-2 gap-8">
        {/* Chart Section */}
        <div className="relative h-80">
          {showTip && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/5 backdrop-blur-sm rounded-xl z-10 animate-fade-in">
              <div className="bg-white p-4 rounded-xl shadow-lg max-w-xs text-center">
                <p className="text-gray-600 mb-3">
                  Click on the chart segments or investment cards to view detailed information
                </p>
                <button
                  onClick={() => setShowTip(false)}
                  className="text-primary-600 font-medium hover:text-primary-700"
                >
                  Got it
                </button>
              </div>
            </div>
          )}
          <Doughnut data={chartData} options={chartOptions} />
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="text-center">
              <div className="text-3xl font-bold text-gray-900">
                ${totalValue.toLocaleString()}
              </div>
              <div className="text-sm text-gray-600">Total Portfolio Value</div>
            </div>
          </div>
        </div>

        {/* Investment Details */}
        <div>
          <ChartLegend
            investments={chartInvestments}
            colors={chartData.datasets[0].backgroundColor as string[]}
            selectedIndex={selectedInvestment ? data.indexOf(selectedInvestment) : null}
            onSelect={(index) => setSelectedInvestment(data[index])}
          />

          {selectedInvestment && chartInvestments[data.indexOf(selectedInvestment)] ? (
            <InvestmentDetails 
              investment={chartInvestments[data.indexOf(selectedInvestment)]} 
            />
          ) : (
            <div className="p-4 bg-gray-50 rounded-xl border border-gray-200 text-center">
              <p className="text-gray-600">Select an investment to view details</p>
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 pt-6 border-t border-gray-200">
        <div className="grid grid-cols-3 gap-4">
          <div className="text-center">
            <div className="text-sm text-gray-600 mb-1">Average Return</div>
            <div className="font-semibold text-primary-600">{averageReturn.toFixed(1)}%</div>
          </div>
          <div className="text-center">
            <div className="text-sm text-gray-600 mb-1">Highest Return</div>
            <div className="font-semibold text-green-600">{highestReturn}%</div>
          </div>
          <div className="text-center">
            <div className="text-sm text-gray-600 mb-1">Lowest Return</div>
            <div className="font-semibold text-gray-600">{lowestReturn}%</div>
          </div>
        </div>
      </div>
    </div>
  );
}