import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import type { ErrorResponse } from '@/types/response';

const API_URL = import.meta.env.VITE_API_URL;
const API_PREFIX = import.meta.env.VITE_API_PREFIX;

if (!API_URL) {
  throw new Error('VITE_API_URL environment variable is not defined');
}

const api = axios.create({
  baseURL: `${API_URL}${API_PREFIX}` || 'http://localhost:5000/api/v1',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Configure retry logic for failed requests
axiosRetry(api, {
  retries: 3,
  retryDelay: (retryCount) => retryCount * 1000,
  retryCondition: (error) => {
    // Don't retry if it's a client error (4xx)
    if (error.response?.status && error.response.status >= 400 && error.response.status < 500) {
      return false;
    }
    return axiosRetry.isNetworkOrIdempotentRequestError(error) ||
           error.code === 'ECONNABORTED';
  }
});

// Request interceptor
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Don't set Content-Type for FormData
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    // Debug logging in development
    if (process.env.NODE_ENV === 'development') {
      console.debug(`[API] ${config.method?.toUpperCase()} ${config.url}`, 
        config.data instanceof FormData ? '[FormData]' : config.data);
    }

    return config;
  },
  (error) => {
    console.error('[API] Request error:', error.message);
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    // Debug logging in development
    if (process.env.NODE_ENV === 'development') {
      console.debug(`[API] Response from ${response.config.url}:`, response.data);
    }
    return response;
  },
  (error: AxiosError<ErrorResponse>) => {
    if (!error.response) {
      throw new Error('Network error. Please check your connection and try again.');
    }

    // Log error details in development
    if (process.env.NODE_ENV === 'development') {
      console.error('[API] Response error:', {
        message: error.message,
        url: error.config?.url,
        method: error.config?.method,
        status: error.response?.status,
        data: error.response?.data
      });
    }

    const message = error.response.data?.message || 
                   error.response.data?.error?.message ||
                   'An unexpected error occurred';

    switch (error.response.status) {
      case 401:
        if (!window.location.pathname.includes('/login')) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = '/login';
          throw new Error('Session expired. Please log in again.');
        }
        throw new Error('Invalid credentials');
      case 403:
        throw new Error('You do not have permission to perform this action.');
      case 404:
        throw new Error('Resource not found.');
      case 409:
        throw new Error('Resource already exists.');
      case 422:
        throw new Error('Invalid data provided.');
      default:
        throw new Error(message);
    }
  }
);

export default api;