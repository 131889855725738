import api from './axios';
import { ethers } from 'ethers';
import type { ApiResponse } from '@/types/response';
import { getChainConfig } from '@/config/web3';
import { convertUsdToTokens, checkWalletBalance, getTokenPrice } from '@/utils/crypto';

interface PaymentIntent {
  clientSecret: string;
  id: string;
}

interface CreatePaymentIntentParams {
  amount: number;
  propertyId: string;
  method: 'stripe' | 'crypto';
}

const PAYMENT_WALLET_ADDRESS = import.meta.env.PAYMENT_WALLET_ADDRESS || '0x0D9Baba5Ce27052fBf8292210b60348c783CB72e';

export const paymentService = {
  async createPaymentIntent(params: CreatePaymentIntentParams): Promise<ApiResponse<PaymentIntent>> {
    try {
      const { data } = await api.post<ApiResponse<PaymentIntent>>('/payments/create-intent', params);
      return data;
    } catch (error) {
      console.error('Create payment intent error:', error);
      throw error;
    }
  },

  async processCryptoPayment(propertyId: string, usdAmount: number, chainId: number) {
    try {
      if (!window.ethereum) {
        throw new Error('No crypto wallet found');
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const walletAddress = await signer.getAddress();

      // Check wallet balance first
      const hasBalance = await checkWalletBalance(walletAddress, usdAmount, chainId);
      if (!hasBalance) {
        throw new Error('Insufficient wallet balance');
      }

      // Convert USD amount to token amount
      const tokenAmount = await convertUsdToTokens(usdAmount, chainId);
      if (!tokenAmount) {
        throw new Error('Failed to calculate token amount');
      }

      console.log('Sending payment:', {
        to: PAYMENT_WALLET_ADDRESS,
        value: tokenAmount,
        from: walletAddress
      });

      // Send transaction
      const tx = await signer.sendTransaction({
        to: PAYMENT_WALLET_ADDRESS,
        value: tokenAmount
      });

      console.log('Transaction sent:', tx.hash);

      // Get chain config for confirmations
      const chain = getChainConfig(chainId);
      
      // Wait for required confirmations
      console.log(`Waiting for ${chain.minConfirmations} confirmations...`);
      const receipt = await tx.wait(chain.minConfirmations);

      // Verify transaction was successful
      if (receipt.status !== 1) {
        throw new Error('Transaction failed');
      }

      // Get transaction details to verify amount
      const transaction = await provider.getTransaction(tx.hash);
      if (!transaction) {
        throw new Error('Transaction details not found');
      }

      // Verify amount received
      const receivedAmount = ethers.utils.formatEther(transaction.value);
      const tokenPrice = await getTokenPrice(chainId);
      const receivedUsdAmount = parseFloat(receivedAmount) * tokenPrice;

      // Allow 1% slippage
      const slippage = Math.abs(receivedUsdAmount - usdAmount) / usdAmount;
      if (slippage > 0.01) {
        throw new Error('Received amount does not match expected amount');
      }

      // Create investment on backend
      const response = await api.post<ApiResponse<{ txHash: string }>>('/payments/crypto', {
        propertyId,
        usdAmount,
        tokenAmount,
        walletAddress,
        txHash: receipt.transactionHash,
        chainId,
        confirmations: receipt.confirmations,
        blockNumber: receipt.blockNumber,
        gasUsed: receipt.gasUsed.toString(),
        effectiveGasPrice: receipt.effectiveGasPrice.toString()
      });

      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to process payment');
      }

      return {
        success: true,
        data: {
          txHash: receipt.transactionHash,
          blockNumber: receipt.blockNumber,
          confirmations: receipt.confirmations,
          receivedAmount: receivedUsdAmount
        }
      };
    } catch (error: any) {
      console.error('Process crypto payment error:', error);
      // Handle user rejection separately
      if (error.code === 4001) { // MetaMask user rejected
        throw new Error('Transaction rejected by user');
      }
      throw new Error(error.message || 'Failed to process crypto payment');
    }
  },

  async verifyTransaction(txHash: string, expectedUsdAmount: number, chainId: number) {
    try {
      if (!window.ethereum) {
        throw new Error('No crypto wallet found');
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const receipt = await provider.getTransactionReceipt(txHash);
      const transaction = await provider.getTransaction(txHash);

      if (!receipt || !transaction) {
        throw new Error('Transaction not found');
      }

      // Verify transaction details
      const isSuccess = receipt.status === 1;
      const isToCorrectAddress = receipt.to?.toLowerCase() === PAYMENT_WALLET_ADDRESS.toLowerCase();

      if (!isSuccess || !isToCorrectAddress) {
        throw new Error('Invalid transaction');
      }

      // Get current token price
      const tokenPrice = await getTokenPrice(chainId);
      if (!tokenPrice) {
        throw new Error('Failed to get token price');
      }

      // Convert received amount to USD using transaction value
      const receivedTokens = ethers.utils.formatEther(transaction.value);
      const receivedUsdAmount = parseFloat(receivedTokens) * tokenPrice;

      // Allow for 1% slippage
      const slippage = Math.abs(receivedUsdAmount - expectedUsdAmount) / expectedUsdAmount;
      if (slippage > 0.01) {
        throw new Error('Received amount does not match expected amount');
      }

      // Verify on backend
      const response = await api.post<ApiResponse<{ verified: boolean }>>('/payments/verify-transaction', {
        txHash,
        receipt: {
          status: receipt.status,
          blockNumber: receipt.blockNumber,
          confirmations: receipt.confirmations,
          gasUsed: receipt.gasUsed.toString(),
          effectiveGasPrice: receipt.effectiveGasPrice.toString()
        },
        transaction: {
          value: transaction.value.toString(),
          to: transaction.to
        },
        expectedUsdAmount,
        actualUsdAmount: receivedUsdAmount,
        chainId
      });

      return response.data;
    } catch (error) {
      console.error('Verify transaction error:', error);
      throw error;
    }
  }
};