import React from 'react';
import { DollarSign, TrendingUp, Building2, Percent } from 'lucide-react';
import type { PropertyFormData } from '@/types/property/form';

interface PropertyFinancialsFormProps {
  formData: PropertyFormData;
  onChange: (data: PropertyFormData) => void;
  errors?: Record<string, string>;
}

export function PropertyFinancialsForm({ formData, onChange, errors }: PropertyFinancialsFormProps) {
  // Ensure financials object exists with default values
  const financials = formData.financials || {
    purchasePrice: 0,
    currentValue: 0,
    expectedValue: 0,
    rentalIncome: {
      monthly: 0,
      occupancyRate: 0,
      netOperatingIncome: 0
    },
    expenses: []
  };

  const handleFinancialsChange = (field: string, value: any) => {
    onChange({
      ...formData,
      financials: {
        ...financials,
        [field]: value
      }
    });
  };

  const handleRentalIncomeChange = (field: string, value: any) => {
    onChange({
      ...formData,
      financials: {
        ...financials,
        rentalIncome: {
          ...financials.rentalIncome,
          [field]: value
        }
      }
    });
  };

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <h2 className="text-lg font-semibold mb-6 flex items-center gap-2">
        <Building2 className="w-5 h-5 text-primary-600" />
        Property Financials
      </h2>

      <div className="grid grid-cols-2 gap-6">
        {/* Purchase Price */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Purchase Price*
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="0.01"
              value={formData.purchasePrice}
              onChange={(e) => onChange({ ...formData, purchasePrice: Number(e.target.value) })}
              className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                errors?.purchasePrice ? 'border-red-300' : 'border-gray-200'
              } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
              required
            />
          </div>
          {errors?.purchasePrice && (
            <p className="mt-1 text-sm text-red-600">{errors.purchasePrice}</p>
          )}
        </div>

        {/* Current Value */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Current Value
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="0.01"
              value={formData.currentValue || formData.purchasePrice}
              onChange={(e) => onChange({ ...formData, currentValue: Number(e.target.value) })}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
        </div>

        {/* Monthly Rental Income */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Monthly Rental Income
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="0.01"
              value={financials.rentalIncome.monthly}
              onChange={(e) => handleRentalIncomeChange('monthly', Number(e.target.value))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
        </div>

        {/* Occupancy Rate */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Occupancy Rate
          </label>
          <div className="relative">
            <Percent className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              max="100"
              step="0.1"
              value={financials.rentalIncome.occupancyRate}
              onChange={(e) => handleRentalIncomeChange('occupancyRate', Number(e.target.value))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
        </div>

        {/* Net Operating Income */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Net Operating Income (Annual)
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="0.01"
              value={financials.rentalIncome.netOperatingIncome || 0}
              onChange={(e) => handleRentalIncomeChange('netOperatingIncome', Number(e.target.value))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
        </div>

        {/* Expected Future Value */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Expected Future Value
          </label>
          <div className="relative">
            <TrendingUp className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="0.01"
              value={financials.expectedValue}
              onChange={(e) => handleFinancialsChange('expectedValue', Number(e.target.value))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
        </div>
      </div>
    </div>
  );
}