import React, { useState, useEffect } from 'react';
import { Settings, Globe, DollarSign, Mail, Bell } from 'lucide-react';
import { GeneralSettings } from './GeneralSettings';
import { InvestmentSettings } from './InvestmentSettings';
import { EmailSettings } from './EmailSettings';
import { NotificationSettings } from './NotificationSettings';
import { settingsService } from '@/services/api/admin/settings';
import type { AdminSettings as AdminSettingsType } from '@/types/admin';
import type { GeneralSettingsProps, InvestmentSettingsProps, EmailSettingsProps, NotificationSettingsProps } from '@/types/admin/settings';
import toast from 'react-hot-toast';

const SECTIONS = [
  { id: 'general', label: 'General Settings', icon: Globe },
  { id: 'investment', label: 'Investment Settings', icon: DollarSign },
  { id: 'email', label: 'Email Settings', icon: Mail },
  { id: 'notifications', label: 'Notifications', icon: Bell }
] as const;

type Section = typeof SECTIONS[number]['id'];

export function AdminSettings() {
  const [activeSection, setActiveSection] = useState<Section>('general');
  const [settings, setSettings] = useState<AdminSettingsType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await settingsService.getSettings();
        if (response.success && response.data) {
          setSettings(response.data);
        }
      } catch (error) {
        toast.error('Failed to load settings');
      } finally {
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleUpdateSettings = async (updatedSettings: Partial<AdminSettingsType>) => {
    try {
      setIsSaving(true);
      const response = await settingsService.updateSettings(updatedSettings);
      
      if (response.success && response.data) {
        setSettings(response.data);
        toast.success('Settings updated successfully');
      }
    } catch (error) {
      toast.error('Failed to update settings');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
        <div className="h-64 bg-gray-200 rounded-xl"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* Content sections */}
      {activeSection === 'general' && (
        <GeneralSettings
          settings={settings}
          onUpdate={handleUpdateSettings}
          isLoading={isSaving}
        />
      )}
      {activeSection === 'investment' && (
        <InvestmentSettings
          settings={settings}
          onUpdate={handleUpdateSettings}
          isLoading={isSaving}
        />
      )}
      {activeSection === 'email' && (
        <EmailSettings
          settings={settings}
          onUpdate={handleUpdateSettings}
          isLoading={isSaving}
        />
      )}
      {activeSection === 'notifications' && (
        <NotificationSettings
          settings={settings}
          onUpdate={handleUpdateSettings}
          isLoading={isSaving}
        />
      )}
    </div>
  );
}