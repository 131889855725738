import api from '../axios';
import type { ApiResponse } from '@/types/response';
import type { Investment } from '@/types/investment';

interface PaymentIntent {
  clientSecret: string;
  id: string;
}

interface CreatePaymentIntentParams {
  amount: number;
  propertyId: string;
  method: 'stripe';
}

interface PaymentConfirmation {
  investment: Investment;
  paymentId: string;
}

export const stripeService = {
  async createPaymentIntent(params: CreatePaymentIntentParams) {
    try {
      const { data } = await api.post<ApiResponse<PaymentIntent>>('/payments/create-intent', params);

      if (!data.success || !data.data?.clientSecret) {
        throw new Error(data.message || 'Failed to create payment intent');
      }

      return {
        success: true,
        data: data.data
      };
    } catch (error: any) {
      console.error('Create payment intent error:', error);
      throw error;
    }
  },

  async confirmPayment(paymentIntentId: string): Promise<ApiResponse<PaymentConfirmation>> {
    try {
      const { data } = await api.post<ApiResponse<PaymentConfirmation>>('/payments/confirm', {
        paymentIntentId
      });

      // Ensure payment was successful
      if (!data.success || !data.data?.investment) {
        throw new Error(data.message || 'Payment confirmation failed');
      }

      return data;
    } catch (error: any) {
      console.error('Confirm payment error:', error);
      throw new Error(error.response?.data?.message || 'Failed to confirm payment');
    }
  }
};