// Add utility function to format location string
export function formatLocationString(location: string | { 
    city: string; 
    state: string; 
    address: string; 
    country: string; 
    zipCode: string; 
    coordinates: { 
      latitude: number; 
      longitude: number; 
    }; 
  }): string {
    if (typeof location === 'string') {
      return location;
    }
    
    return `${location.address}, ${location.city}, ${location.state}`;
  }