import { Property } from '@/types/property';
import { Investment } from '@/types/investment';

export function exportToCsv<T extends Record<string, any>>(data: T[], filename: string) {
  // Convert data to CSV format
  const replacer = (_: any, value: any) => value === null ? '' : value;
  const header = Object.keys(data[0]);
  const csv = [
    header.join(','), // Header row
    ...data.map(row =>
      header.map(fieldName => 
        JSON.stringify(row[fieldName], replacer)
      ).join(',')
    )
  ].join('\r\n');

  // Create and trigger download
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export function formatPropertyForExport(property: Property & { investments?: Investment[] }) {
  // Format investment details if available
  const investmentDetails = property.investments?.map(inv => ({
    userId: inv.userId,
    amount: inv.amount,
    currentValue: inv.currentValue,
    returnToDate: inv.returnToDate,
    status: inv.status,
    date: inv.createdAt
  })) || [];

  // Calculate total investment metrics
  const totalInvested = investmentDetails.reduce((sum, inv) => sum + Number(inv.amount), 0);
  const totalCurrentValue = investmentDetails.reduce((sum, inv) => sum + Number(inv.currentValue), 0);
  const averageReturn = investmentDetails.length > 0
    ? investmentDetails.reduce((sum, inv) => sum + Number(inv.returnToDate), 0) / investmentDetails.length
    : 0;

  return {
    'ID': property.id,
    'Title': property.title,
    'Type': property.type,
    'Status': property.status,
    'Address': property.location.address,
    'City': property.location.city,
    'State': property.location.state,
    'Country': property.location.country,
    'ZIP Code': property.location.zipCode,
    'Target Amount': property.targetAmount,
    'Raised Amount': property.raisedAmount,
    'Min Investment': property.minInvestment,
    'Max Investment': property.maxInvestment,
    'Expected Return': property.expectedReturn,
    'Current Investors': property.investors,
    'Purchase Price': property.purchasePrice,
    'Current Value': property.currentValue,
    'Last Valuation Date': property.lastValuationDate,
    'Features': property.features.join('; '),
    'Amenities': property.amenities.join('; '),
    'Description Summary': property.description.summary,
    'Full Description': property.description.full,
    'Created At': property.createdAt,
    'Updated At': property.updatedAt,
    // Investment metrics
    'Total Amount Invested': totalInvested,
    'Total Current Value': totalCurrentValue,
    'Average Return': `${averageReturn.toFixed(2)}%`,
    'Number of Investors': investmentDetails.length,
    // Investment details as JSON string for easy parsing
    'Investment Details': JSON.stringify(investmentDetails)
  };
}