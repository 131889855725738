import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Heart, ArrowRight, MapPin } from 'lucide-react';
import { watchlistService } from '@/services/api/watchlist';
import { PropertyImageCarousel } from '../PropertyImageCarousel';
import toast from 'react-hot-toast';
import type { Property } from '@/types/property';

export function WatchlistSection() {
  const [watchlist, setWatchlist] = useState<Property[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchWatchlist = async () => {
      try {
        const response = await watchlistService.getWatchlist();
        if (response.success) {
          setWatchlist(response.data || []);
        }
      } catch (error) {
        console.error('Failed to load watchlist:', error);
        toast.error('Failed to load watchlist');
      } finally {
        setIsLoading(false);
      }
    };

    fetchWatchlist();
  }, []);

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-xl border border-gray-200 animate-pulse">
        <div className="h-6 bg-gray-200 rounded w-1/4 mb-4"></div>
        <div className="space-y-4">
          {[1, 2].map((i) => (
            <div key={i} className="h-32 bg-gray-200 rounded-lg"></div>
          ))}
        </div>
      </div>
    );
  }

  if (watchlist.length === 0) {
    return (
      <div className="bg-white p-6 rounded-xl border border-gray-200">
        <div className="text-center py-8">
          <Heart className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900 mb-2">No Saved Properties</h3>
          <p className="text-gray-600 mb-6">
            Properties you save will appear here for quick access
          </p>
          <Link
            to="/properties"
            className="inline-flex items-center gap-2 text-primary-600 hover:text-primary-700 font-medium"
          >
            Browse Properties
            <ArrowRight className="w-4 h-4" />
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold">Saved Properties</h3>
        <Link
          to="/properties"
          className="text-primary-600 hover:text-primary-700 text-sm font-medium flex items-center gap-1"
        >
          View All
          <ArrowRight className="w-4 h-4" />
        </Link>
      </div>

      <div className="space-y-4">
        {watchlist.map((property) => (
          <Link
            key={property.id}
            to={`/properties/${property.id}`}
            className="block group hover:bg-gray-50 rounded-lg transition-colors"
          >
            <div className="flex gap-4 p-4">
              <div className="w-32 h-24 rounded-lg overflow-hidden flex-shrink-0">
                <PropertyImageCarousel
                  images={property.images}
                  title={property.title}
                  className="w-full h-full"
                />
              </div>
              
              <div className="flex-1 min-w-0">
                <h4 className="font-medium text-gray-900 mb-1 group-hover:text-primary-600 transition-colors">
                  {property.title}
                </h4>
                <p className="text-sm text-gray-600 flex items-center gap-1 mb-2">
                  <MapPin className="w-4 h-4" />
                  {property.location.address}
                </p>
                <div className="flex items-center gap-4 text-sm">
                  <span className="text-primary-600 font-medium">
                    ${property.minInvestment.toLocaleString()} min
                  </span>
                  <span className="text-green-600 font-medium flex items-center gap-1">
                    <ArrowRight className="w-4 h-4" />
                    {property.expectedReturn}% return
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}