import { adminApi } from '../client';
import type { ApiResponse } from '@/types/response';
import type { AdminStats } from '@/types/admin';

export const statsService = {
  async getStats() {
    try {
      const { data } = await adminApi.get<ApiResponse<AdminStats>>('/stats');
      return data;
    } catch (error) {
      console.error('Get stats error:', error);
      throw error;
    }
  }
};