import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { WagmiConfig } from 'wagmi';
import { Toaster } from 'react-hot-toast';
import { Provider as JotaiProvider } from 'jotai';
import { Navbar } from './components/Navbar';
import { PropertyListing } from './pages/PropertyListing';
import { PropertyDetails } from './pages/PropertyDetails';
import { Dashboard } from './pages/Dashboard';
import { Profile } from './pages/Profile';
import { Login } from './pages/auth/Login';
import { SignUp } from './pages/auth/SignUp';
import { VerifyOTP } from './pages/auth/VerifyOTP';
import { ForgotPassword } from './pages/auth/ForgotPassword';
import { ResetPassword } from './pages/auth/ResetPassword';
import { Settings } from './pages/Settings';
import { ChangePassword } from './pages/auth/ChangePassword';
import { Rewards } from './pages/Rewards';
import { AdminLayout } from './components/admin/layout/AdminLayout';
import { ProtectedRoute } from './components/ProtectedRoute';
import { LoadingProvider } from './contexts/LoadingContext';
import { config } from './config/web3';

function App() {
  return (
    <JotaiProvider>
      <WagmiConfig config={config}>
        <LoadingProvider>
          <Router>
            <div className="min-h-screen flex flex-col bg-gray-50">
              <Navbar />
              <main className="flex-grow pt-16">
                <Routes>
                  <Route path="/" element={<Navigate to="/properties" replace />} />
                  <Route path="/properties" element={<PropertyListing />} />
                  <Route path="/properties/:id" element={<PropertyDetails />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/verify-email" element={<VerifyOTP />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} /> {/* Add this route */}
                  <Route
                    path="/dashboard/*"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/profile"
                    element={
                      <ProtectedRoute>
                        <Profile />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/settings/*"
                    element={
                      <ProtectedRoute>
                        <Settings />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/change-password"
                    element={
                      <ProtectedRoute>
                        <ChangePassword />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/rewards/*"
                    element={
                      <ProtectedRoute>
                        <Rewards />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/*"
                    element={
                      <ProtectedRoute requireAdmin>
                        <AdminLayout />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </main>
            </div>
            <Toaster 
              position="bottom-right"
              toastOptions={{
                duration: 3000,
                style: {
                  background: '#363636',
                  color: '#fff',
                  borderRadius: '8px',
                },
              }}
            />
          </Router>
        </LoadingProvider>
      </WagmiConfig>
    </JotaiProvider>
  );
}

export default App;