import api from './axios';
import type { ApiResponse } from '@/types/response';
import type { Property } from '@/types/property';

export const watchlistService = {
  async getWatchlist(): Promise<ApiResponse<Property[]>> {
    try {
      const { data } = await api.get<ApiResponse<Property[]>>('/watchlist');
      return data;
    } catch (error) {
      console.error('Get watchlist error:', error);
      throw error;
    }
  },

  async addToWatchlist(propertyId: string): Promise<ApiResponse<void>> {
    try {
      const { data } = await api.post<ApiResponse<void>>('/watchlist', { propertyId });
      return data;
    } catch (error) {
      console.error('Add to watchlist error:', error);
      throw error;
    }
  },

  async removeFromWatchlist(propertyId: string): Promise<ApiResponse<void>> {
    try {
      const { data } = await api.delete<ApiResponse<void>>(`/watchlist/${propertyId}`);
      return data;
    } catch (error) {
      console.error('Remove from watchlist error:', error);
      throw error;
    }
  }
};