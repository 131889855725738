import React, { useState, useEffect } from 'react';
import { Search, Filter } from 'lucide-react';
import { adminService } from '@/services/api/admin';
import { VerificationList } from './VerificationList';
import { VerificationModal } from './VerificationModal';
import toast from 'react-hot-toast';
import type { VerificationRequest } from '@/types/verification';

export function VerificationRequests() {
  const [requests, setRequests] = useState<VerificationRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('pending');
  const [selectedRequest, setSelectedRequest] = useState<VerificationRequest | null>(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchRequests = async () => {
      try {
        const response = await adminService.getVerificationRequests();
        if (response.success && response.data) {
          setRequests(response.data);
        }
      } catch (error) {
        console.error('Failed to load verification requests:', error);
        toast.error('Failed to load verification requests');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRequests();
  }, []);

  const handleApprove = async (userId: string) => {
    try {
      setIsSubmitting(true);
      await adminService.updateVerificationStatus(userId, 'approved');
      setRequests(prev => prev.filter(req => req.userId !== userId));
      setSelectedRequest(null);
      toast.success('Verification approved');
    } catch (error) {
      toast.error('Failed to approve verification');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReject = async (userId: string) => {
    if (!rejectionReason) {
      toast.error('Please provide a reason for rejection');
      return;
    }

    try {
      setIsSubmitting(true);
      await adminService.updateVerificationStatus(userId, 'rejected', rejectionReason);
      setRequests(prev => prev.filter(req => req.userId !== userId));
      setSelectedRequest(null);
      setRejectionReason('');
      toast.success('Verification rejected');
    } catch (error) {
      toast.error('Failed to reject verification');
    } finally {
      setIsSubmitting(false);
    }
  };

  const filteredRequests = requests.filter(request => {
    const matchesSearch = 
      request.userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      request.userEmail.toLowerCase().includes(searchTerm.toLowerCase());
    
    if (filter === 'pending') return matchesSearch && request.status === 'pending';
    if (filter === 'approved') return matchesSearch && request.status === 'approved';
    if (filter === 'rejected') return matchesSearch && request.status === 'rejected';
    
    return matchesSearch;
  });

  if (isLoading) {
    return (
      <div className="p-8 text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-2 border-primary-500 border-t-transparent mx-auto"></div>
        <p className="mt-2 text-gray-600">Loading verification requests...</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Verification Requests</h1>
      </div>

      <div className="flex gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search requests..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          />
        </div>
        <div className="relative">
          <Filter className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="pl-10 pr-8 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 appearance-none bg-white"
          >
            <option value="all">All Requests</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      </div>

      <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
        {filteredRequests.length === 0 ? (
          <div className="p-8 text-center text-gray-600">
            No verification requests found
          </div>
        ) : (
          <VerificationList
            requests={filteredRequests}
            onView={setSelectedRequest}
            onApprove={handleApprove}
            onReject={setSelectedRequest}
            isSubmitting={isSubmitting}
          />
        )}
      </div>

      {selectedRequest && (
        <VerificationModal
          request={selectedRequest}
          rejectionReason={rejectionReason}
          onReasonChange={setRejectionReason}
          onApprove={handleApprove}
          onReject={handleReject}
          onClose={() => {
            setSelectedRequest(null);
            setRejectionReason('');
          }}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
}