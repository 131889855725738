import React, { useState, useRef, useEffect } from 'react';
import { Calculator, DollarSign, Clock } from 'lucide-react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface InvestmentCalculatorProps {
  expectedReturn: number;
  minInvestment: number;
}

const INVESTMENT_DURATIONS = [
  { value: 1, label: '1 Year' },
  { value: 2, label: '2 Years' },
  { value: 5, label: '5 Years' },
  { value: 10, label: '10 Years' }
];

export function InvestmentCalculator({ expectedReturn, minInvestment }: InvestmentCalculatorProps) {
  const [investment, setInvestment] = useState(minInvestment);
  const [duration, setDuration] = useState(5);
  const chartRef = useRef<ChartJS<'bar'>>(null);

  // Calculate returns for each year
  const returns = Array.from({ length: duration }, (_, i) => ({
    year: i + 1,
    value: investment * Math.pow(1 + expectedReturn / 100, i + 1)
  }));

  const chartData: ChartData<'bar'> = {
    labels: returns.map(r => `Year ${r.year}`),
    datasets: [
      {
        label: 'Conservative',
        data: returns.map(r => r.value * 0.8),
        backgroundColor: 'rgba(99, 102, 241, 0.8)',
        borderRadius: 8
      },
      {
        label: 'Expected',
        data: returns.map(r => r.value),
        backgroundColor: 'rgba(34, 197, 94, 0.8)',
        borderRadius: 8
      },
      {
        label: 'Optimistic',
        data: returns.map(r => r.value * 1.2),
        backgroundColor: 'rgba(239, 68, 68, 0.8)',
        borderRadius: 8
      }
    ]
  };

  const chartOptions: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          padding: 20,
          font: {
            family: "'Inter', sans-serif",
            size: 12
          }
        }
      },
      tooltip: {
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        titleColor: '#1f2937',
        bodyColor: '#1f2937',
        borderColor: '#e5e7eb',
        borderWidth: 1,
        padding: 12,
        usePointStyle: true,
        callbacks: {
          label: (context) => {
            const value = context.raw as number;
            return `$${value.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            })}`;
          }
        }
      }
    },
    scales: {
      x: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          font: {
            family: "'Inter', sans-serif",
            size: 12
          }
        }
      },
      y: {
        beginAtZero: true,
        border: {
          display: false
        },
        grid: {
          color: 'rgba(0, 0, 0, 0.05)'
        },
        ticks: {
          callback: (value) => '$' + Number(value).toLocaleString(),
          font: {
            family: "'Inter', sans-serif",
            size: 12
          }
        }
      }
    }
  };

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <div className="flex items-center gap-2 mb-6">
        <Calculator className="w-6 h-6 text-primary-600" />
        <h3 className="text-xl font-semibold">Investment Calculator</h3>
      </div>

      <div className="grid md:grid-cols-2 gap-6 mb-8">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Investment Amount
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min={minInvestment}
              step={1000}
              value={investment}
              onChange={(e) => setInvestment(Number(e.target.value))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Minimum investment: ${minInvestment.toLocaleString()}
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Investment Duration
          </label>
          <div className="relative">
            <Clock className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <select
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 appearance-none bg-white"
            >
              {INVESTMENT_DURATIONS.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <p className="mt-1 text-sm text-gray-500">
            Expected annual return: {expectedReturn}%
          </p>
        </div>
      </div>

      <div className="h-80">
        <Bar data={chartData} options={chartOptions} ref={chartRef} />
      </div>

      <div className="mt-6 grid grid-cols-3 gap-4 text-center">
        <div className="p-4 bg-primary-50 rounded-lg">
          <div className="text-sm text-gray-600 mb-1">Conservative</div>
          <div className="font-semibold text-primary-700">
            ${Math.round(returns[returns.length - 1].value * 0.8).toLocaleString()}
          </div>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <div className="text-sm text-gray-600 mb-1">Expected</div>
          <div className="font-semibold text-green-700">
            ${Math.round(returns[returns.length - 1].value).toLocaleString()}
          </div>
        </div>
        <div className="p-4 bg-red-50 rounded-lg">
          <div className="text-sm text-gray-600 mb-1">Optimistic</div>
          <div className="font-semibold text-red-700">
            ${Math.round(returns[returns.length - 1].value * 1.2).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
}