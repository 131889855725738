import React, { useState } from 'react';
import { MessageSquare, Mail, Phone, Video, Calendar, X, Clock, Users, Sparkles, ArrowLeft } from 'lucide-react';
import { LiveChat } from './LiveChat';
import { supportService } from '@/services/api/support';
import { useAuth } from '@/hooks/useAuth';
import toast from 'react-hot-toast';

interface ContactSupportProps {
  isOpen: boolean;
  onClose: () => void;
}

const supportAgents = [
  {
    id: 1,
    name: 'Sarah Chen',
    role: 'Investment Specialist',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=150&q=80',
    status: 'online',
    expertise: 'Property Investment',
    experience: '8+ years',
    languages: ['English'],
    availability: 'Available within 24 hours'
  },
  {
    id: 2,
    name: 'Michael Torres',
    role: 'Senior Support Agent',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=crop&w=150&q=80',
    status: 'online',
    expertise: 'Technical Support',
    experience: '5+ years',
    languages: ['English'],
    availability: 'Available within 12 hours'
  }
];

const contactOptions = [
  { 
    id: 'live_chat', 
    icon: MessageSquare, 
    label: 'Live Chat', 
    description: 'Get instant help from our team',
    availableNow: true 
  },
  { 
    id: 'phone_call', 
    icon: Phone, 
    label: 'Phone Call', 
    description: 'Schedule a call with an expert',
    availableNow: true
  },
  { 
    id: 'video_call', 
    icon: Video, 
    label: 'Video Call', 
    description: 'Face-to-face consultation',
    availableNow: false
  }
];

export function ContactSupport({ isOpen, onClose }: ContactSupportProps) {
  const { user } = useAuth();
  const [selectedAgent, setSelectedAgent] = useState<typeof supportAgents[0] | null>(null);
  const [contactMethod, setContactMethod] = useState<'live_chat' | 'phone_call' | 'video_call' | null>(null);
  const [showChat, setShowChat] = useState(false);
  const [scheduledTime, setScheduledTime] = useState('');
  const [topic, setTopic] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const now = new Date();
  now.setMinutes(now.getMinutes() + 30);

  // Format for datetime-local input
  const minDateTime = now.toISOString().slice(0, 16);

  const handleStartChat = async (agent: typeof supportAgents[0]) => {
    if (!user) {
      toast.error('Please log in to use live chat');
      return;
    }

    try {
      await supportService.submitRequest({
        type: 'live_chat',
        agentId: agent.id.toString(),
        message: 'Starting live chat',
        contactMethod: 'email'
      });

      setSelectedAgent(agent);
      setShowChat(true);
      onClose();
    } catch (error) {
      toast.error('Failed to start chat. Please try again.');
    }
  };

  const handleSchedule = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !selectedAgent || !contactMethod || !scheduledTime || !topic) {
      toast.error('Please fill in all required fields');
      return;
    }

    // Validate selected time is not in the past
    const selectedTime = new Date(scheduledTime);
    const now = new Date();
    now.setMinutes(now.getMinutes() + 30); // 30 minutes buffer
    
    if (selectedTime < now) {
      toast.error('Please select a future date and time');
      return;
    }

    setIsSubmitting(true);
    try {
      await supportService.submitRequest({
        type: contactMethod,
        agentId: selectedAgent.id.toString(),
        preferredTime: scheduledTime,
        message: topic,
        contactMethod: contactMethod === 'phone_call' ? 'phone' : 'email'
      });

      toast.success('Support request scheduled successfully!');
      onClose();
      resetForm();
    } catch (error) {
      toast.error('Failed to schedule support request');
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setSelectedAgent(null);
    setContactMethod(null);
    setScheduledTime('');
    setTopic('');
  };

  if (!isOpen && !showChat) return null;

  if (showChat && selectedAgent) {
    return <LiveChat agent={selectedAgent} onClose={() => setShowChat(false)} />;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose}></div>
      <div className="relative bg-white rounded-2xl shadow-xl max-w-2xl w-full mx-4 animate-fade-in">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-2xl font-semibold">How can we help?</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-6 h-6" />
            </button>
          </div>

          {!selectedAgent ? (
            <div className="space-y-8">
              <div>
                <h4 className="text-lg font-medium mb-4 flex items-center gap-2">
                  <Users className="w-5 h-5 text-primary-600" />
                  Available Support Team
                </h4>
                <div className="grid md:grid-cols-2 gap-4">
                  {supportAgents.map((agent) => (
                    <button
                      key={agent.id}
                      onClick={() => setSelectedAgent(agent)}
                      className="p-4 rounded-xl border border-gray-200 hover:border-primary-300 hover:bg-primary-50 transition-all text-left group"
                    >
                      <div className="flex items-start gap-4">
                        <div className="relative">
                          <img
                            src={agent.avatar}
                            alt={agent.name}
                            className="w-12 h-12 rounded-full"
                          />
                          <div className="absolute -bottom-1 -right-1 w-3 h-3 bg-green-500 rounded-full border-2 border-white"></div>
                        </div>
                        <div>
                          <h5 className="font-medium group-hover:text-primary-600">{agent.name}</h5>
                          <p className="text-sm text-gray-600">{agent.role}</p>
                          <div className="flex flex-wrap gap-2 mt-2">
                            <span className="text-xs px-2 py-1 bg-primary-100 text-primary-700 rounded-full">
                              {agent.expertise}
                            </span>
                            <span className="text-xs px-2 py-1 bg-green-100 text-green-700 rounded-full">
                              {agent.availability}
                            </span>
                          </div>
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="text-lg font-medium mb-4 flex items-center gap-2">
                  <Sparkles className="w-5 h-5 text-primary-600" />
                  Quick Connect
                </h4>
                <div className="grid md:grid-cols-3 gap-4">
                  {contactOptions.map((option) => (
                    <button
                      key={option.id}
                      onClick={() => {
                        setContactMethod(option.id as any);
                        if (!selectedAgent) {
                          setSelectedAgent(supportAgents[0]);
                        }
                      }}
                      className="p-4 rounded-xl border border-gray-200 hover:border-primary-300 hover:bg-primary-50 transition-all text-left relative group"
                    >
                      <option.icon className="w-6 h-6 text-primary-600 mb-2" />
                      <h5 className="font-medium mb-1 group-hover:text-primary-600">{option.label}</h5>
                      <p className="text-sm text-gray-600">{option.description}</p>
                      {option.availableNow && (
                        <span className="absolute top-2 right-2 flex items-center gap-1">
                          <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                          <span className="text-xs text-green-600">Available</span>
                        </span>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSchedule} className="space-y-6">
              <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-xl">
                <img
                  src={selectedAgent.avatar}
                  alt={selectedAgent.name}
                  className="w-12 h-12 rounded-full"
                />
                <div>
                  <h5 className="font-medium">{selectedAgent.name}</h5>
                  <p className="text-sm text-gray-600">{selectedAgent.role}</p>
                  <div className="flex items-center gap-2 mt-1">
                    <span className="text-xs px-2 py-1 bg-primary-100 text-primary-700 rounded-full">
                      {selectedAgent.expertise}
                    </span>
                    <span className="text-xs px-2 py-1 bg-green-100 text-green-700 rounded-full">
                      {selectedAgent.availability}
                    </span>
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Preferred Time
                </label>
                <div className="relative">
                <input
                  type="datetime-local"
                  value={scheduledTime}
                  onChange={(e) => setScheduledTime(e.target.value)}
                  min={minDateTime}
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                  required
                />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  What would you like to discuss?
                </label>
                <textarea
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder="Brief description of your query..."
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 h-24 resize-none"
                  required
                />
              </div>

              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={() => setSelectedAgent(null)}
                  className="flex items-center gap-2 px-4 py-2 text-gray-600 hover:text-gray-900"
                >
                  <ArrowLeft className="w-5 h-5" />
                  Back
                </button>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
                >
                  {isSubmitting ? (
                    <>
                      <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                      Scheduling...
                    </>
                  ) : (
                    'Schedule Support'
                  )}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}