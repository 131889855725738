import { propertyService } from './properties';
import { propertyAppreciationService } from './appreciation';

// Combine all property-related services
export const adminPropertyService = {
  ...propertyService,
  ...propertyAppreciationService
};

// Export individual services
export { propertyService };
export { propertyAppreciationService };

// Export types
export type { Property } from '@/types/property';
export type { Investment } from '@/types/investment';