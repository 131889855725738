import React, { useState, useEffect } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import { verificationService } from '@/services/api/verification';
import { DocumentUpload } from './verification/DocumentUpload';
import { VerificationStatus } from './verification/VerificationStatus';
import toast from 'react-hot-toast';

interface VerificationStatus {
  status: 'unverified' | 'pending' | 'verified' | 'rejected';
  rejectionReason?: string;
  documents?: {
    idDocument?: {
      url: string;
      type: string;
    };
    proofOfAddress?: {
      url: string;
      type: string;
    };
  };
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_TYPES = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

export function IdentityVerification() {
  const [status, setStatus] = useState<VerificationStatus | null>(null);
  const [files, setFiles] = useState<{ idDocument?: File; proofOfAddress?: File }>({});
  const [previews, setPreviews] = useState<{ idDocument?: string; proofOfAddress?: string }>({});
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await verificationService.getStatus();
        if (response.success) {
          setStatus(response.data || null);
        }
      } catch (error) {
        toast.error('Failed to load verification status');
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatus();
  }, []);

  useEffect(() => {
    // Cleanup previews on unmount
    return () => {
      Object.values(previews).forEach(url => {
        if (url?.startsWith('blob:')) {
          URL.revokeObjectURL(url);
        }
      });
    };
  }, [previews]);

  const validateFile = (file: File) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      throw new Error('Invalid file type. Please upload JPG, PNG or PDF files only.');
    }
    if (file.size > MAX_FILE_SIZE) {
      throw new Error('File size exceeds 5MB limit.');
    }
    return true;
  };

  const handleFileChange = (type: 'idDocument' | 'proofOfAddress', file: File) => {
    try {
      if (validateFile(file)) {
        setFiles(prev => ({ ...prev, [type]: file }));
        
        // Create preview URL for images and PDFs
        if (file.type.startsWith('image/')) {
          const previewUrl = URL.createObjectURL(file);
          setPreviews(prev => ({ ...prev, [type]: previewUrl }));
        } else if (file.type === 'application/pdf') {
          setPreviews(prev => ({ ...prev, [type]: undefined }));
        }
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Invalid file');
    }
  };

  const handleSubmit = async () => {
    if (!files.idDocument || !files.proofOfAddress) {
      toast.error('Please upload both required documents');
      return;
    }

    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.append('idDocument', files.idDocument);
      formData.append('proofOfAddress', files.proofOfAddress);

      const response = await verificationService.submitDocuments(formData);

      if (response.success) {
        setStatus(response.data || null);
        setFiles({});
        // Cleanup previews
        Object.values(previews).forEach(url => {
          if (url?.startsWith('blob:')) {
            URL.revokeObjectURL(url);
          }
        });
        setPreviews({});
        toast.success('Documents submitted successfully');
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to submit documents');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-4">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
        <div className="h-64 bg-gray-200 rounded"></div>
      </div>
    );
  }

  if (status?.status === 'verified' || status?.status === 'pending') {
    return <VerificationStatus status={status.status} />;
  }

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-semibold">Identity Verification</h3>

      {status?.status === 'rejected' && status.rejectionReason && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4">
          <div className="flex gap-3">
            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
            <div>
              <h4 className="font-medium text-red-900">Verification Failed</h4>
              <p className="mt-1 text-sm text-red-700">{status.rejectionReason}</p>
            </div>
          </div>
        </div>
      )}

      <div className="grid md:grid-cols-2 gap-6">
        <DocumentUpload
          id="idDocument"
          label="Government-Issued ID"
          description="Upload passport, driver's license, or national ID"
          file={files.idDocument}
          preview={previews.idDocument}
          isPDF={files.idDocument?.type === 'application/pdf'}
          onChange={(file) => handleFileChange('idDocument', file)}
          icon="camera"
        />

        <DocumentUpload
          id="proofOfAddress"
          label="Proof of Address"
          description="Upload utility bill or bank statement"
          file={files.proofOfAddress}
          preview={previews.proofOfAddress}
          isPDF={files.proofOfAddress?.type === 'application/pdf'}
          onChange={(file) => handleFileChange('proofOfAddress', file)}
          icon="file"
        />
      </div>

      <button
        onClick={handleSubmit}
        disabled={isSubmitting || !files.idDocument || !files.proofOfAddress}
        className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
      >
        {isSubmitting ? (
          <>
            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
            Submitting...
          </>
        ) : (
          <>
            <Upload className="w-5 h-5" />
            Submit Documents
          </>
        )}
      </button>
    </div>
  );
}