import React from 'react';
import { Link } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';

interface ErrorStateProps {
  message: string;
}

export function ErrorState({ message }: ErrorStateProps) {
  return (
    <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
      <div className="flex items-center justify-center gap-2 text-red-600 mb-4">
        <AlertCircle className="w-6 h-6" />
        <span className="text-lg font-medium">Error Loading Dashboard</span>
      </div>
      <p className="text-gray-600 mb-6">{message}</p>
      <Link
        to="/properties"
        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
      >
        Browse Properties
      </Link>
    </div>
  );
}