import { createConfig, configureChains, Chain } from 'wagmi';
import { mainnet, polygon, bsc } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';

// Configure supported chains
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, polygon, bsc],
  [publicProvider()]
);

// Create wagmi config
export const config = createConfig({
  autoConnect: true,
  publicClient,
  webSocketPublicClient,
  connectors: [
    new MetaMaskConnector({ 
      chains,
      options: {
        shimDisconnect: true,
        UNSTABLE_shimOnConnectSelectAccount: true,
      }
    }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: 'Sociallocket',
        headlessMode: true
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: process.env.WALLET_CONNECT_PROJECT_ID || '',
        showQrModal: true,
        metadata: {
          name: 'Sociallocket',
          description: 'Real Estate Investment Platform',
          url: 'https://sociallocket.com',
          icons: ['https://sociallocket.com/logo.png']
        }
      }
    })
  ],
});

// Extended chain interface with additional properties
export interface ExtendedChain extends Chain {
  coingeckoId: string;
  symbol: string;
  rpcUrl?: string;
  explorerUrl: string;
  minConfirmations: number;
  gasMultiplier: number;
  settings: {
    gasLimitMultiplier: number;
    maxPriorityFeePerGas?: number;
    maxFeePerGas?: number;
  };
}

// Chain-specific configurations
export const chainConfigs: Record<number, ExtendedChain> = {
  [mainnet.id]: {
    ...mainnet,
    coingeckoId: 'ethereum',
    symbol: 'ETH',
    rpcUrl: process.env.ETH_RPC_URL,
    explorerUrl: 'https://etherscan.io',
    minConfirmations: 3,
    gasMultiplier: 1.2,
    settings: {
      gasLimitMultiplier: 1.2,
      maxPriorityFeePerGas: 2000000000, // 2 gwei
    }
  },
  [polygon.id]: {
    ...polygon,
    coingeckoId: 'matic-network',
    symbol: 'MATIC',
    rpcUrl: process.env.POLYGON_RPC_URL,
    explorerUrl: 'https://polygonscan.com',
    minConfirmations: 5,
    gasMultiplier: 1.3,
    settings: {
      gasLimitMultiplier: 1.3,
      maxFeePerGas: 30000000000, // 30 gwei
    }
  },
  [bsc.id]: {
    ...bsc,
    coingeckoId: 'binancecoin',
    symbol: 'BNB',
    rpcUrl: process.env.BSC_RPC_URL,
    explorerUrl: 'https://bscscan.com',
    minConfirmations: 3,
    gasMultiplier: 1.1,
    settings: {
      gasLimitMultiplier: 1.1,
      maxFeePerGas: 30000000000, // 30 gwei
    }
  }
};

// Utility functions
export const getChainConfig = (chainId: number): ExtendedChain => {
  const config = chainConfigs[chainId];
  if (!config) {
    throw new Error(`Unsupported chain ID: ${chainId}`);
  }
  return config;
};

export const getExplorerUrl = (chainId: number, hash: string): string => {
  const config = getChainConfig(chainId);
  return `${config.explorerUrl}/tx/${hash}`;
};

export const formatTokenAmount = (amount: string, chainId: number): string => {
  const config = getChainConfig(chainId);
  return `${amount} ${config.symbol}`;
};

export const estimateGasLimit = async (
  chainId: number,
  estimatedGas: bigint
): Promise<bigint> => {
  const config = getChainConfig(chainId);
  return BigInt(Math.ceil(Number(estimatedGas) * config.settings.gasLimitMultiplier));
};

export const getMinConfirmations = (chainId: number): number => {
  const config = getChainConfig(chainId);
  return config.minConfirmations;
};

// Export supported chains for easy access
export const supportedChains = {
  ethereum: chainConfigs[mainnet.id],
  polygon: chainConfigs[polygon.id],
  binance: chainConfigs[bsc.id]
};