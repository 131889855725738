import api from '../axios';
import { getSignedUrl } from '@/utils/s3';
import { userService } from '../user';
import type { LoginCredentials, AuthResponse } from './types';

export const loginService = {
  async login(credentials: LoginCredentials): Promise<AuthResponse> {
    try {
      const response = await api.post<AuthResponse>('/auth/login', credentials);
      
      if (!response.data.success || !response.data.token || !response.data.user) {
        throw new Error(response.data.message || 'Login failed');
      }

      // Store token first to ensure subsequent requests are authenticated
      localStorage.setItem('token', response.data.token);

      try {
        // Fetch complete profile data after login
        const profileResponse = await userService.getProfile();
        if (profileResponse.success && profileResponse.data) {
          const userData = profileResponse.data;

          // Store complete user data with original avatar URL
          localStorage.setItem('user', JSON.stringify(userData));

          // Get signed URL for immediate display if avatar exists
          if (userData.avatar && userData.avatar.includes('amazonaws.com')) {
            try {
              const signedUrl = await getSignedUrl(userData.avatar);
              return {
                ...response.data,
                user: { ...userData, avatar: signedUrl }
              };
            } catch (error) {
              console.error('Failed to get signed avatar URL:', error);
              return {
                ...response.data,
                user: userData
              };
            }
          }

          return {
            ...response.data,
            user: userData
          };
        }
      } catch (error) {
        console.error('Failed to fetch profile data:', error);
      }

      // Fallback to basic user data if profile fetch fails
      localStorage.setItem('user', JSON.stringify(response.data.user));
      return response.data;
    } catch (error: any) {
      console.error('Login error:', error);
      throw new Error(error.response?.data?.message || 'Invalid credentials');
    }
  }
};