import React from 'react';
import { Star, ExternalLink } from 'lucide-react';

export function RateUs() {
  const handleGoogleReview = () => {
    window.open('https://g.page/r/your-google-review-link', '_blank');
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Review Us on Google</h3>
        <p className="text-gray-600">
          Love using our platform? Help others discover us by sharing your experience on Google!
        </p>
      </div>

      <div className="bg-primary-50 border border-primary-100 rounded-lg p-6 text-center">
        <div className="flex justify-center mb-4">
          {[1, 2, 3, 4, 5].map((value) => (
            <Star
              key={value}
              className="w-8 h-8 text-primary-600"
              fill="currentColor"
            />
          ))}
        </div>
        <h4 className="text-lg font-medium text-primary-900 mb-2">
          Share Your Experience
        </h4>
        <p className="text-primary-700 mb-6">
          Your review helps us grow and helps other investors make informed decisions.
        </p>
        <button
          onClick={handleGoogleReview}
          className="inline-flex items-center gap-2 px-6 py-3 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
        >
          <Star className="w-5 h-5" />
          Write a Google Review
          <ExternalLink className="w-4 h-4" />
        </button>
      </div>

      <div className="mt-6 p-4 bg-gray-50 rounded-lg">
        <h5 className="font-medium mb-2">Why review on Google?</h5>
        <ul className="space-y-2 text-sm text-gray-600">
          <li>• Help other investors discover our platform</li>
          <li>• Share your success story</li>
          <li>• Provide valuable feedback to our team</li>
          <li>• Build trust in our community</li>
        </ul>
      </div>
    </div>
  );
}