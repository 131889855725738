import React, { useState } from 'react';
import { Search, MapPin, DollarSign } from 'lucide-react';
import { propertyService } from '@/services/api/properties';
import type { PropertyFilters } from '@/types/property';
import toast from 'react-hot-toast';

interface SearchBarProps {
  onSearch: (results: any[]) => void;
}

export function SearchBar({ onSearch }: SearchBarProps) {
  const [location, setLocation] = useState('');
  const [priceRange, setPriceRange] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSearching(true);

    try {
      const filters: PropertyFilters = {};
      if (location) filters.location = location;
      if (priceRange) {
        const [min, max] = priceRange.split('-').map(Number);
        filters.minPrice = min;
        filters.maxPrice = max;
      }
      if (propertyType) filters.type = propertyType;

      const response = await propertyService.getProperties(filters);
      onSearch(response.data);
    } catch (error) {
      toast.error('Failed to search properties');
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <form onSubmit={handleSearch} className="max-w-4xl mx-auto">
      <div className="flex flex-col md:flex-row gap-4 bg-white p-2 rounded-xl shadow-lg border border-primary-100">
        <div className="flex-1 relative">
          <MapPin className="absolute left-4 top-3.5 w-5 h-5 text-primary-400" />
          <input
            type="text"
            placeholder="Location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full pl-12 pr-4 py-3 rounded-lg border border-primary-100 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          />
        </div>
        <div className="flex-1 relative">
          <DollarSign className="absolute left-4 top-3.5 w-5 h-5 text-primary-400" />
          <select
            value={priceRange}
            onChange={(e) => setPriceRange(e.target.value)}
            className="w-full pl-12 pr-4 py-3 rounded-lg border border-primary-100 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          >
            <option value="">Price Range</option>
            <option value="0-250000">$0 - $250,000</option>
            <option value="250000-500000">$250,000 - $500,000</option>
            <option value="500000-750000">$500,000 - $750,000</option>
            <option value="750000-1000000">$750,000 - $1,000,000</option>
            <option value="1000000-">$1,000,000+</option>
          </select>
        </div>
        <div className="flex-1 relative">
          <select
            value={propertyType}
            onChange={(e) => setPropertyType(e.target.value)}
            className="w-full px-4 py-3 rounded-lg border border-primary-100 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          >
            <option value="">Property Type</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="mixed_use">Mixed Use</option>
          </select>
        </div>
        <button
          type="submit"
          disabled={isSearching}
          className="bg-primary-600 text-white px-8 py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors flex items-center gap-2 shadow-lg shadow-primary-600/20 hover:shadow-xl hover:shadow-primary-600/30 disabled:opacity-50"
        >
          {isSearching ? (
            <>
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Searching...
            </>
          ) : (
            <>
              <Search className="w-5 h-5" />
              Search
            </>
          )}
        </button>
      </div>
    </form>
  );
}