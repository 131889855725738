import React from 'react';

interface PropertyProgressProps {
  raisedAmount: number;
  targetAmount: number;
}

export function PropertyProgress({ raisedAmount, targetAmount }: PropertyProgressProps) {
  const progress = (raisedAmount / targetAmount) * 100;

  return (
    <div>
      <div className="flex justify-between text-sm mb-1">
        <span className="text-gray-600">Progress</span>
        <span className="text-primary-600 font-medium">{progress.toFixed(1)}%</span>
      </div>
      <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
        <div 
          className="h-full bg-gradient-to-r from-primary-500 to-primary-600 rounded-full transition-all duration-500"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  );
}