import React, { useState } from 'react';
import { Bell, Save } from 'lucide-react';
import type { NotificationSettingsProps } from '@/types/admin/settings';
import toast from 'react-hot-toast';

export function NotificationSettings({ settings, onUpdate, isLoading }: NotificationSettingsProps) {
  const [formData, setFormData] = useState(settings?.notifications || {
    enableEmailNotifications: true,
    enablePushNotifications: true,
    adminEmailRecipients: []
  });
  const [newEmail, setNewEmail] = useState(''); // Add this line to fix the error

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onUpdate({ notifications: formData });
      toast.success('Notification settings updated successfully');
    } catch (error) {
      toast.error('Failed to update notification settings');
    }
  };

  const handleAddEmail = (e: React.FormEvent) => {
    e.preventDefault();
    if (newEmail && !formData.adminEmailRecipients.includes(newEmail)) {
      setFormData(prev => ({
        ...prev,
        adminEmailRecipients: [...prev.adminEmailRecipients, newEmail]
      }));
      setNewEmail(''); // Clear input after adding
    }
  };

  const handleRemoveEmail = (email: string) => {
    setFormData(prev => ({
      ...prev,
      adminEmailRecipients: prev.adminEmailRecipients.filter(e => e !== email)
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            id="emailNotifications"
            checked={formData.enableEmailNotifications}
            onChange={(e) => setFormData(prev => ({ ...prev, enableEmailNotifications: e.target.checked }))}
            className="w-4 h-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
          />
          <label htmlFor="emailNotifications" className="flex items-center gap-2">
            <Bell className="w-5 h-5 text-gray-400" />
            <span>Enable Email Notifications</span>
          </label>
        </div>

        <div className="flex items-center gap-4">
          <input
            type="checkbox"
            id="pushNotifications"
            checked={formData.enablePushNotifications}
            onChange={(e) => setFormData(prev => ({ ...prev, enablePushNotifications: e.target.checked }))}
            className="w-4 h-4 text-primary-600 border-gray-300 rounded focus:ring-primary-500"
          />
          <label htmlFor="pushNotifications" className="flex items-center gap-2">
            <Bell className="w-5 h-5 text-gray-400" />
            <span>Enable Push Notifications</span>
          </label>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Admin Email Recipients
        </label>
        <div className="space-y-2">
          <div className="flex gap-2">
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              placeholder="Add email address"
              className="flex-1 px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            <button
              type="button"
              onClick={handleAddEmail}
              className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
            >
              Add
            </button>
          </div>
          <div className="space-y-2">
            {formData.adminEmailRecipients.map((email) => (
              <div
                key={email}
                className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
              >
                <span>{email}</span>
                <button
                  type="button"
                  onClick={() => handleRemoveEmail(email)}
                  className="text-red-600 hover:text-red-700"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
        >
          {isLoading ? (
            <>
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="w-4 h-4" />
              Save Changes
            </>
          )}
        </button>
      </div>
    </form>
  );
}