import React, { useState, useEffect } from 'react';
import { Coins } from 'lucide-react';
import { settingsService } from '@/services/api/settings';
import type { Settings } from '@/types/settings';
import toast from 'react-hot-toast';

interface CurrencySettingsProps {
  settings: Settings | null;
  onUpdate: (settings: Partial<Settings>) => void;
}

const currencies = [
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' },
  { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
  { code: 'CAD', symbol: 'C$', name: 'Canadian Dollar' }
];

export function CurrencySettings({ settings, onUpdate }: CurrencySettingsProps) {
  const [selectedCurrency, setSelectedCurrency] = useState(settings?.currency || 'USD');
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (settings?.currency) {
      setSelectedCurrency(settings.currency);
    }
  }, [settings]);

  const handleCurrencyChange = async (currency: string) => {
    if (currency === selectedCurrency) return;
    
    setIsUpdating(true);
    try {
      const response = await settingsService.updateCurrency(currency);
      if (response.success) {
        setSelectedCurrency(currency);
        onUpdate({ currency }); // Update parent state
        toast.success(`Currency updated to ${currency}`);
      } else {
        throw new Error(response.message || 'Failed to update currency');
      }
    } catch (error) {
      toast.error('Failed to update currency');
      // Revert selection on error
      setSelectedCurrency(settings?.currency || 'USD');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-medium">Currency Settings</h3>
        <span className="text-sm text-gray-500">Selected: {selectedCurrency}</span>
      </div>
      
      <div className="grid gap-4">
        {currencies.map((currency) => (
          <label
            key={currency.code}
            className={`flex items-center justify-between p-4 rounded-lg border ${
              isUpdating ? 'opacity-50 cursor-not-allowed' : 'hover:border-primary-300 cursor-pointer'
            } ${
              selectedCurrency === currency.code ? 'border-primary-500 bg-primary-50' : 'border-gray-200'
            } transition-colors`}
          >
            <div className="flex items-center gap-3">
              <Coins className="w-5 h-5 text-primary-600" />
              <div>
                <div className="font-medium">{currency.name}</div>
                <div className="text-sm text-gray-600">{currency.code} ({currency.symbol})</div>
              </div>
            </div>
            <input
              type="radio"
              name="currency"
              value={currency.code}
              checked={selectedCurrency === currency.code}
              onChange={() => handleCurrencyChange(currency.code)}
              disabled={isUpdating}
              className="h-4 w-4 text-primary-600 focus:ring-primary-500 disabled:opacity-50"
            />
          </label>
        ))}
      </div>
    </div>
  );
}