import type { Investment } from '@/types/investment';

export function calculateInvestmentValue(investments: Investment[]): number {
  return Number(investments.reduce((sum, inv) => sum + Number(inv.currentValue), 0).toFixed(2));
}

export function calculatePortfolioReturns(investments: Investment[]): number {
  if (investments.length === 0) return 0;
  
  const totalInvested = investments.reduce((sum, inv) => sum + Number(inv.amount), 0);
  const totalCurrent = investments.reduce((sum, inv) => sum + Number(inv.currentValue), 0);
  
  if (totalInvested === 0) return 0;
  
  const totalReturnPercentage = ((totalCurrent - totalInvested) / totalInvested) * 100;
  return Number(totalReturnPercentage.toFixed(1));
}

export function calculateInvestmentDuration(investments: Investment[]): number {
  if (investments.length === 0) return 0;
  
  const firstInvestmentDate = Math.min(
    ...investments.map(inv => new Date(inv.createdAt).getTime())
  );
  
  const durationInMonths = Math.floor(
    (Date.now() - firstInvestmentDate) / (1000 * 60 * 60 * 24 * 30.44)
  );
  return Math.max(0, durationInMonths);
}

export function calculateInvestmentDiversity(investments: Investment[]): number {
  if (investments.length === 0) return 0;
  
  const uniqueTypes = new Set(
    investments
      .filter(inv => inv.property?.type)
      .map(inv => inv.property!.type.toLowerCase())
  );
  return uniqueTypes.size;
}

export function calculateMilestoneProgress(
  currentValue: number,
  milestones: { value: number }[]
): number {
  if (!milestones.length) return 0;
  
  // Sort milestones by value
  const sortedValues = milestones.map(m => m.value).sort((a, b) => a - b);
  const firstMilestone = sortedValues[0];
  
  // If we haven't reached the first milestone
  if (currentValue < firstMilestone) {
    const progress = (currentValue / firstMilestone) * 100;
    return Number(progress.toFixed(1)); // Round to 1 decimal place
  }
  
  // Find the last milestone we've passed and the next one
  const lastPassedIndex = sortedValues.findIndex(value => value > currentValue) - 1;
  const lastPassed = lastPassedIndex >= 0 ? sortedValues[lastPassedIndex] : sortedValues[0];
  const nextMilestone = sortedValues[lastPassedIndex + 1];
  
  // If we've passed all milestones
  if (!nextMilestone) return 100;
  
  // Calculate progress between milestones
  const baseProgress = (lastPassedIndex + 1) * (100 / sortedValues.length);
  const extraProgress = ((currentValue - lastPassed) / (nextMilestone - lastPassed)) * (100 / sortedValues.length);
  
  // Round to 1 decimal place
  return Number((baseProgress + extraProgress).toFixed(1));
}

export function formatMilestoneValue(value: number, category: string): string {
  switch (category) {
    case 'investment_value':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
      
    case 'portfolio_returns':
      return `${value.toFixed(1)}%`;
      
    case 'investment_duration':
      return `${value} ${value === 1 ? 'month' : 'months'}`;
      
    case 'investment_diversity':
      return `${value} ${value === 1 ? 'type' : 'types'}`;
      
    default:
      return value.toString();
  }
}

export function getNextMilestone(currentValue: number, milestones: number[]): number | null {
  const nextMilestone = [...milestones]
    .sort((a, b) => a - b)
    .find(m => m > currentValue);
  return nextMilestone || null;
}

export function getReachedMilestones(currentValue: number, milestones: number[]): number[] {
  return [...milestones]
    .sort((a, b) => a - b)
    .filter(m => currentValue >= m);
}