import React from 'react';
import { MapPin, Bed, Heart } from 'lucide-react';
import type { Property } from '@/types/property';

interface PropertyHeaderProps {
  property: Property;
  isInWatchlist: boolean;
  onWatchlistClick: (e: React.MouseEvent) => void;
}

export function PropertyHeader({ property, isInWatchlist, onWatchlistClick }: PropertyHeaderProps) {
  return (
    <div className="flex justify-between items-start mb-6">
      <div>
        <h1 className="text-3xl font-bold text-gray-900 mb-2">{property.title}</h1>
        <div className="flex items-center gap-4">
          <p className="text-gray-600 flex items-center gap-2">
            <MapPin className="w-5 h-5" />
            {property.location.address}
          </p>
          {property.type === 'residential' && (
            <p className="text-gray-600 flex items-center gap-2">
              <Bed className="w-5 h-5" />
              {property.features.length} Features
            </p>
          )}
        </div>
      </div>
      <button
        onClick={onWatchlistClick}
        className="p-2 text-gray-400 hover:text-primary-600 rounded-lg hover:bg-gray-50"
      >
        <Heart className={`w-6 h-6 ${isInWatchlist ? 'fill-primary-600 text-primary-600' : ''}`} />
      </button>
    </div>
  );
}