import { ethers } from 'ethers';
import { initializeContract } from './initialization';
import { findTransferEvent } from './events';

export class NFTContract {
  private contract: ethers.Contract | null = null;
  private signer: ethers.providers.JsonRpcSigner | null = null;
  private chainId: number | null = null;

  async safeMint(to: string, uri: string): Promise<string> {
    try {
      // Initialize contract (will throw if requirements not met)
      const { contract, signer, chainId } = await initializeContract();
      this.contract = contract;
      this.signer = signer;
      this.chainId = chainId;

      console.log('Minting NFT:', {
        to,
        uri,
        chainId: this.chainId,
        contractAddress: this.contract.address
      });

      // Estimate gas with 20% buffer
      const gasEstimate = await this.contract.estimateGas.safeMint(to, uri);
      const gasLimit = gasEstimate.mul(120).div(100);

      // Get gas price with 20% buffer
      const gasPrice = await this.signer.getGasPrice();
      const adjustedGasPrice = gasPrice.mul(120).div(100);

      // Send transaction
      const tx = await this.contract.safeMint(to, uri, {
        gasLimit,
        gasPrice: adjustedGasPrice
      });

      console.log('Transaction sent:', tx.hash);

      // Wait for confirmations
      const receipt = await tx.wait(3);
      
      if (receipt.status !== 1) {
        throw new Error('Transaction failed');
      }

      // Find transfer event
      const transferEvent = findTransferEvent(receipt, this.contract, to);
      if (!transferEvent) {
        throw new Error('Could not find transfer event');
      }

      // Get token ID from event
      const parsedEvent = this.contract.interface.parseLog(transferEvent);
      const tokenId = parsedEvent.args.tokenId.toString();

      console.log('NFT minted successfully:', {
        tokenId,
        txHash: receipt.transactionHash
      });

      return tokenId;
    } catch (error: any) {
      console.error('NFT minting error:', {
        error,
        code: error.code,
        message: error.message,
        data: error.data
      });

      // Throw error to be handled by caller
      throw error;
    }
  }
}

export const nftContract = new NFTContract();