import { urlCache } from './cache';
import { imageRateLimiter } from './rate-limit';

const DEBUG = true;
const S3_REGION = import.meta.env.VITE_AWS_REGION;
const S3_BUCKET = import.meta.env.VITE_AWS_S3_BUCKET;

const BATCH_SIZE = 5;
const BATCH_DELAY = 500;
const REQUEST_DELAY = 100;

let requestQueue: Array<() => Promise<void>> = [];
let isProcessingQueue = false;

async function processQueue() {
  if (isProcessingQueue) return;
  isProcessingQueue = true;

  while (requestQueue.length > 0) {
    const request = requestQueue.shift();
    if (request) {
      await request();
      await new Promise(resolve => setTimeout(resolve, REQUEST_DELAY));
    }
  }

  isProcessingQueue = false;
}

export async function getSignedUrl(url: string, publicAccess = false): Promise<string> {
  // Return original URL if not an S3 URL
  if (!url || !url.includes('amazonaws.com')) {
    return url;
  }

  // For public access, return a direct S3 URL with virtual hosted-style path
  if (publicAccess) {
    const key = extractS3Key(url);
    if (!key) {
      return url;
    }
    // Use path-style URL instead of virtual hosted-style to avoid SSL issues
    const publicUrl = `https://s3.${S3_REGION}.amazonaws.com/${S3_BUCKET}/${key}`;
    return publicUrl;
  }

  // Check cache first
  const cachedUrl = urlCache.get(url);
  if (cachedUrl) {
    if (DEBUG) console.log('[S3] Returning cached URL for:', url);
    return cachedUrl;
  }

  // Check rate limit
  if (!imageRateLimiter.canMakeRequest()) {
    if (DEBUG) console.log('[S3] Rate limit exceeded for:', url);
    return url;
  }

  // Extract S3 key
  const key = extractS3Key(url);
  if (!key) {
    if (DEBUG) console.log('[S3] Failed to extract key:', url);
    return url;
  }

  // Add request to queue
  return new Promise((resolve) => {
    const request = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          if (DEBUG) console.log('[S3] No auth token found');
          resolve(url);
          return;
        }

        const response = await fetch(
          `${import.meta.env.VITE_API_URL}${import.meta.env.VITE_API_PREFIX}/s3/signed-url`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ key, public: publicAccess })
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to get signed URL: ${response.statusText}`);
        }

        const data = await response.json();
        if (!data.success) {
          throw new Error(data.message || 'Failed to get signed URL');
        }

        // Cache the signed URL
        urlCache.set(url, data.url, 55);
        if (DEBUG) console.log('[S3] Successfully got signed URL for:', url);
        resolve(data.url);
      } catch (error) {
        console.error('[S3] Error getting signed URL:', error);
        resolve(url);
      }
    };

    requestQueue.push(request);
    processQueue();
  });
}

export async function getSignedUrls(urls: string[], publicAccess = false): Promise<string[]> {
  if (DEBUG) console.log('[S3] Getting signed URLs for batch:', urls);

  if (!urls || !Array.isArray(urls)) {
    console.warn('[S3] Invalid URLs array:', urls);
    return [];
  }

  const results: string[] = [];
  
  for (let i = 0; i < urls.length; i += BATCH_SIZE) {
    const batch = urls.slice(i, i + BATCH_SIZE);
    const batchPromises = batch.map(url => getSignedUrl(url, publicAccess));
    
    const batchResults = await Promise.all(batchPromises);
    results.push(...batchResults);
    
    if (i + BATCH_SIZE < urls.length) {
      await new Promise(resolve => setTimeout(resolve, BATCH_DELAY));
    }
  }
  
  if (DEBUG) console.log('[S3] Completed batch signing:', results);
  return results;
}

export function extractS3Key(url: string): string | null {
  if (DEBUG) console.log('[S3] Extracting key from URL:', url);
  try {
    if (!url.includes('amazonaws.com')) {
      return url;
    }

    // Handle direct S3 keys
    if (url.startsWith('avatars/') || url.startsWith('uploads/')) {
      return url;
    }

    // Extract key from full S3 URL
    const match = url.match(/amazonaws\.com\/([^?]+)/);
    if (!match) return null;

    const key = decodeURIComponent(match[1]);
    if (DEBUG) console.log('[S3] Extracted raw key:', key);

    // Remove any domain prefix from the key
    const cleanKey = key.replace(/^.*?amazonaws\.com\//, '');
    
    // Handle nested URLs
    if (cleanKey.includes('amazonaws.com')) {
      return extractS3Key(cleanKey);
    }

    // Remove any URL encoding artifacts
    const result = cleanKey.replace(/^new\.prod\.sociallocket\.images\//, '')
                         .replace(/^new\.prod\.sociallocket\.images\.s3\..*?\//, '');

    if (DEBUG) console.log('[S3] Final cleaned key:', result);
    return result;
  } catch (error) {
    console.error('[S3] Failed to extract key:', error);
    return null;
  }
}