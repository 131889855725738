import { ethers } from 'ethers';
import type { SupportedChainId } from '../types';

// Gas configuration constants
const GAS_MULTIPLIER = 1.2; // 20% buffer
const MAX_RETRIES = 3;
const RETRY_MULTIPLIERS = [1, 1.2, 1.5]; // Increase gas by 20%, 50% on retries

// Base gas settings per chain
const CHAIN_GAS_SETTINGS = {
  137: { // Polygon
    basePriorityFee: ethers.utils.parseUnits('35', 'gwei'),
    maxFee: ethers.utils.parseUnits('100', 'gwei')
  },
  56: { // BSC
    basePriorityFee: ethers.utils.parseUnits('5', 'gwei'),
    maxFee: ethers.utils.parseUnits('10', 'gwei')
  }
} as const;

export async function calculateGasConfig(
  provider: ethers.providers.Provider,
  chainId: SupportedChainId,
  gasEstimate: ethers.BigNumber,
  retryAttempt: number = 0
) {
  try {
    // Get chain-specific settings
    const chainSettings = CHAIN_GAS_SETTINGS[chainId];
    if (!chainSettings) {
      throw new Error(`No gas settings for chain ${chainId}`);
    }

    // Get current network conditions
    const feeData = await provider.getFeeData();
    if (!feeData.maxFeePerGas || !feeData.maxPriorityFeePerGas) {
      throw new Error('Failed to get network fee data');
    }

    // Apply retry multiplier
    const multiplier = RETRY_MULTIPLIERS[retryAttempt] || RETRY_MULTIPLIERS[RETRY_MULTIPLIERS.length - 1];

    // Calculate gas parameters
    const maxPriorityFeePerGas = ethers.BigNumber.from(
      Math.max(
        Number(feeData.maxPriorityFeePerGas),
        Number(chainSettings.basePriorityFee)
      )
    ).mul(Math.floor(multiplier * 100)).div(100);

    const maxFeePerGas = ethers.BigNumber.from(
      Math.max(
        Number(feeData.maxFeePerGas),
        Number(chainSettings.maxFee)
      )
    ).mul(Math.floor(multiplier * 100)).div(100);

    // Add buffer to gas limit
    const gasLimit = gasEstimate.mul(Math.floor(GAS_MULTIPLIER * 100)).div(100);

    return {
      maxFeePerGas,
      maxPriorityFeePerGas,
      gasLimit
    };
  } catch (error) {
    console.error('Calculate gas config error:', error);
    throw error;
  }
}