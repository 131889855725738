import React, { useEffect } from 'react';
import { PortfolioChart } from '../components/PortfolioChart';
import { RewardsSection } from '../components/RewardsSection';
import { DashboardStats } from '../components/dashboard/DashboardStats';
import { DashboardHeader } from '../components/dashboard/DashboardHeader';
import { InvestmentSection } from '../components/dashboard/investments/InvestmentSection';
import { WatchlistSection } from '../components/dashboard/WatchlistSection';
import { LoadingState } from '../components/dashboard/LoadingState';
import { ErrorState } from '../components/dashboard/ErrorState';
import { EmptyState } from '../components/dashboard/EmptyState';
import { useInvestments } from '../hooks/useInvestments';
import { useAuth } from '@/hooks/useAuth';
import { investmentValueService } from '@/services/api/investments/value';
import toast from 'react-hot-toast';

const BATCH_SIZE = 3; // Process 3 investments at a time
const BATCH_DELAY = 2000; // 2 seconds between batches

export function Dashboard() {
  const { user } = useAuth();
  const { 
    investments, 
    isLoading, 
    error,
    filteredInvestments,
    searchTerm,
    setSearchTerm,
    selectedType,
    setSelectedType,
    sortBy,
    setSortBy,
    refreshInvestments
  } = useInvestments();

  // Update investment values when dashboard loads
  useEffect(() => {
    const updateInvestmentValues = async () => {
      if (!investments.length) return;

      try {
        // Process investments in batches
        for (let i = 0; i < investments.length; i += BATCH_SIZE) {
          const batch = investments.slice(i, i + BATCH_SIZE);
          
          // Update each investment in the current batch
          await Promise.all(
            batch.map(investment => 
              investmentValueService.updateInvestmentValue(investment.id)
            )
          );

          // Wait before processing next batch
          if (i + BATCH_SIZE < investments.length) {
            await new Promise(resolve => setTimeout(resolve, BATCH_DELAY));
          }
        }
        
        // Refresh investments to get updated values
        await refreshInvestments();
      } catch (error) {
        console.error('Failed to update investment values:', error);
        toast.error('Failed to update some investment values');
      }
    };

    updateInvestmentValues();
  }, [investments.length]); // Only run when investments array length changes

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState message={error} />;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main Content */}
        <div className="flex-1">
          <div className="bg-white p-6 rounded-xl border border-gray-200">
            <DashboardHeader />

            {investments.length === 0 ? (
              <EmptyState />
            ) : (
              <>
                <DashboardStats investments={investments} />

                <div className="mb-8">
                  <PortfolioChart data={investments} />
                </div>

                <InvestmentSection
                  investments={filteredInvestments}
                  user={user}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              </>
            )}
          </div>
        </div>

        {/* Right Sidebar */}
        <div className="md:w-96">
          <div className="sticky top-24 space-y-8">
            <RewardsSection />
            <WatchlistSection />
          </div>
        </div>
      </div>
    </div>
  );
}