import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { PropertyList } from './PropertyList';
import { PropertyForm } from './form/PropertyForm';
import { PropertyDetails } from './PropertyDetails';

export function PropertyManager() {
  return (
    <Routes>
      <Route path="/" element={<PropertyList />} />
      <Route path="/new" element={<PropertyForm />} />
      <Route path="/:id" element={<PropertyDetails />} />
      <Route path="/:id/edit" element={<PropertyForm />} />
    </Routes>
  );
}