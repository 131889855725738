import React, { useState } from 'react';
import { FileText, Download, Lock, AlertCircle, Eye } from 'lucide-react';
import { documentService } from '@/services/api/properties/documents';
import { downloadFile } from '@/utils/document';
import { useAuth } from '@/hooks/useAuth';
import toast from 'react-hot-toast';
import type { Document } from '@/types/document';

interface PropertyDocumentsProps {
  propertyId: string; // Make this required by removing the optional '?'
  documents: Document[];
  onDelete?: (documentId: string) => void;
  isAdmin?: boolean;
}

const documentTypeIcons = {
  prospectus: '📊',
  financial_report: '📈',
  legal: '⚖️',
  contract: '📝',
  other: '📄'
} as const;

const formatFileSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

export function PropertyDocuments({ propertyId, documents }: PropertyDocumentsProps) {
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [viewingId, setViewingId] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();

  const handleDownload = async (doc: Document) => {
    if (!isAuthenticated && !doc.isPublic) {
      toast.error('Please log in to download this document');
      return;
    }

    try {
      setDownloadingId(doc.id);
      const response = await documentService.downloadDocument(propertyId, doc.id);
      
      if (response.success && response.data) {
        await downloadFile(response.data.url, response.data.filename);
        toast.success('Document downloaded successfully');
      }
    } catch (error) {
      console.error('Download error:', error);
      toast.error('Failed to download document');
    } finally {
      setDownloadingId(null);
    }
  };

  const handleView = async (doc: Document) => {
    if (!isAuthenticated && !doc.isPublic) {
      toast.error('Please log in to view this document');
      return;
    }

    try {
      setViewingId(doc.id);
      const response = await documentService.viewDocument(propertyId, doc.id);
      
      if (response.success && response.data) {
        window.open(response.data.url, '_blank');
      }
    } catch (error) {
      console.error('View error:', error);
      toast.error('Failed to view document');
    } finally {
      setViewingId(null);
    }
  };

  if (!documents.length) {
    return (
      <div className="bg-gray-50 rounded-lg p-6 text-center">
        <FileText className="w-12 h-12 text-gray-400 mx-auto mb-4" />
        <p className="text-gray-600">No documents available for this property</p>
      </div>
    );
  }

  // Separate documents into public and private
  const publicDocs = documents.filter(doc => doc.isPublic);
  const privateDocs = documents.filter(doc => !doc.isPublic);

  return (
    <div className="space-y-6">
      {/* Public Documents */}
      {publicDocs.length > 0 && (
        <div>
          <h3 className="text-lg font-medium mb-4">Public Documents</h3>
          <div className="space-y-3">
            {publicDocs.map((doc) => (
              <div
                key={doc.id}
                className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200 hover:border-primary-300 transition-colors"
              >
                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 bg-primary-50 rounded-lg flex items-center justify-center text-xl">
                    {documentTypeIcons[doc.type]}
                  </div>
                  <div>
                    <h4 className="font-medium">{doc.title}</h4>
                    <div className="flex items-center gap-3 text-sm text-gray-500">
                      <span>{formatFileSize(doc.fileSize)}</span>
                      <span>•</span>
                      <span className="capitalize">{doc.type.replace('_', ' ')}</span>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleView(doc)}
                    disabled={viewingId === doc.id}
                    className="flex items-center gap-2 px-4 py-2 text-primary-600 hover:text-primary-700 hover:bg-primary-50 rounded-lg transition-colors disabled:opacity-50"
                  >
                    <Eye className="w-4 h-4" />
                    <span>View</span>
                  </button>
                  <button
                    onClick={() => handleDownload(doc)}
                    disabled={downloadingId === doc.id}
                    className="flex items-center gap-2 px-4 py-2 text-primary-600 hover:text-primary-700 hover:bg-primary-50 rounded-lg transition-colors disabled:opacity-50"
                  >
                    <Download className="w-4 h-4" />
                    <span>Download</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Private Documents */}
      {privateDocs.length > 0 && (
        <div>
          <h3 className="text-lg font-medium mb-4">Private Documents</h3>
          <div className="space-y-3">
            {privateDocs.map((doc) => (
              <div
                key={doc.id}
                className="flex items-center justify-between p-4 bg-white rounded-lg border border-gray-200 hover:border-primary-300 transition-colors"
              >
                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 bg-amber-50 rounded-lg flex items-center justify-center text-xl">
                    {documentTypeIcons[doc.type]}
                  </div>
                  <div>
                    <div className="flex items-center gap-2">
                      <h4 className="font-medium">{doc.title}</h4>
                      <Lock className="w-4 h-4 text-amber-600" />
                    </div>
                    <div className="flex items-center gap-3 text-sm text-gray-500">
                      <span>{formatFileSize(doc.fileSize)}</span>
                      <span>•</span>
                      <span className="capitalize">{doc.type.replace('_', ' ')}</span>
                    </div>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    onClick={() => handleView(doc)}
                    disabled={!isAuthenticated || viewingId === doc.id}
                    className="flex items-center gap-2 px-4 py-2 text-primary-600 hover:text-primary-700 hover:bg-primary-50 rounded-lg transition-colors disabled:opacity-50"
                  >
                    <Eye className="w-4 h-4" />
                    <span>View</span>
                  </button>
                  <button
                    onClick={() => handleDownload(doc)}
                    disabled={!isAuthenticated || downloadingId === doc.id}
                    className="flex items-center gap-2 px-4 py-2 text-primary-600 hover:text-primary-700 hover:bg-primary-50 rounded-lg transition-colors disabled:opacity-50"
                  >
                    <Download className="w-4 h-4" />
                    <span>Download</span>
                  </button>
                </div>
              </div>
            ))}
          </div>

          {!isAuthenticated && (
            <div className="flex items-start gap-2 p-4 bg-amber-50 text-amber-800 rounded-lg mt-4">
              <AlertCircle className="w-5 h-5 mt-0.5 flex-shrink-0" />
              <div className="text-sm">
                <p className="font-medium">Login Required</p>
                <p>Please log in to access private documents. Public documents are available for download without authentication.</p>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}