import api from './axios';
import { getSignedUrls } from '@/utils/s3';
import type { Property, PropertyFilters } from '@/types/property';
import type { ApiResponse } from '@/types/response';

export const propertyService = {
  async getProperties(filters?: PropertyFilters) {
    try {
      const response = await api.get<ApiResponse<Property[]>>('/properties', {
        params: filters
      });

      if (!response.data.success || !Array.isArray(response.data.data)) {
        throw new Error(response.data.message || 'Failed to fetch properties');
      }

      // Transform the data
      const properties = response.data.data.map(property => ({
        ...property,
        targetAmount: parseFloat(String(property.targetAmount)),
        raisedAmount: parseFloat(String(property.raisedAmount)) || 0,
        minInvestment: parseFloat(String(property.minInvestment)),
        maxInvestment: parseFloat(String(property.maxInvestment)),
        expectedReturn: parseFloat(String(property.expectedReturn)),
        // Just use investors field directly since we added investorCount to the type
        investors: property.investors || 0,
        images: Array.isArray(property.images) ? property.images : []
      }));

      // Get signed URLs for all images with public access
      const propertiesWithSignedUrls = await Promise.all(
        properties.map(async (property) => ({
          ...property,
          images: await getSignedUrls(property.images, true)
        }))
      );

      return {
        success: true,
        data: propertiesWithSignedUrls
      };
    } catch (error) {
      console.error('Get Properties Error:', error);
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Failed to fetch properties',
        data: []
      };
    }
  },

  async getProperty(id: string) {
    try {
      const response = await api.get<ApiResponse<Property>>(`/properties/${id}`);
      
      if (!response.data.success || !response.data.data) {
        throw new Error(response.data.message || 'Failed to fetch property');
      }

      const property = response.data.data;
      
      // Transform the data
      const transformedProperty = {
        ...property,
        targetAmount: parseFloat(String(property.targetAmount)),
        raisedAmount: parseFloat(String(property.raisedAmount)) || 0,
        minInvestment: parseFloat(String(property.minInvestment)),
        maxInvestment: parseFloat(String(property.maxInvestment)),
        expectedReturn: parseFloat(String(property.expectedReturn)),
        investors: property.investors || 0,
        images: Array.isArray(property.images) ? await getSignedUrls(property.images, true) : []
      };

      return {
        success: true,
        data: transformedProperty
      };
    } catch (error) {
      console.error('Get Property Error:', error);
      return {
        success: false,
        error: error instanceof Error ? error.message : 'Failed to fetch property'
      };
    }
  },

  async saveProperty(id: string) {
    try {
      const response = await api.post<ApiResponse<void>>(`/properties/${id}/save`);
      return response.data;
    } catch (error) {
      console.error('Save property error:', error);
      throw error;
    }
  },

  async removeFromWatchlist(id: string) {
    try {
      const response = await api.delete<ApiResponse<void>>(`/properties/${id}/save`);
      return response.data;
    } catch (error) {
      console.error('Remove from watchlist error:', error);
      throw error;
    }
  },

  async submitInvestment(propertyId: string, amount: number, paymentMethod: string) {
    try {
      // Only submit investment for non-crypto payments
      if (paymentMethod !== 'crypto') {
        const response = await api.post<ApiResponse<void>>(`/properties/${propertyId}/invest`, {
          amount,
          paymentMethod
        });
        return response.data;
      }
      // For crypto payments, return success without creating investment
      // The investment will be created after crypto payment confirmation
      return { success: true };
    } catch (error) {
      console.error('Submit investment error:', error);
      throw error;
    }
  }
};