import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Plus, Search, MapPin, DollarSign, Users, RefreshCw, Loader, Download, Filter } from 'lucide-react';
import { propertyService } from '@/services/api/admin/properties';
import { exportToCsv, formatPropertyForExport } from '@/utils/export';
import type { Property } from '@/types/property';
import toast from 'react-hot-toast';

export function PropertyList() {
  const [properties, setProperties] = useState<Property[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [isRetrying, setIsRetrying] = useState(false);

  const fetchProperties = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await propertyService.getProperties();
      if (response.success) {
        setProperties(response.data);
      } else {
        throw new Error('Failed to load properties');
      }
    } catch (error) {
      console.error('Failed to load properties:', error);
      setError(error instanceof Error ? error.message : 'Failed to load properties');
      toast.error('Failed to load properties');
    } finally {
      setIsLoading(false);
      setIsRetrying(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, []);

  const handleExport = () => {
    try {
      const formattedData = properties.map(formatPropertyForExport);
      exportToCsv(formattedData, `properties-${new Date().toISOString().split('T')[0]}.csv`);
      toast.success('Properties exported successfully');
    } catch (error) {
      console.error('Export error:', error);
      toast.error('Failed to export properties');
    }
  };

  const handleRetry = () => {
    setIsRetrying(true);
    fetchProperties();
  };

  const filteredProperties = properties.filter(property => {
    const matchesSearch = 
      property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      property.location.address.toLowerCase().includes(searchTerm.toLowerCase());
    
    if (filter === 'active') return matchesSearch && property.status === 'active';
    if (filter === 'funded') return matchesSearch && property.status === 'funded';
    if (filter === 'closed') return matchesSearch && property.status === 'closed';
    
    return matchesSearch;
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="flex flex-col items-center gap-4">
          <Loader className="w-8 h-8 text-primary-600 animate-spin" />
          <p className="text-gray-600">Loading properties...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600 mb-4">{error}</p>
        <button
          onClick={handleRetry}
          disabled={isRetrying}
          className="inline-flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
        >
          <RefreshCw className={`w-5 h-5 ${isRetrying ? 'animate-spin' : ''}`} />
          {isRetrying ? 'Retrying...' : 'Try Again'}
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Properties</h1>
        <div className="flex items-center gap-4">
          <button
            onClick={handleExport}
            className="flex items-center gap-2 px-4 py-2 text-primary-600 bg-primary-50 rounded-lg hover:bg-primary-100"
          >
            <Download className="w-5 h-5" />
            Export CSV
          </button>
          <Link
            to="/admin/properties/new"
            className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
          >
            <Plus className="w-5 h-5" />
            Add Property
          </Link>
        </div>
      </div>

      <div className="flex gap-4 mb-6">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
          <input
            type="text"
            placeholder="Search properties..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          />
        </div>
        <div className="relative">
          <Filter className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="pl-10 pr-8 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 appearance-none bg-white"
          >
            <option value="all">All Properties</option>
            <option value="active">Active</option>
            <option value="funded">Funded</option>
            <option value="closed">Closed</option>
          </select>
        </div>
      </div>

      <div className="bg-white rounded-xl border border-gray-200 overflow-hidden">
        {filteredProperties.length === 0 ? (
          <div className="p-8 text-center text-gray-600">
            No properties found
          </div>
        ) : (
          <div className="divide-y divide-gray-200">
            {filteredProperties.map((property) => (
              <Link
                key={property.id}
                to={`/admin/properties/${property.id}`}
                className="block p-6 hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <h3 className="font-semibold text-lg mb-2">{property.title}</h3>
                    <div className="flex items-center gap-4 text-gray-600">
                      <div className="flex items-center gap-1">
                        <MapPin className="w-4 h-4" />
                        <span>{property.location.address}</span>
                      </div>
                      <div className="flex items-center gap-1">
                        <Users className="w-4 h-4" />
                        <span>{property.investors} investors</span>
                      </div>
                    </div>
                  </div>
                  <span className={`px-3 py-1 rounded-full text-sm font-medium ${
                    property.status === 'funded' ? 'bg-blue-100 text-blue-800' :
                    property.status === 'closed' ? 'bg-gray-100 text-gray-800' : 
                    'bg-green-100 text-green-800'
                  }`}>
                    {property.status.charAt(0).toUpperCase() + property.status.slice(1)}
                  </span>
                </div>

                <div className="mt-4 grid grid-cols-3 gap-4">
                  <div>
                    <div className="text-sm text-gray-600">Target Amount</div>
                    <div className="font-semibold">${property.targetAmount.toLocaleString()}</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-600">Raised</div>
                    <div className="font-semibold text-primary-600">
                      ${property.raisedAmount.toLocaleString()}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-600">Progress</div>
                    <div className="font-semibold text-primary-600">
                      {((property.raisedAmount / property.targetAmount) * 100).toFixed(1)}%
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}