import React, { useState, useRef } from 'react';
import { Download, Share2 } from 'lucide-react';
import { InvestmentCertificate } from './InvestmentCertificate';
import type { Investment } from '@/types/investment';
import type { UserProfile } from '@/types/user';
import { generateInvestmentCertificatePDF } from '@/utils/pdf';
import toast from 'react-hot-toast';

interface CertificateViewerProps {
  investment: Investment;
  user: UserProfile;
}

export function CertificateViewer({ investment, user }: CertificateViewerProps) {
  console.log('Investment data for certificate:', investment); 
  console.log('Rendering certificate with investment:', {
    id: investment.id,
    status: investment.status,
    transactionHash: investment.transactionHash,
    nftTokenId: investment.nftTokenId
  });
  const [isDownloading, setIsDownloading] = useState(false);
  const certificateRef = useRef<HTMLDivElement>(null);

const handleDownload = async () => {
  if (!certificateRef.current) return;
  
  setIsDownloading(true);
    try {
        const pdfBlob = await generateInvestmentCertificatePDF(
        certificateRef.current,
        investment,
        user
        );

        // Create download link
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `investment-certificate-${investment.id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);

            toast.success('Certificate downloaded successfully');
        } catch (error) {
            console.error('Download error:', error);
            toast.error('Failed to download certificate');
        } finally {
            setIsDownloading(false);
        }
    };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: 'Investment Certificate',
          text: `Check out my investment in ${investment.property?.title}`,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(window.location.href);
        toast.success('Link copied to clipboard');
      }
    } catch (error) {
      console.error('Share failed:', error);
    }
  };

  return (
    <div className="space-y-6">
      {/* Actions */}
      <div className="flex justify-end gap-4">
        <button
          onClick={handleDownload}
          disabled={isDownloading}
          className="flex items-center gap-2 px-4 py-2 text-primary-600 hover:bg-primary-50 rounded-lg transition-colors"
        >
          <Download className="w-5 h-5" />
          {isDownloading ? 'Downloading...' : 'Download'}
        </button>
        <button
          onClick={handleShare}
          className="flex items-center gap-2 px-4 py-2 text-primary-600 hover:bg-primary-50 rounded-lg transition-colors"
        >
          <Share2 className="w-5 h-5" />
          Share
        </button>
      </div>

      {/* Certificate */}
      <InvestmentCertificate
        ref={certificateRef}
        investment={investment}
        user={user}
        showNFTDetails={true}
      />
    </div>
  );
};