import type { UserProfile } from '@/types/user';
import type { Investment } from '@/types/investment';
import { formatLocationString } from '@/utils/property';

export interface NFTMetadata {
  name: string;
  description: string;
  image: string;
  attributes: Array<{
    trait_type: string;
    value: string | number;
  }>;
}

export function generateNFTMetadata(
  user: UserProfile,
  investment: Investment
): NFTMetadata {
  return {
    name: `${investment.property?.title || 'Property'} Investment Certificate`,
    description: `Investment certificate for ${user.name}`,
    image: investment.property?.images[0] || '',
    attributes: [
      {
        trait_type: 'Investor Name',
        value: user.name
      },
      {
        trait_type: 'Email',
        value: user.email
      },
      {
        trait_type: 'Phone',
        value: user.phoneNumber || 'Not provided'
      },
      {
        trait_type: 'Property Address',
        value: investment.property?.location ? formatLocationString(investment.property.location) : 'Unknown Location'
      },
      {
        trait_type: 'Investment Amount',
        value: investment.amount
      },
      {
        trait_type: 'Expected Returns',
        value: investment.property?.expectedReturn || 0
      },
      {
        trait_type: 'Investment Date',
        value: new Date(investment.createdAt).toISOString()
      }
    ]
  };
}