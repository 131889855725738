import React from 'react';
import { FileText, AlertCircle } from 'lucide-react';
import { DocumentList } from '../documents/DocumentList';
import { DocumentUpload } from '../documents/DocumentUpload';
import type { DocumentFormData } from '@/types/document';

interface PropertyDocumentsProps {
  documents: DocumentFormData[];
  onDocumentsChange: (documents: DocumentFormData[]) => void;
  errors?: Record<string, string>;
}

export function PropertyDocuments({ documents, onDocumentsChange, errors }: PropertyDocumentsProps) {
  const handleAddDocument = (document: DocumentFormData) => {
    onDocumentsChange([...documents, document]);
  };

  const handleRemoveDocument = (index: number) => {
    const newDocuments = [...documents];
    newDocuments.splice(index, 1);
    onDocumentsChange(newDocuments);
  };

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <FileText className="w-5 h-5 text-primary-600" />
        Property Documents
      </h2>

      <div className="space-y-6">
        <DocumentUpload onUpload={handleAddDocument} />

        <div className="border-t border-gray-200 pt-6">
          <h3 className="text-sm font-medium text-gray-700 mb-4">Added Documents</h3>
          <DocumentList
            documents={documents}
            onRemove={handleRemoveDocument}
          />
        </div>

        {errors?.documents && (
          <div className="flex items-start gap-2 text-red-600">
            <AlertCircle className="w-5 h-5 mt-0.5" />
            <span>{errors.documents}</span>
          </div>
        )}

        <div className="text-sm text-gray-500 space-y-1">
          <p>Supported formats: PDF, DOC, DOCX</p>
          <p>Maximum file size: 10MB</p>
        </div>
      </div>
    </div>
  );
}