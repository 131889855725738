import React from 'react';
import type { Property } from '@/types/property';

interface PropertyProgressProps {
  property: Property;
  progress: number;
}

export function PropertyProgress({ property, progress }: PropertyProgressProps) {
  return (
    <div className="mb-6">
      <div className="flex justify-between text-sm mb-2">
        <span className="text-gray-600">Funding Progress</span>
        <span className="text-primary-600 font-semibold">{progress.toFixed(1)}% Funded</span>
      </div>
      <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
        <div 
          className="h-full bg-gradient-to-r from-primary-500 to-primary-600 rounded-full transition-all duration-500"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <div className="flex justify-between text-sm mt-2">
        <span className="text-gray-600">Raised: ${property.raisedAmount.toLocaleString()}</span>
        <span className="text-gray-600">Target: ${property.targetAmount.toLocaleString()}</span>
      </div>
    </div>
  );
}