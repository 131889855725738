import { toast } from 'react-hot-toast';

interface RateLimitConfig {
  maxRequests: number;
  windowMs: number;
  retryAfter?: number;
  maxConcurrent?: number;
}

class RateLimiter {
  private requests: Map<string, number[]> = new Map();
  private concurrentRequests: Map<string, number> = new Map();
  private readonly config: RateLimitConfig;

  constructor(config: RateLimitConfig) {
    this.config = {
      maxRequests: config.maxRequests,
      windowMs: config.windowMs,
      retryAfter: config.retryAfter || 1000,
      maxConcurrent: config.maxConcurrent
    };
  }

  canMakeRequest(): boolean {
    const now = Date.now();
    const windowStart = now - this.config.windowMs;
    
    // Get or initialize request timestamps for this key
    const timestamps = Array.from(this.requests.values())
      .flat()
      .filter(time => time > windowStart);
    
    return timestamps.length < this.config.maxRequests;
  }

  async throttleRequest<T>(
    key: string,
    requestFn: () => Promise<T>,
    onRateLimit?: () => void
  ): Promise<T> {
    const now = Date.now();
    const windowStart = now - this.config.windowMs;
    
    // Get or initialize request timestamps for this key
    let timestamps = this.requests.get(key) || [];
    timestamps = timestamps.filter(time => time > windowStart);
    
    // Check rate limit
    if (timestamps.length >= this.config.maxRequests) {
      const oldestRequest = timestamps[0];
      const timeToWait = oldestRequest + this.config.windowMs - now;
      
      onRateLimit?.();
      
      await new Promise(resolve => setTimeout(resolve, timeToWait));
      return this.throttleRequest(key, requestFn, onRateLimit);
    }

    // Check concurrent limit if configured
    if (this.config.maxConcurrent) {
      const currentConcurrent = this.concurrentRequests.get(key) || 0;
      if (currentConcurrent >= this.config.maxConcurrent) {
        onRateLimit?.();
        await new Promise(resolve => setTimeout(resolve, 1000));
        return this.throttleRequest(key, requestFn, onRateLimit);
      }
      this.concurrentRequests.set(key, currentConcurrent + 1);
    }

    // Add current request timestamp
    timestamps.push(now);
    this.requests.set(key, timestamps);

    try {
      const result = await requestFn();
      return result;
    } catch (error: any) {
      if (error.response?.status === 429) {
        const retryAfter = error.response.headers['retry-after'];
        const waitTime = (retryAfter ? parseInt(retryAfter) * 1000 : this.config.retryAfter) || 1000;
        
        onRateLimit?.();
        
        await new Promise(resolve => setTimeout(resolve, waitTime));
        return this.throttleRequest(key, requestFn, onRateLimit);
      }
      throw error;
    } finally {
      // Decrease concurrent count
      if (this.config.maxConcurrent) {
        const currentConcurrent = this.concurrentRequests.get(key) || 1;
        this.concurrentRequests.set(key, currentConcurrent - 1);
      }
    }
  }

  clearRequests(key: string) {
    this.requests.delete(key);
    this.concurrentRequests.delete(key);
  }
}

// Create rate limiters for different endpoints
export const investmentsRateLimiter = new RateLimiter({
  maxRequests: 5,  // 5 requests
  windowMs: 10000, // per 10 seconds
  retryAfter: 2000 // retry after 2 seconds
});

export const propertyRateLimiter = new RateLimiter({
  maxRequests: 10, // 10 requests
  windowMs: 10000, // per 10 seconds
  retryAfter: 2000 // retry after 2 seconds
});

export const userRateLimiter = new RateLimiter({
  maxRequests: 5,  // 5 requests
  windowMs: 10000, // per 10 seconds
  retryAfter: 2000 // retry after 2 seconds
});

// Image-specific rate limiter with concurrent request limit
export const imageRateLimiter = new RateLimiter({
  maxRequests: 20,  // 20 requests
  windowMs: 10000,  // per 10 seconds
  retryAfter: 1000, // retry after 1 second
  maxConcurrent: 5  // max 5 concurrent requests
});