import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { AdminSidebar } from '../sidebar/AdminSidebar';
import { Overview } from '../dashboard/Overview';
import { UsersList } from '../users/UsersList';
import { UserDetails } from '../users/UserDetails';
import { PropertyManager } from '../property/PropertyManager';
import { VerificationRequests } from '../verification/VerificationRequests';
import { AdminSettings } from '../settings/AdminSettings';

export function AdminLayout() {
  return (
    <div className="min-h-screen bg-gray-50 flex">
      <AdminSidebar />
      <main className="flex-1 p-8">
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/users" element={<UsersList />} />
          <Route path="/users/:id" element={<UserDetails />} />
          <Route path="/properties/*" element={<PropertyManager />} />
          <Route path="/verifications" element={<VerificationRequests />} />
          <Route path="/settings" element={<AdminSettings />} />
        </Routes>
      </main>
    </div>
  );
}