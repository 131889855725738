import React from 'react';
import { useS3Image } from '@/hooks/useS3Image';
import { ImageIcon } from 'lucide-react';

// Omit the src prop from ImgHTMLAttributes since we'll define our own
type ImageProps = Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'src'>;

interface S3ImageProps extends ImageProps {
  src: string | undefined | null;
  alt: string;
  fallback?: React.ReactNode;
  className?: string;
  publicAccess?: boolean;
}

export function S3Image({ src, alt, fallback, className, publicAccess = false, ...props }: S3ImageProps) {
  const { signedUrl, isLoading, error } = useS3Image(src, publicAccess);

  // Show loading state
  if (isLoading) {
    return (
      <div className={`animate-pulse bg-gray-200 ${className}`}>
        <div className="w-full h-full flex items-center justify-center">
          <ImageIcon className="w-8 h-8 text-gray-400" />
        </div>
      </div>
    );
  }

  // Show error state or fallback
  if (error || !signedUrl) {
    return fallback || (
      <div className={`bg-gray-100 ${className}`}>
        <div className="w-full h-full flex items-center justify-center">
          <ImageIcon className="w-8 h-8 text-gray-400" />
        </div>
      </div>
    );
  }

  // Show image
  return (
    <img
      src={signedUrl}
      alt={alt}
      className={className}
      {...props}
    />
  );
}