import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, User, Mail, Calendar, Shield, DollarSign, Building2 } from 'lucide-react';
import { adminService } from '@/services/api/admin';
import { settingsService } from '@/services/api/settings';
import { S3Image } from '@/components/S3Image';
import { CryptoRewards } from './CryptoRewards';
import toast from 'react-hot-toast';
import type { Settings } from '@/types/settings';

export function UserDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [userSettings, setUserSettings] = useState<Settings | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!id) return;

      try {
        setIsLoading(true);
        const [userResponse, settingsResponse] = await Promise.all([
          adminService.getUser(id),
          settingsService.getUserSettings(id)
        ]);

        if (userResponse.success && userResponse.data) {
          // Merge user data with settings
          setUser({
            ...userResponse.data,
            paymentDetails: settingsResponse.success ? settingsResponse.data?.paymentDetails : undefined
          });

          // Only set settings if response is successful and data exists
          if (settingsResponse.success && settingsResponse.data) {
            setUserSettings(settingsResponse.data);
          }
        }
      } catch (error) {
        console.error('Failed to load user:', error);
        toast.error('Failed to load user details');
        navigate('/admin/users');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [id, navigate]);

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
        <div className="h-64 bg-gray-200 rounded-xl"></div>
      </div>
    );
  }

  if (!user) return null;

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate('/admin/users')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="w-5 h-5" />
          Back to Users
        </button>
      </div>

      <div className="bg-white rounded-xl border border-gray-200">
        <div className="p-6">
          <div className="flex items-start gap-6">
            {user.avatar ? (
              <S3Image
                src={user.avatar}
                alt={user.name}
                className="w-24 h-24 rounded-xl object-cover"
              />
            ) : (
              <div className="w-24 h-24 bg-gray-100 rounded-xl flex items-center justify-center">
                <User className="w-8 h-8 text-gray-400" />
              </div>
            )}
            
            <div className="flex-1">
              <div className="flex items-start justify-between">
                <div>
                  <h1 className="text-2xl font-bold">{user.name}</h1>
                  <div className="flex items-center gap-2 text-gray-600 mt-1">
                    <Mail className="w-4 h-4" />
                    {user.email}
                  </div>
                </div>
                <div className={`px-3 py-1 rounded-full text-sm font-medium ${
                  user.isVerified 
                    ? 'bg-green-100 text-green-800' 
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {user.isVerified ? 'Verified' : 'Unverified'}
                </div>
              </div>

              <div className="grid grid-cols-2 gap-6 mt-6">
                <div>
                  <div className="text-sm text-gray-600">Member Since</div>
                  <div className="flex items-center gap-2 mt-1">
                    <Calendar className="w-4 h-4 text-gray-400" />
                    {new Date(user.createdAt).toLocaleDateString()}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Role</div>
                  <div className="flex items-center gap-2 mt-1">
                    <Shield className="w-4 h-4 text-gray-400" />
                    <span className="capitalize">{user.role}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-xl border border-gray-200">
          <h2 className="text-lg font-semibold mb-4">Investment Summary</h2>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <DollarSign className="w-5 h-5 text-primary-600" />
                <span>Total Invested</span>
              </div>
              <span className="font-semibold">${user.totalValue?.toLocaleString()}</span>
            </div>
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <Building2 className="w-5 h-5 text-primary-600" />
                <span>Active Investments</span>
              </div>
              <span className="font-semibold">{user.totalInvestments}</span>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl border border-gray-200">
          <h2 className="text-lg font-semibold mb-4">Account Status</h2>
          <div className="space-y-4">
            <div>
              <div className="text-sm text-gray-600 mb-1">Email Verification</div>
              <div className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                user.isVerified 
                  ? 'bg-green-100 text-green-800'
                  : 'bg-yellow-100 text-yellow-800'
              }`}>
                {user.isVerified ? 'Verified' : 'Pending Verification'}
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-600 mb-1">Account Status</div>
              <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                Active
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Crypto Rewards Section */}
      <CryptoRewards user={user} />
    </div>
  );
}