import React, { useRef, useEffect } from 'react';

interface OTPInputProps {
  value: string[];
  onChange: (otp: string[]) => void;
  disabled?: boolean;
}

export function OTPInput({ value, onChange, disabled = false }: OTPInputProps) {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    // Focus first input on mount
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (index: number, inputValue: string) => {
    if (inputValue.length > 1) inputValue = inputValue[0];
    if (!/^\d*$/.test(inputValue)) return;

    const newOTP = [...value];
    newOTP[index] = inputValue;
    onChange(newOTP);

    // Move to next input if value entered
    if (inputValue && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !value[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text').slice(0, 6);
    if (!/^\d+$/.test(pastedData)) return;

    const newOTP = [...value];
    pastedData.split('').forEach((char, index) => {
      if (index < 6) newOTP[index] = char;
    });
    onChange(newOTP);
  };

  return (
    <div className="flex gap-2 justify-center">
      {value.map((digit, index) => (
        <input
          key={index}
          ref={el => inputRefs.current[index] = el}
          type="text"
          maxLength={1}
          value={digit}
          onChange={e => handleChange(index, e.target.value)}
          onKeyDown={e => handleKeyDown(index, e)}
          onPaste={handlePaste}
          disabled={disabled}
          className="w-12 h-12 text-center text-2xl font-semibold rounded-xl border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 disabled:bg-gray-100 disabled:text-gray-400"
        />
      ))}
    </div>
  );
}