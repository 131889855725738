import { ethers } from 'ethers';
import { NFT_CONTRACT_ADDRESSES } from '../config';
import type { SupportedChainId } from '../types';

export async function validateChainAndContract(chainId: number, signer: ethers.Signer) {
  // Validate chain ID
  if (!NFT_CONTRACT_ADDRESSES[chainId as SupportedChainId]) {
    throw new Error(`Chain ID ${chainId} not supported. Please switch to a supported network.`);
  }

  // Get contract address
  const contractAddress = NFT_CONTRACT_ADDRESSES[chainId as SupportedChainId];
  
  // Validate contract exists on chain
  const code = await signer.provider?.getCode(contractAddress);
  if (!code || code === '0x') {
    throw new Error(`No contract found at address ${contractAddress} on chain ${chainId}`);
  }

  return contractAddress;
}