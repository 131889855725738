import React, { useState } from 'react';
import { Star } from 'lucide-react';
import { feedbackService } from '@/services/api/feedback';
import toast from 'react-hot-toast';

export function PlatformRating() {
  const [rating, setRating] = useState<number | null>(null);
  const [review, setReview] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!rating) return;

    setIsSubmitting(true);
    try {
      await feedbackService.submitRating(rating, review);
      toast.success('Thank you for your rating!');
      setRating(null);
      setReview('');
    } catch (error) {
      toast.error('Failed to submit rating');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h3 className="text-lg font-medium mb-2">Rate Our Platform</h3>
        <p className="text-gray-600">
          Let us know how we're doing and help us improve our services
        </p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-4">
            How would you rate your experience?
          </label>
          <div className="flex gap-4 justify-center">
            {[1, 2, 3, 4, 5].map((value) => (
              <button
                key={value}
                type="button"
                onClick={() => setRating(value)}
                className={`p-3 rounded-lg transition-colors ${
                  rating === value
                    ? 'bg-primary-100 text-primary-600'
                    : 'bg-gray-100 text-gray-400 hover:bg-gray-200'
                }`}
              >
                <Star className="w-8 h-8" fill={rating && value <= rating ? 'currentColor' : 'none'} />
              </button>
            ))}
          </div>
          {rating && (
            <div className="text-center mt-2 text-sm text-primary-600 font-medium">
              {rating === 1 ? 'Poor' :
               rating === 2 ? 'Fair' :
               rating === 3 ? 'Good' :
               rating === 4 ? 'Very Good' :
               'Excellent'}
            </div>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Share your experience (Optional)
          </label>
          <textarea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Tell us what you like and what we can improve..."
            className="w-full h-32 px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          />
        </div>

        <button
          type="submit"
          disabled={isSubmitting || !rating}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Submitting...
            </>
          ) : (
            <>
              <Star className="w-4 h-4" />
              Submit Rating
            </>
          )}
        </button>
      </form>
    </div>
  );
}