import { adminApi } from '../client';
import type { ApiResponse } from '@/types/response';
import type { VerificationRequest } from '@/types/verification';

export const verificationService = {
  async getVerificationRequests(): Promise<ApiResponse<VerificationRequest[]>> {
    try {
      console.log('Fetching verification requests...');
      const { data } = await adminApi.get<ApiResponse<VerificationRequest[]>>('/verifications');
      
      console.log('Raw verification requests response:', data);

      if (!data.success || !Array.isArray(data.data)) {
        throw new Error(data.message || 'Invalid response format');
      }

      // Transform and validate the data
      const validatedRequests = data.data.map(request => {
        // Log individual request for debugging
        console.log('Raw request data:', request);

        // Ensure documents are properly structured
        const documents = request.documents || {};
        const idDocument = documents.idDocument || null;
        const proofOfAddress = documents.proofOfAddress || null;

        console.log('Extracted documents:', { idDocument, proofOfAddress });

        return {
          id: request.id,
          userId: request.userId,
          userName: request.userName,
          userEmail: request.userEmail,
          status: request.status,
          submittedAt: request.submittedAt,
          rejectionReason: request.rejectionReason,
          documents: {
            idDocument: idDocument ? {
              url: idDocument.url,
              type: idDocument.type || 'image/jpeg'
            } : undefined,
            proofOfAddress: proofOfAddress ? {
              url: proofOfAddress.url,
              type: proofOfAddress.type || 'image/jpeg'
            } : undefined
          }
        };
      });

      console.log('Transformed verification requests:', validatedRequests);

      return {
        success: true,
        data: validatedRequests
      };
    } catch (error) {
      console.error('Get verification requests error:', error);
      throw error;
    }
  },

  async updateVerificationStatus(
    userId: string, 
    status: 'approved' | 'rejected', 
    reason?: string
  ): Promise<ApiResponse<void>> {
    try {
      const { data } = await adminApi.put<ApiResponse<void>>(`/verifications/${userId}`, {
        status,
        reason
      });
      return data;
    } catch (error) {
      console.error('Update verification status error:', error);
      throw error;
    }
  }
};