import api from './axios';
import type { ApiResponse } from '@/types/response';
import type { VerificationStatus } from '@/types/verification';

export const verificationService = {
  async getStatus() {
    try {
      const { data } = await api.get<ApiResponse<VerificationStatus>>('/verification/status');
      return data;
    } catch (error) {
      console.error('Get verification status error:', error);
      throw error;
    }
  },

  async submitDocuments(formData: FormData) {
    try {
      const { data } = await api.post<ApiResponse<VerificationStatus>>(
        '/verification/submit',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return data;
    } catch (error) {
      console.error('Submit verification documents error:', error);
      throw error;
    }
  }
};