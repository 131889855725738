import React from 'react';
import { InvestmentCard } from './InvestmentCard';
import { Pagination } from './Pagination';
import type { Investment } from '@/types/investment';
import type { UserProfile } from '@/types/user';

interface InvestmentListProps {
  investments: Investment[];
  user: UserProfile | null; // Update to allow null
  page: number;
  onPageChange: (page: number) => void;
  itemsPerPage?: number;
}

export function InvestmentList({ 
  investments,
  user,
  page,
  onPageChange,
  itemsPerPage = 5
}: InvestmentListProps) {
  const totalPages = Math.ceil(investments.length / itemsPerPage);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentInvestments = investments.slice(startIndex, endIndex);

  if (!investments.length) {
    return (
      <div className="text-center py-8 text-gray-600">
        <p>No investments found matching your criteria</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {currentInvestments.map((investment) => (
          // Only render InvestmentCard if user exists
          user && <InvestmentCard 
            key={investment.id} 
            investment={investment}
            user={user}
          />
        ))}
      </div>

      {totalPages > 1 && (
        <Pagination
          currentPage={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </div>
  );
}