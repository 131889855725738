import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TrendingUp, MapPin, Calendar, Award } from 'lucide-react';
import { S3Image } from '@/components/S3Image';
import { CertificateViewer } from '@/components/investment/certificate';
import type { Investment } from '@/types/investment';
import type { UserProfile } from '@/types/user';

interface InvestmentCardProps {
  investment: Investment;
  user: UserProfile;
}

export function InvestmentCard({ investment, user }: InvestmentCardProps) {
  const [showCertificate, setShowCertificate] = useState(false);

  // Early validation
  if (!investment?.property) {
    console.warn('Invalid investment data:', investment);
    return null;
  }

  // Safely parse numeric values
  const amount = typeof investment.amount === 'string' 
    ? parseFloat(investment.amount) 
    : Number(investment.amount) || 0;

  const currentValue = typeof investment.currentValue === 'string'
    ? parseFloat(investment.currentValue)
    : Number(investment.currentValue) || amount;

  const returnToDate = typeof investment.returnToDate === 'string'
    ? parseFloat(investment.returnToDate)
    : Number(investment.returnToDate) || 0;

  // Format location string based on type
  const locationString = typeof investment.property.location === 'string'
    ? investment.property.location
    : `${investment.property.location.city}, ${investment.property.location.state}`;

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  return (
    <div className="bg-white hover:bg-gray-50 rounded-xl border border-gray-200 p-4 transition-all">
      <div className="flex items-center gap-4">
        <div className="w-24 h-24 rounded-lg overflow-hidden flex-shrink-0">
          <S3Image
            src={investment.property.images[0]}
            alt={investment.property.title}
            className="w-full h-full object-cover"
            fallback={
              <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                <span className="text-gray-400">No Image</span>
              </div>
            }
          />
        </div>
        
        <div className="flex-1 min-w-0">
          <div className="flex justify-between items-start">
            <div>
              <div className="flex items-center gap-2 mb-1">
                <h4 className="font-semibold text-lg truncate">{investment.property.title}</h4>
                <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-primary-100 text-primary-700">
                  {investment.property.type}
                </span>
              </div>
              <p className="text-gray-600 flex items-center gap-1 text-sm">
                <MapPin className="w-4 h-4" />
                <span className="truncate">{locationString}</span>
              </p>
            </div>
            <Link
              to={`/properties/${investment.propertyId}`}
              className="text-primary-600 hover:text-primary-700 text-sm font-medium"
            >
              View Details
            </Link>
          </div>

          {/* Certificate Button */}
          <div className="mt-4 pt-4 border-t border-gray-200">
            <button
              onClick={() => setShowCertificate(true)} 
              className="w-full flex items-center justify-center gap-2 px-4 py-2 text-primary-600 hover:bg-primary-50 rounded-lg transition-colors"
            >
              <Award className="w-5 h-5" />
              View Certificate
            </button>
          </div>

          <div className="grid grid-cols-4 gap-4 mt-4">
            <div>
              <div className="text-sm text-gray-600">Invested</div>
              <div className="font-semibold">{formatCurrency(amount)}</div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Current Value</div>
              <div className="font-semibold">{formatCurrency(currentValue)}</div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Return</div>
              <div className="flex items-center gap-1">
                <TrendingUp className={`w-4 h-4 ${returnToDate >= 0 ? 'text-green-500' : 'text-red-500'}`} />
                <span className={`font-semibold ${returnToDate >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                  {returnToDate >= 0 ? '+' : ''}{returnToDate.toFixed(1)}%
                </span>
              </div>
            </div>
            <div>
              <div className="text-sm text-gray-600">Investment Date</div>
              <div className="flex items-center gap-1 text-gray-600">
                <Calendar className="w-4 h-4" />
                <span>{new Date(investment.createdAt).toLocaleDateString()}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Certificate Modal */}
      {showCertificate && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50">
          <div className="bg-white rounded-xl p-6 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <CertificateViewer investment={investment} user={user} />
            <button
              onClick={() => setShowCertificate(false)}
              className="mt-4 w-full px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}