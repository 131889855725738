import api from '../axios';
import type { ApiResponse } from '@/types/response';
import { getSignedUrl } from '@/utils/s3';
import { getFileExtension, sanitizeFilename } from '@/utils/document';
import type { Document } from '@/types/document';

export const documentService = {
  async getDocuments(propertyId: string): Promise<ApiResponse<Document[]>> {
    try {
      const { data } = await api.get<ApiResponse<Document[]>>(`/properties/${propertyId}/documents`);
      
      if (!data.success || !data.data) {
        throw new Error(data.message || 'Failed to fetch documents');
      }

      // Get signed URLs for all documents
      const documentsWithUrls = await Promise.all(
        data.data.map(async (doc) => ({
          ...doc,
          url: await getSignedUrl(doc.url)
        }))
      );

      return {
        success: true,
        data: documentsWithUrls
      };
    } catch (error) {
      console.error('Get documents error:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Failed to fetch documents',
        data: []
      };
    }
  },

  async downloadDocument(propertyId: string, documentId: string): Promise<ApiResponse<{ url: string; filename: string }>> {
    try {
      const { data } = await api.get<ApiResponse<Document>>(`/properties/${propertyId}/documents/${documentId}/download`);
      
      if (!data.success || !data.data) {
        throw new Error(data.message || 'Document not found');
      }

      const document = data.data;
      const signedUrl = await getSignedUrl(document.url);
      const extension = getFileExtension(document.mimeType);
      const filename = sanitizeFilename(document.title + extension);

      return {
        success: true,
        data: {
          url: signedUrl,
          filename
        }
      };
    } catch (error) {
      console.error('Download document error:', error);
      throw error;
    }
  },

  async viewDocument(propertyId: string, documentId: string): Promise<ApiResponse<{ url: string }>> {
    try {
      const { data } = await api.get<ApiResponse<Document>>(`/properties/${propertyId}/documents/${documentId}/view`);
      
      if (!data.success || !data.data) {
        throw new Error(data.message || 'Document not found');
      }

      const signedUrl = await getSignedUrl(data.data.url);

      return {
        success: true,
        data: {
          url: signedUrl
        }
      };
    } catch (error) {
      console.error('View document error:', error);
      throw error;
    }
  },

  async uploadDocument(propertyId: string, formData: FormData): Promise<ApiResponse<Document>> {
    try {
      const { data } = await api.post<ApiResponse<Document>>(
        `/properties/${propertyId}/documents`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (!data.success || !data.data) {
        throw new Error(data.message || 'Failed to upload document');
      }

      // Get signed URL for immediate use
      const signedUrl = await getSignedUrl(data.data.url);

      return {
        success: true,
        data: {
          ...data.data,
          url: signedUrl
        }
      };
    } catch (error) {
      console.error('Upload document error:', error);
      throw error;
    }
  },

  async deleteDocument(propertyId: string, documentId: string): Promise<ApiResponse<void>> {
    try {
      const { data } = await api.delete<ApiResponse<void>>(`/properties/${propertyId}/documents/${documentId}`);
      return data;
    } catch (error) {
      console.error('Delete document error:', error);
      throw error;
    }
  }
};