import React from 'react';
import { Upload } from 'lucide-react';
import type { DocumentFormData } from '@/types/document';

interface DocumentUploadProps {
  onUpload: (document: DocumentFormData) => void;
}

const DOCUMENT_TYPES = [
  { value: 'prospectus', label: 'Property Prospectus' },
  { value: 'financial_report', label: 'Financial Report' },
  { value: 'legal', label: 'Legal Documents' },
  { value: 'contract', label: 'Contract' },
  { value: 'other', label: 'Other' }
] as const;

export function DocumentUpload({ onUpload }: DocumentUploadProps) {
  const [title, setTitle] = React.useState('');
  const [type, setType] = React.useState<DocumentFormData['type']>('prospectus');
  const [isPublic, setIsPublic] = React.useState(false);
  const [file, setFile] = React.useState<File | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;

    // Validate file
    const maxSize = 10 * 1024 * 1024; // 10MB
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    if (selectedFile.size > maxSize) {
      alert('File size must be less than 10MB');
      return;
    }

    if (!allowedTypes.includes(selectedFile.type)) {
      alert('Only PDF and Word documents are allowed');
      return;
    }

    setFile(selectedFile);
  };

  const handleSubmit = () => {
    if (!title || !type || !file) {
      alert('Please fill in all document details');
      return;
    }

    onUpload({
      title,
      type,
      file,
      isPublic
    });

    // Reset form
    setTitle('');
    setType('prospectus');
    setIsPublic(false);
    setFile(null);
    const fileInput = document.getElementById('document-file') as HTMLInputElement;
    if (fileInput) fileInput.value = '';
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Document Title
          </label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            placeholder="Enter document title"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Document Type
          </label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value as DocumentFormData['type'])}
            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          >
            {DOCUMENT_TYPES.map(type => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Upload Document
        </label>
        <input
          id="document-file"
          type="file"
          onChange={handleFileChange}
          accept=".pdf,.doc,.docx"
          className="block w-full text-sm text-gray-500
            file:mr-4 file:py-2 file:px-4
            file:rounded-lg file:border-0
            file:text-sm file:font-medium
            file:bg-primary-50 file:text-primary-700
            hover:file:bg-primary-100"
        />
      </div>

      <div>
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={isPublic}
            onChange={(e) => setIsPublic(e.target.checked)}
            className="rounded text-primary-600 focus:ring-primary-500"
          />
          <span className="text-sm text-gray-700">Make document publicly accessible</span>
        </label>
      </div>

      <button
        type="button"
        onClick={handleSubmit}
        disabled={!title || !type || !file}
        className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
      >
        <Upload className="w-4 h-4" />
        Add Document
      </button>
    </div>
  );
}