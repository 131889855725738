import React from 'react';
import { CheckCircle, Clock } from 'lucide-react';

interface VerificationStatusProps {
  status: 'pending' | 'verified';
}

export function VerificationStatus({ status }: VerificationStatusProps) {
  if (status === 'pending') {
    return (
      <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-6 text-center">
        <div className="w-12 h-12 bg-yellow-100 rounded-full flex items-center justify-center mx-auto mb-4">
          <Clock className="w-6 h-6 text-yellow-600" />
        </div>
        <h4 className="text-lg font-medium text-yellow-900 mb-2">
          Verification in Progress
        </h4>
        <p className="text-yellow-700">
          Your documents are being reviewed. This process typically takes 1-2 business days.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-green-50 border border-green-200 rounded-lg p-6 text-center">
      <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
        <CheckCircle className="w-6 h-6 text-green-600" />
      </div>
      <h4 className="text-lg font-medium text-green-900 mb-2">
        Identity Verified
      </h4>
      <p className="text-green-700">
        Your identity has been successfully verified. You now have full access to all platform features.
      </p>
    </div>
  );
}