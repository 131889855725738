import React from 'react';
import { DollarSign } from 'lucide-react';

interface InvestmentAmountProps {
  amount: number;
  minInvestment: number;
  maxInvestment: number;
  remainingAmount: number;
  onChange: (amount: number) => void;
  error?: string;
}

export function InvestmentAmount({
  amount,
  minInvestment,
  maxInvestment,
  remainingAmount,
  onChange,
  error
}: InvestmentAmountProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-1">
        Investment Amount
      </label>
      <div className="relative">
        <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
        <input
          type="number"
          min={minInvestment}
          max={Math.min(maxInvestment, remainingAmount)}
          step={100}
          value={amount}
          onChange={(e) => onChange(Number(e.target.value))}
          className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
            error ? 'border-red-300' : 'border-gray-200'
          } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
          placeholder="Enter investment amount"
        />
      </div>
      {error && (
        <p className="mt-1 text-sm text-red-600">{error}</p>
      )}
      <p className="mt-1 text-sm text-gray-500">
        Min: ${minInvestment.toLocaleString()} | 
        Max: ${Math.min(maxInvestment, remainingAmount).toLocaleString()}
      </p>
    </div>
  );
}