import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trophy, Star, Award, ArrowRight } from 'lucide-react';
import { rewardService } from '@/services/api/rewards';
import type { Reward } from '@/types/reward';
import toast from 'react-hot-toast';

export function RewardsSection() {
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRewards = async () => {
      try {
        const response = await rewardService.getRewards();
        setRewards(response.data);
      } catch (error) {
        toast.error('Failed to load rewards');
      } finally {
        setIsLoading(false);
      }
    };

    fetchRewards();
  }, []);

  const handleClaimReward = async (rewardId: string) => {
    try {
      await rewardService.claimReward(rewardId);
      toast.success('Reward claimed successfully!');
      // Refresh rewards after claiming
      const response = await rewardService.getRewards();
      setRewards(response.data);
    } catch (error) {
      toast.error('Failed to claim reward');
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded-xl border border-gray-200">
        <div className="animate-pulse space-y-4">
          <div className="h-6 bg-gray-200 rounded w-1/3"></div>
          <div className="space-y-3">
            {[1, 2, 3].map((i) => (
              <div key={i} className="h-20 bg-gray-200 rounded-lg"></div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold">Investor Rewards</h2>
        <Link 
          to="/rewards"
          className="text-primary-600 hover:text-primary-700 text-sm font-medium flex items-center gap-1"
        >
          View All Rewards
          <ArrowRight className="w-4 h-4" />
        </Link>
      </div>

      <div className="space-y-4">
        {rewards.map((reward) => (
          <div
            key={reward.id}
            className={`p-4 rounded-lg border ${
              reward.status === 'pending'
                ? 'border-primary-200 bg-primary-50/50'
                : 'border-gray-200'
            }`}
          >
            <div className="flex items-center gap-4">
              <div className={`p-2 rounded-lg ${
                reward.type === 'milestone' ? 'bg-purple-100 text-purple-600' :
                reward.type === 'badge' ? 'bg-amber-100 text-amber-600' :
                'bg-primary-100 text-primary-600'
              }`}>
                {reward.type === 'milestone' ? <Trophy className="w-5 h-5" /> :
                 reward.type === 'badge' ? <Star className="w-5 h-5" /> :
                 <Award className="w-5 h-5" />}
              </div>
              <div className="flex-1">
                <div className="flex items-center justify-between mb-1">
                  <h3 className="font-medium">{reward.title}</h3>
                  {reward.status === 'pending' && (
                    <button
                      onClick={() => handleClaimReward(reward.id)}
                      className="text-xs px-3 py-1 bg-primary-600 text-white rounded-full hover:bg-primary-700 transition-colors"
                    >
                      Claim
                    </button>
                  )}
                </div>
                <p className="text-sm text-gray-600">{reward.description}</p>
                {reward.value > 0 && (
                  <div className="mt-2 text-sm text-primary-600 font-medium">
                    Value: ${reward.value}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        {rewards.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No rewards available yet. Keep investing to earn rewards!
          </div>
        )}
      </div>
    </div>
  );
}