import React from 'react';
import { FileText } from 'lucide-react';
import { S3Image } from '@/components/S3Image';
import type { VerificationDocument } from '@/types/verification';

interface DocumentPreviewProps {
  document?: VerificationDocument;
  title: string;
  className?: string;
}

export function DocumentPreview({ document, title, className = '' }: DocumentPreviewProps) {
  console.log(`DocumentPreview for ${title}:`, document);

  if (!document?.url) {
    console.log(`No URL found for ${title}`);
    return (
      <div className={`flex items-center justify-center p-6 bg-gray-50 rounded-lg ${className}`}>
        <div className="text-center text-gray-500">
          <FileText className="w-10 h-10 mx-auto mb-3" />
          <p className="font-medium">No Document Available</p>
          <p className="text-sm text-gray-400 mt-1">Document has not been uploaded</p>
        </div>
      </div>
    );
  }

  const isPDF = document.type.toLowerCase().includes('pdf');
  console.log(`Document type for ${title}:`, document.type, 'isPDF:', isPDF);

  if (isPDF) {
    return (
      <div className={`border border-gray-200 rounded-lg overflow-hidden ${className}`}>
        <div className="flex flex-col items-center justify-center p-8 bg-gray-50">
          <FileText className="w-12 h-12 text-primary-600 mb-3" />
          <div className="text-center">
            <p className="font-medium text-gray-900 mb-2">PDF Document</p>
            <a 
              href={document.url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
            >
              View Document
            </a>
          </div>
        </div>
        <div className="p-4 bg-white border-t border-gray-200">
          <h4 className="font-medium text-gray-900">{title}</h4>
          <p className="text-sm text-gray-600 mt-1">PDF Document</p>
        </div>
      </div>
    );
  }

  return (
    <div className={`border border-gray-200 rounded-lg overflow-hidden ${className}`}>
      <div className="aspect-[4/3] relative">
        <S3Image
          src={document.url}
          alt={title}
          className="w-full h-full object-cover"
          fallback={
            <div className="flex items-center justify-center h-full bg-gray-50">
              <div className="text-center text-gray-500">
                <FileText className="w-10 h-10 mx-auto mb-2" />
                <p>Failed to load image</p>
              </div>
            </div>
          }
        />
      </div>
      <div className="p-4 bg-white border-t border-gray-200">
        <h4 className="font-medium text-gray-900">{title}</h4>
        <p className="text-sm text-gray-600 mt-1">
          Type: {document.type.split('/').pop()?.toUpperCase() || 'Image'}
        </p>
      </div>
    </div>
  );
}