import React, { useState } from 'react';
import { Phone, Mail, Award, Star, MessageSquare } from 'lucide-react';
import { ContactForm } from './agent/ContactForm';
import type { Agent } from '@/types/agent';

interface AgentProfileProps {
  agent: Agent;
}

export function AgentProfile({ agent }: AgentProfileProps) {
  const [showContactForm, setShowContactForm] = useState(false);

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <div className="flex items-start gap-6">
        <img
          src={agent.image}
          alt={agent.name}
          className="w-24 h-24 rounded-xl object-cover"
        />
        <div className="flex-1">
          <h4 className="text-lg font-semibold">{agent.name}</h4>
          <p className="text-gray-600 mb-4">{agent.title}</p>
          
          <div className="grid grid-cols-3 gap-4 mb-6">
            <div className="text-center p-3 bg-gray-50 rounded-lg">
              <div className="text-primary-600 mb-1">
                <Award className="w-5 h-5 mx-auto" />
              </div>
              <div className="text-sm text-gray-600">Experience</div>
              <div className="font-semibold">{agent.experience}+ years</div>
            </div>
            <div className="text-center p-3 bg-gray-50 rounded-lg">
              <div className="text-primary-600 mb-1">
                <Star className="w-5 h-5 mx-auto" />
              </div>
              <div className="text-sm text-gray-600">Rating</div>
              <div className="font-semibold">{agent.rating}/5.0</div>
            </div>
            <div className="text-center p-3 bg-gray-50 rounded-lg">
              <div className="text-primary-600 mb-1">
                <MessageSquare className="w-5 h-5 mx-auto" />
              </div>
              <div className="text-sm text-gray-600">Deals Closed</div>
              <div className="font-semibold">{agent.deals}</div>
            </div>
          </div>

          <p className="text-gray-600 mb-6">{agent.bio}</p>

          {showContactForm ? (
            <ContactForm agent={agent} onClose={() => setShowContactForm(false)} />
          ) : (
            <>
              <button
                onClick={() => setShowContactForm(true)}
                className="w-full bg-primary-600 text-white py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors"
              >
                Schedule a Call
              </button>

              <div className="mt-4 space-y-2">
                <a href={`tel:${agent.phone}`} className="flex items-center gap-2 text-gray-600 hover:text-primary-600">
                  <Phone className="w-4 h-4" />
                  {agent.phone}
                </a>
                <a href={`mailto:${agent.email}`} className="flex items-center gap-2 text-gray-600 hover:text-primary-600">
                  <Mail className="w-4 h-4" />
                  {agent.email}
                </a>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
