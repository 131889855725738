import axios from 'axios';
import axiosRetry from 'axios-retry';
import type { AxiosError, InternalAxiosRequestConfig } from 'axios';
import type { ErrorResponse } from '@/types/response';

const API_URL = import.meta.env.VITE_API_URL;
const API_PREFIX = import.meta.env.VITE_API_PREFIX;

if (!API_URL || !API_PREFIX) {
  throw new Error('API environment variables are not properly configured');
}

// Create base API instance
const api = axios.create({
  baseURL: `${API_URL}${API_PREFIX}`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Create admin API instance with /admin prefix
export const adminApi = axios.create({
  baseURL: `${API_URL}${API_PREFIX}/admin`, // Add /admin prefix
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

// Configure retry logic and interceptors for both instances
[api, adminApi].forEach(instance => {
  // Configure retry logic
  axiosRetry(instance, {
    retries: 3,
    retryDelay: (retryCount) => retryCount * 1000,
    retryCondition: (error) => {
      if (error.response?.status && error.response.status >= 400 && error.response.status < 500) {
        return false;
      }
      return axiosRetry.isNetworkOrIdempotentRequestError(error);
    }
  });

  // Request interceptor
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      // Don't set Content-Type for FormData
      if (config.data instanceof FormData) {
        delete config.headers['Content-Type'];
      }

      // Debug logging
      if (process.env.NODE_ENV === 'development') {
        console.log(`[${instance === adminApi ? 'ADMIN API' : 'API'}] Request:`, {
          method: config.method?.toUpperCase(),
          url: config.url,
          headers: {
            ...config.headers,
            Authorization: config.headers.Authorization ? '[HIDDEN]' : undefined
          },
          data: config.data instanceof FormData ? '[FormData]' : config.data
        });
      }

      return config;
    },
    (error) => {
      console.error(`[${instance === adminApi ? 'ADMIN API' : 'API'}] Request error:`, error);
      return Promise.reject(error);
    }
  );

  // Response interceptor
  instance.interceptors.response.use(
    (response) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(`[${instance === adminApi ? 'ADMIN API' : 'API'}] Response:`, {
          url: response.config.url,
          status: response.status,
          data: response.data
        });
      }
      return response;
    },
    (error: AxiosError<ErrorResponse>) => {
      if (process.env.NODE_ENV === 'development') {
        console.error(`[${instance === adminApi ? 'ADMIN API' : 'API'}] Response error:`, {
          url: error.config?.url,
          method: error.config?.method,
          status: error.response?.status,
          data: error.response?.data,
          message: error.message
        });
      }

      if (!error.response) {
        throw new Error('Network error. Please check your connection and try again.');
      }

      // Handle specific error cases
      switch (error.response.status) {
        case 401:
          if (!window.location.pathname.includes('/login')) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            window.location.href = '/login';
            throw new Error('Session expired. Please log in again.');
          }
          throw new Error('Invalid credentials');
        
        case 403:
          throw new Error('You do not have permission to perform this action.');
        
        case 404:
          throw new Error('Resource not found.');
        
        case 422:
          throw new Error('Invalid data provided.');
        
        default:
          throw new Error(
            error.response.data?.message || 
            error.response.data?.error?.message ||
            'An unexpected error occurred'
          );
      }
    }
  );
});

export default api;