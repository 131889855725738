import api from '../axios';
import { investmentsRateLimiter } from '@/utils/rate-limit';
import type { ApiResponse } from '@/types/response';
import type { Investment } from '@/types/investment';
import toast from 'react-hot-toast';

export const investmentValueService = {
  async updateInvestmentValue(investmentId: string): Promise<ApiResponse<Investment>> {
    return investmentsRateLimiter.throttleRequest(
      `update-value-${investmentId}`,
      async () => {
        try {
          const response = await api.post<ApiResponse<Investment>>(
            `/investments/${investmentId}/value/update`
          );
          return response.data;
        } catch (error) {
          console.error('Update investment value error:', error);
          throw error;
        }
      },
      () => toast.error('Too many value update requests, please wait...')
    );
  }
};