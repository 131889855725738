import { adminApi } from '../../client';
import type { ApiResponse } from '@/types/response';
import type { ValuationHistory } from '@/types/property/valuation';

interface UpdatePropertyValueParams {
  currentValue: number;
  appreciationReason: string;
  effectiveDate?: string;
  notifyInvestors?: boolean;
}

export const propertyAppreciationService = {
  async updatePropertyValue(propertyId: string, params: UpdatePropertyValueParams) {
    try {
      const { data } = await adminApi.put<ApiResponse<{
        property: {
          id: string;
          currentValue: number;
          appreciationPercentage: number;
          lastValuationDate: string;
        };
        investmentsUpdated: number;
      }>>(`/properties/${propertyId}/value`, params);
      return data;
    } catch (error) {
      console.error('Update property value error:', error);
      throw error;
    }
  },

  async getValuationHistory(propertyId: string) {
    try {
      const { data } = await adminApi.get<ApiResponse<ValuationHistory>>(`/properties/${propertyId}/valuations`);
      return data;
    } catch (error) {
      console.error('Get valuation history error:', error);
      throw error;
    }
  }
};