import React from 'react';
import { AlertCircle } from 'lucide-react';
import type { CryptoDetails } from './types';

interface CryptoWalletFormProps {
  cryptoDetails: CryptoDetails;
  onCryptoDetailsChange: (field: keyof CryptoDetails, value: string) => void;
}

export function CryptoWalletForm({
  cryptoDetails,
  onCryptoDetailsChange
}: CryptoWalletFormProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Wallet Address
        </label>
        <input
          type="text"
          value={cryptoDetails.walletAddress}
          onChange={(e) => onCryptoDetailsChange('walletAddress', e.target.value)}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Network
        </label>
        <select
          value={cryptoDetails.network}
          onChange={(e) => onCryptoDetailsChange('network', e.target.value)}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          required
        >
          <option value="ethereum">Ethereum</option>
          <option value="polygon">Polygon</option>
          <option value="binance">BNB Chain</option>
        </select>
      </div>

      <div className="flex items-start gap-2 p-4 bg-amber-50 text-amber-800 rounded-lg">
        <AlertCircle className="w-5 h-5 mt-0.5 flex-shrink-0" />
        <div className="text-sm">
          <p className="font-medium">Important</p>
          <p>Make sure to enter the correct wallet address. Transactions cannot be reversed once processed.</p>
        </div>
      </div>
    </div>
  );
}