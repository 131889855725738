import React from 'react';
import { ArrowRight, Users, TrendingUp } from 'lucide-react';

interface Property {
  targetAmount: number;
  raisedAmount: number;
  investors: number;
  expectedReturn: number;
  minInvestment: number;
}

interface SideInvestmentCardProps {
  property: Property;
  progress: number;
  onInvest: () => void;
}

export function SideInvestmentCard({ property, progress, onInvest }: SideInvestmentCardProps) {
  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200 mb-8">
      <h3 className="text-xl font-semibold mb-6">Investment Progress</h3>
      <div className="space-y-4">
        <div>
          <div className="flex justify-between text-sm mb-2">
            <span className="text-gray-600">Raised</span>
            <span className="font-semibold">${property.raisedAmount.toLocaleString()}</span>
          </div>
          <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
            <div 
              className="h-full bg-gradient-to-r from-primary-500 to-primary-600 rounded-full transition-all duration-500"
              style={{ width: `${progress}%` }}
            ></div>
          </div>
          <div className="flex justify-between text-sm mt-2">
            <span className="text-gray-600">Target</span>
            <span className="font-semibold">${property.targetAmount.toLocaleString()}</span>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 py-4 border-t border-gray-100">
          <div className="flex items-center gap-2">
            <Users className="w-5 h-5 text-primary-500" />
            <div>
              <div className="text-sm text-gray-600">Investors</div>
              <div className="font-semibold">{property.investors}</div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <TrendingUp className="w-5 h-5 text-primary-500" />
            <div>
              <div className="text-sm text-gray-600">Returns</div>
              <div className="font-semibold">{property.expectedReturn}%</div>
            </div>
          </div>
        </div>

        <button
          onClick={onInvest}
          className="w-full bg-primary-600 text-white py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors flex items-center justify-center gap-2"
        >
          Invest Now
          <ArrowRight className="w-5 h-5" />
        </button>

        <div className="text-center text-sm text-gray-600">
          Min. Investment: ${property.minInvestment.toLocaleString()}
        </div>
      </div>
    </div>
  );
}