import api from '../axios';
import type { VerifyOTPResponse } from '@/types/auth';

export const verificationService = {
  async verifyOTP(email: string, otp: string): Promise<VerifyOTPResponse> {
    try {
      console.log('Sending OTP verification request:', { email, otp });
      
      const response = await api.post<VerifyOTPResponse>('/auth/verify-otp', { 
        email,
        otp
      });

      if (!response.data.success || !response.data.token || !response.data.user) {
        throw new Error(response.data.message || 'Verification failed');
      }

      return {
        success: true,
        token: response.data.token,
        user: response.data.user
      };
    } catch (error: any) {
      console.error('OTP verification error:', error);
      throw new Error(error.response?.data?.message || 'Invalid verification code');
    }
  },

  async resendOTP(email: string) {
    try {
      const response = await api.post('/auth/resend-otp', { email });
      return response.data;
    } catch (error: any) {
      console.error('Resend OTP error:', error);
      throw new Error(error.response?.data?.message || 'Failed to resend verification code');
    }
  }
};