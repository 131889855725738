import React, { useState, useEffect } from 'react';
import { Wallet, LogOut, AlertTriangle, ChevronDown } from 'lucide-react';
import { useWallet } from '../hooks/useWallet';
import { useAuth } from '../hooks/useAuth';
import { userService } from '@/services/api/user';
import { supportedChains } from '@/config/web3';
import toast from 'react-hot-toast';

export function WalletConnect() {
  const { address, isConnected, connect, disconnect, isLoading, chain, switchNetwork } = useWallet();
  const { user, updateUser } = useAuth();
  const [showNetworkMenu, setShowNetworkMenu] = useState(false);

  // Update user's wallet address when connected
  useEffect(() => {
    const updateWalletAddress = async () => {
      if (isConnected && address && (!user?.walletAddress || user.walletAddress !== address)) {
        try {
          const response = await userService.updateProfile({ walletAddress: address });
          if (response.success && response.data) {
            updateUser({ walletAddress: address });
          }
        } catch (error) {
          console.error('Failed to update wallet address:', error);
        }
      }
    };

    updateWalletAddress();
  }, [isConnected, address, user?.walletAddress, updateUser]);

  const handleConnect = async () => {
    try {
      await connect();
    } catch (error) {
      if (error instanceof Error && error.name !== 'UserRejectedRequestError') {
        toast.error('Failed to connect wallet');
      }
    }
  };

  const handleDisconnect = async () => {
    try {
      await disconnect();
      // Clear wallet address from user profile
      if (user?.walletAddress) {
        try {
          await userService.updateProfile({ walletAddress: null });
          updateUser({ walletAddress: null });
        } catch (error) {
          console.error('Failed to clear wallet address:', error);
        }
      }
    } catch (error) {
      toast.error('Failed to disconnect wallet');
    }
  };

  const handleNetworkSwitch = async (chainId: number) => {
    try {
      await switchNetwork(chainId);
      setShowNetworkMenu(false);
    } catch (error) {
      toast.error('Failed to switch network');
    }
  };

  if (!isConnected) {
    return (
      <div className="ml-4">
        <button
          onClick={handleConnect}
          disabled={isLoading}
          className="px-4 py-2 bg-primary-600 text-white rounded-lg font-medium hover:bg-primary-700 transition-colors flex items-center gap-2 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? (
            <>
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Connecting...
            </>
          ) : (
            <>
              <Wallet className="w-4 h-4" />
              Connect Wallet
            </>
          )}
        </button>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2 ml-4">
      <div className="relative">
        <button
          onClick={() => setShowNetworkMenu(!showNetworkMenu)}
          className="px-4 py-2 bg-primary-50 text-primary-700 rounded-lg font-medium flex items-center gap-2"
        >
          {chain?.name || 'Unknown Network'}
          <ChevronDown className="w-4 h-4" />
        </button>

        {showNetworkMenu && (
          <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-50">
            {Object.values(supportedChains).map((supportedChain) => (
              <button
                key={supportedChain.id}
                onClick={() => handleNetworkSwitch(supportedChain.id)}
                className={`w-full px-4 py-2 text-left hover:bg-gray-50 ${
                  chain?.id === supportedChain.id ? 'text-primary-600 font-medium' : 'text-gray-700'
                }`}
              >
                {supportedChain.name}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className="px-4 py-2 bg-primary-50 text-primary-700 rounded-lg font-medium">
        {address?.slice(0, 6)}...{address?.slice(-4)}
      </div>

      <button
        onClick={handleDisconnect}
        className="p-2 text-gray-600 hover:text-primary-600 hover:bg-primary-50 rounded-lg transition-colors"
        title="Disconnect Wallet"
      >
        <LogOut className="w-4 h-4" />
      </button>
    </div>
  );
}