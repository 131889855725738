import React, { useState } from 'react';
import { DollarSign, Percent, Gift, Save } from 'lucide-react';
import type { InvestmentSettingsProps } from '@/types/admin/settings';
import toast from 'react-hot-toast';

export function InvestmentSettings({ settings, onUpdate, isLoading }: InvestmentSettingsProps) {
  const [formData, setFormData] = useState(settings?.investment || {
    minInvestmentAmount: 5000,
    maxInvestmentAmount: 1000000,
    platformFee: 2.5,
    referralReward: 100
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await onUpdate({ investment: formData });
      toast.success('Investment settings updated successfully');
    } catch (error) {
      toast.error('Failed to update investment settings');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Minimum Investment Amount
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="100"
              value={formData.minInvestmentAmount}
              onChange={(e) => setFormData(prev => ({ ...prev, minInvestmentAmount: Number(e.target.value) }))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Maximum Investment Amount
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="1000"
              value={formData.maxInvestmentAmount}
              onChange={(e) => setFormData(prev => ({ ...prev, maxInvestmentAmount: Number(e.target.value) }))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Platform Fee (%)
          </label>
          <div className="relative">
            <Percent className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              max="100"
              step="0.1"
              value={formData.platformFee}
              onChange={(e) => setFormData(prev => ({ ...prev, platformFee: Number(e.target.value) }))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Referral Reward Amount
          </label>
          <div className="relative">
            <Gift className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              min="0"
              step="10"
              value={formData.referralReward}
              onChange={(e) => setFormData(prev => ({ ...prev, referralReward: Number(e.target.value) }))}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              required
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isLoading}
          className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
        >
          {isLoading ? (
            <>
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Saving...
            </>
          ) : (
            <>
              <Save className="w-4 h-4" />
              Save Changes
            </>
          )}
        </button>
      </div>
    </form>
  );
}