import React, { useState } from 'react';
import { Wallet, Send, AlertCircle, Settings } from 'lucide-react';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { rewardsContract } from '@/services/rewards/contract'; // Add this import
import toast from 'react-hot-toast';
import type { User } from '@/types/user';

const POLYGON_WALLET = import.meta.env.POLYGON_REWARDS || '0xeAf792BD0D1C5c4884d6B3ED96AE1f7bDcbBfC3a';
const BSC_WALLET = import.meta.env.BSC_REWARDS || '0xDb8D29085f7A2373Edb6479F076baAE9D2e549F7';

interface CryptoRewardsProps {
  user: User;
}

export function CryptoRewards({ user }: CryptoRewardsProps) {
  const [amount, setAmount] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  // Get wallet details from user's payment preferences
  const cryptoDetails = user.paymentDetails?.crypto;
  const walletAddress = cryptoDetails?.walletAddress;
  const network = cryptoDetails?.network;

  const handleSendReward = async () => {
    if (!walletAddress || !network) {
      toast.error('User has not configured their crypto payment preferences');
      return;
    }
  
    if (!amount || parseFloat(amount) <= 0) {
      toast.error('Please enter a valid amount');
      return;
    }
  
    try {
      setIsProcessing(true);
  
      // Get network ID
      const networkId = network === 'polygon' ? 137 : 56;
  
      // Send reward using contract
      const txHash = await rewardsContract.sendReward(
        walletAddress,
        amount,
        networkId
      );
  
      toast.success('Reward sent successfully');
      setAmount('');
    } catch (error: any) {
      console.error('Send reward error:', error);
      toast.error(error.message || 'Failed to send reward');
    } finally {
      setIsProcessing(false);
    }
  };

  if (!user.paymentDetails?.crypto?.walletAddress) {
    return (
      <div className="bg-white p-6 rounded-xl border border-gray-200">
        <div className="flex items-center gap-3 mb-6">
          <div className="p-2 bg-primary-50 rounded-lg">
            <Wallet className="w-5 h-5 text-primary-600" />
          </div>
          <h3 className="font-medium">Send Crypto Reward</h3>
        </div>

        <div className="flex items-start gap-2 p-4 bg-amber-50 text-amber-800 rounded-lg">
          <AlertCircle className="w-5 h-5 mt-0.5 flex-shrink-0" />
          <div>
            <p className="font-medium">No Crypto Payment Method</p>
            <p className="text-sm mt-1">
              User needs to configure their crypto payment preferences first.
            </p>
            <Link 
              to={`/admin/users/${user.id}/payment-settings`}
              className="inline-flex items-center gap-1 mt-2 text-sm font-medium text-amber-900 hover:text-amber-800"
            >
              <Settings className="w-4 h-4" />
              View Payment Settings
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <div className="flex items-center gap-3 mb-6">
        <div className="p-2 bg-primary-50 rounded-lg">
          <Wallet className="w-5 h-5 text-primary-600" />
        </div>
        <h3 className="font-medium">Send Crypto Reward</h3>
      </div>

      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Network
          </label>
          <div className="px-4 py-2 bg-gray-50 rounded-lg text-gray-700">
            {network === 'polygon' ? 'Polygon (MATIC)' : 'BNB Smart Chain (BNB)'}
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Amount
          </label>
          <div className="relative">
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              min="0"
              step="0.000001"
              className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              placeholder={`Enter amount in ${network === 'polygon' ? 'MATIC' : 'BNB'}`}
            />
          </div>
        </div>

        <div className="flex items-start gap-2 p-4 bg-gray-50 rounded-lg text-sm text-gray-600">
          <AlertCircle className="w-5 h-5 mt-0.5 flex-shrink-0" />
          <div>
            <p>User's wallet address:</p>
            <p className="font-mono mt-1">{walletAddress}</p>
          </div>
        </div>

        <button
          onClick={handleSendReward}
          disabled={isProcessing || !amount}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
        >
          {isProcessing ? (
            <>
              <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Processing...
            </>
          ) : (
            <>
              <Send className="w-5 h-5" />
              Send Reward
            </>
          )}
        </button>
      </div>
    </div>
  );
}