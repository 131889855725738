import React from 'react';
import { Building2, Wallet } from 'lucide-react';

interface PaymentMethodSelectorProps {
  method: 'bank' | 'crypto';
  onChange: (method: 'bank' | 'crypto') => void;
}

export function PaymentMethodSelector({ method, onChange }: PaymentMethodSelectorProps) {
  return (
    <div className="grid grid-cols-2 gap-4">
      <button
        type="button"
        onClick={() => onChange('bank')}
        className={`p-4 rounded-lg border ${
          method === 'bank'
            ? 'border-primary-500 bg-primary-50'
            : 'border-gray-200 hover:border-primary-300'
        }`}
      >
        <Building2 className="w-6 h-6 text-primary-600 mb-2" />
        <div className="font-medium">Bank Transfer</div>
        <p className="text-sm text-gray-600 mt-1">
          Receive payments directly to your bank account
        </p>
      </button>

      <button
        type="button"
        onClick={() => onChange('crypto')}
        className={`p-4 rounded-lg border ${
          method === 'crypto'
            ? 'border-primary-500 bg-primary-50'
            : 'border-gray-200 hover:border-primary-300'
        }`}
      >
        <Wallet className="w-6 h-6 text-primary-600 mb-2" />
        <div className="font-medium">Crypto Wallet</div>
        <p className="text-sm text-gray-600 mt-1">
          Receive payments in cryptocurrency
        </p>
      </button>
    </div>
  );
}