import React from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Trophy, Star, Award, Gift, Clock } from 'lucide-react';
import { BadgesSection } from '../components/rewards/BadgesSection';
import { AchievementsSection } from '../components/rewards/AchievementsSection';
import { MilestonesSection } from '../components/rewards/MilestonesSection';

const sections = [
  {
    id: 'badges',
    title: 'Investor Badges',
    icon: Trophy,
    path: '/rewards/badges'
  },
  {
    id: 'achievements',
    title: 'Achievements',
    icon: Star,
    path: '/rewards/achievements'
  },
  {
    id: 'milestones',
    title: 'Investment Milestones',
    icon: Gift,
    path: '/rewards/milestones'
  }
];

export function Rewards() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = React.useState(() => {
    const path = location.pathname.split('/');
    return path[2] || 'badges';
  });

  React.useEffect(() => {
    if (location.pathname === '/rewards' || location.pathname === '/rewards/') {
      navigate('/rewards/badges', { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleSectionChange = (sectionId: string) => {
    setActiveSection(sectionId);
    const section = sections.find(s => s.id === sectionId);
    if (section) {
      navigate(section.path);
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Sidebar */}
        <div className="lg:w-80">
          <div className="bg-white rounded-xl border border-gray-200 overflow-hidden sticky top-24">
            <div className="p-6">
              <div className="flex items-center gap-3 mb-6">
                <Trophy className="w-6 h-6 text-primary-600" />
                <h2 className="text-xl font-semibold">Rewards</h2>
              </div>
              <nav className="space-y-1">
                {sections.map((section) => (
                  <button
                    key={section.id}
                    onClick={() => handleSectionChange(section.id)}
                    className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-colors ${
                      activeSection === section.id
                        ? 'bg-primary-50 text-primary-700'
                        : 'text-gray-700 hover:bg-gray-50'
                    }`}
                  >
                    <section.icon className="w-5 h-5" />
                    <span className="font-medium">{section.title}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1">
          <div className="bg-white rounded-xl border border-gray-200">
            <Routes>
              <Route path="/badges" element={<BadgesSection />} />
              <Route path="/achievements" element={<AchievementsSection />} />
              <Route path="/milestones" element={<MilestonesSection />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}