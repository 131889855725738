import React, { useState } from 'react';
import { X } from 'lucide-react';
import { InvestmentAmount } from './InvestmentAmount';
import { PaymentMethodSelector } from './PaymentMethodSelector';
import { NetworkSelector } from './NetworkSelector';
import { StripePaymentForm } from '../StripePaymentForm';
import { useWallet } from '@/hooks/useWallet';
import { useAuth } from '@/hooks/useAuth';
import { paymentService } from '@/services/api/payments';
import { investmentService } from '@/services/api/investments';
import { mintInvestmentNFT } from '@/services/nft';
import { stripeService } from '@/services/api/payments/stripe';
import { supportedChains } from '@/config/web3';
import toast from 'react-hot-toast';

interface InvestmentModalProps {
  isOpen: boolean;
  onClose: () => void;
  property: {
    id: string;
    title: string;
    minInvestment: number;
    maxInvestment: number;
    targetAmount: number;
    raisedAmount: number;
  };
  onInvest: (amount: number, method: 'stripe' | 'crypto') => void;
}

export function InvestmentModal({ isOpen, onClose, property, onInvest }: InvestmentModalProps) {
  const [amount, setAmount] = useState(property?.minInvestment || 0);
  const [error, setError] = useState('');
  const [paymentMethod, setPaymentMethod] = useState<'stripe' | 'crypto'>('stripe');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedChain, setSelectedChain] = useState(supportedChains.ethereum.id);
  const [isProcessing, setIsProcessing] = useState(false);
  const { isConnected, connect, isLoading: isWalletLoading, chain, switchNetwork } = useWallet();
  const [paymentIntent, setPaymentIntent] = useState<{ clientSecret: string; id: string } | null>(null);
  const { user } = useAuth();

  if (!isOpen || !property) return null;

  const remainingAmount = property.targetAmount - property.raisedAmount;

  const validateAmount = (value: number) => {
    if (value < property.minInvestment) {
      setError(`Minimum investment amount is $${property.minInvestment.toLocaleString()}`);
      return false;
    }
    
    if (value > property.maxInvestment) {
      setError(`Maximum investment amount is $${property.maxInvestment.toLocaleString()}`);
      return false;
    }

    if (value > remainingAmount) {
      setError(`Maximum available investment amount is $${remainingAmount.toLocaleString()}`);
      return false;
    }

    setError('');
    return true;
  };

  const handleAmountChange = (value: number) => {
    setAmount(value);
    validateAmount(value);
  };

  const handleInvest = async () => {
    if (!validateAmount(amount)) {
      return;
    } 

    try {
      if (paymentMethod === 'stripe') {
        const response = await stripeService.createPaymentIntent({
          amount,
          propertyId: property.id,
          method: 'stripe'
        });
  
        if (!response.success || !response.data) {
          throw new Error('Failed to create payment intent');
        }
  
        setPaymentIntent(response.data);
        setShowPaymentForm(true);
        return;
      }

      if (paymentMethod === 'crypto') {
        if (!isConnected) {
          await connect();
          return;
        }

        if (chain?.id !== selectedChain && switchNetwork) {
          await switchNetwork(selectedChain);
          return;
        }

        setIsProcessing(true);
        const response = await paymentService.processCryptoPayment(
          property.id,
          amount,
          selectedChain
        );

        if (!response.success) {
          throw new Error('Failed to process payment');
        }

        onInvest(amount, 'crypto');
        onClose();
        toast.success('Investment successful!');
      }
    } catch (error: any) {
      if (error.message.includes('rejected')) {
        toast.error('Transaction was rejected');
      } else {
        toast.error('Payment failed. Please try again.');
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      if (!paymentIntent || !user) {
        throw new Error('Payment intent or user not found');
      }
  
      // Confirm payment and get investment details
      const response = await stripeService.confirmPayment(paymentIntent.id);
      
      if (!response.success || !response.data?.investment) {
        throw new Error('Payment confirmation failed');
      }
  
      // Mint NFT certificate and store IPFS hash
      try {
        console.log('Minting NFT certificate...');
        const { tokenId, ipfsHash } = await mintInvestmentNFT(user, response.data.investment);
        
        // Update investment with NFT details if minting was successful
        if (tokenId !== '0') {
          await investmentService.updateInvestment(response.data.investment.id, {
            nftTokenId: tokenId,
            transactionHash: ipfsHash // Store IPFS hash
          });
        }
        
        console.log('NFT minted successfully:', { tokenId, ipfsHash });
      } catch (nftError) {
        console.error('Failed to mint NFT:', nftError);
        toast.error('Investment successful but failed to mint NFT certificate');
      }
  
      onInvest(amount, paymentMethod);
      onClose();
      toast.success('Investment successful!');
    } catch (error) {
      console.error('Failed to complete investment:', error);
      toast.error('Investment failed. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose}></div>
      <div className="relative bg-white rounded-2xl shadow-xl max-w-md w-full mx-4 animate-fade-in">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold">Invest in {property.title}</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>

          {!showPaymentForm ? (
            <div className="space-y-6">
              <InvestmentAmount
                amount={amount}
                minInvestment={property.minInvestment}
                maxInvestment={property.maxInvestment}
                remainingAmount={remainingAmount}
                onChange={handleAmountChange}
                error={error}
              />

              <PaymentMethodSelector
                method={paymentMethod}
                onChange={setPaymentMethod}
                disabled={isProcessing}
              />

              {paymentMethod === 'crypto' && (
                <NetworkSelector
                  selectedChain={selectedChain}
                  onChange={setSelectedChain}
                  disabled={isProcessing}
                />
              )}

              <button
                onClick={handleInvest}
                disabled={!!error || amount <= 0 || isWalletLoading || isProcessing}
                className="w-full bg-primary-600 text-white py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
              >
                {isWalletLoading || isProcessing ? (
                  <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                ) : (
                  'Continue to Payment'
                )}
              </button>
            </div>
          ) : (
            paymentIntent && (
              <StripePaymentForm
                amount={amount}
                onSuccess={handlePaymentSuccess}
                onCancel={() => setShowPaymentForm(false)}
                clientSecret={paymentIntent.clientSecret}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}