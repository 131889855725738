import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import type { Investment } from '@/types/investment';
import type { UserProfile } from '@/types/user';

export async function generateInvestmentCertificatePDF(
  certificateElement: HTMLElement,
  investment: Investment,
  user: UserProfile
): Promise<Blob> {
  try {
    // Capture the certificate element as canvas
    const canvas = await html2canvas(certificateElement, {
      scale: 2,
      useCORS: true,
      logging: false
    });

    // Create PDF
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [canvas.width, canvas.height]
    });

    // Add the canvas as image
    pdf.addImage(
      canvas.toDataURL('image/png'),
      'PNG',
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Generate filename
    const filename = `investment-certificate-${investment.id}.pdf`;

    return pdf.output('blob');
  } catch (error) {
    console.error('PDF generation error:', error);
    throw new Error('Failed to generate PDF');
  }
}
