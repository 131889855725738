interface CacheItem<T> {
  value: T;
  timestamp: number;
  expiresAt: number;
}

class Cache<T> {
  private cache: Map<string, CacheItem<T>> = new Map();
  private readonly maxSize: number;
  private readonly cleanupInterval: number;
  private cleanupTimer: NodeJS.Timeout | null = null;

  constructor(maxSize: number = 5000) {
    this.maxSize = maxSize;
    this.cleanupInterval = 5 * 60 * 1000; // 5 minutes
    this.startCleanupTimer();
  }

  set(key: string, value: T, ttlMinutes: number = 55): void {
    this.cleanup();

    // If cache is at capacity, remove 20% of oldest entries
    if (this.cache.size >= this.maxSize) {
      const entries = Array.from(this.cache.entries())
        .sort((a, b) => a[1].timestamp - b[1].timestamp)
        .slice(0, Math.floor(this.maxSize * 0.2));
      
      entries.forEach(([key]) => this.cache.delete(key));
    }

    this.cache.set(key, {
      value,
      timestamp: Date.now(),
      expiresAt: Date.now() + (ttlMinutes * 60 * 1000)
    });
  }

  get(key: string): T | null {
    const item = this.cache.get(key);
    if (!item) return null;

    const now = Date.now();
    if (now >= item.expiresAt) {
      this.cache.delete(key);
      return null;
    }

    // Update access timestamp
    item.timestamp = now;
    return item.value;
  }

  private cleanup(): void {
    const now = Date.now();
    let deletedCount = 0;

    for (const [key, item] of this.cache.entries()) {
      if (now >= item.expiresAt) {
        this.cache.delete(key);
        deletedCount++;
      }
    }

    if (deletedCount > 0) {
      console.debug(`Cache cleanup: removed ${deletedCount} expired items`);
    }
  }

  private startCleanupTimer(): void {
    if (this.cleanupTimer) {
      clearInterval(this.cleanupTimer);
    }

    this.cleanupTimer = setInterval(() => {
      this.cleanup();
    }, this.cleanupInterval);
  }

  clear(): void {
    this.cache.clear();
  }

  size(): number {
    return this.cache.size;
  }

  dispose(): void {
    if (this.cleanupTimer) {
      clearInterval(this.cleanupTimer);
    }
    this.cache.clear();
  }
}

export const urlCache = new Cache<string>(5000);