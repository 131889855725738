import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Edit, Trash2, AlertTriangle } from 'lucide-react';
import type { Property } from '@/types/property';

interface PropertyHeaderProps {
  property: Property;
  onDelete: () => void;
  isDeleting: boolean;
}

export function PropertyHeader({ property, onDelete, isDeleting }: PropertyHeaderProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <div className="flex justify-between items-start">
      <div>
        <h1 className="text-2xl font-bold mb-2">{property.title}</h1>
        <p className="text-gray-600">{property.location.address}</p>
      </div>
      <div className="flex items-center gap-4">
        <Link
          to={`/admin/properties/${property.id}/edit`}
          className="flex items-center gap-2 px-4 py-2 text-primary-600 bg-primary-50 rounded-lg hover:bg-primary-100"
        >
          <Edit className="w-5 h-5" />
          Edit
        </Link>
        <button
          onClick={() => setShowDeleteModal(true)}
          className="flex items-center gap-2 px-4 py-2 text-red-600 bg-red-50 rounded-lg hover:bg-red-100"
        >
          <Trash2 className="w-5 h-5" />
          Delete
        </button>
      </div>

      {/* Delete Confirmation Modal */}
      {showDeleteModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={() => setShowDeleteModal(false)}></div>
          <div className="relative bg-white rounded-xl shadow-xl max-w-md w-full mx-4 p-6">
            <div className="flex items-center gap-3 mb-4">
              <div className="p-3 bg-red-100 rounded-full">
                <AlertTriangle className="w-6 h-6 text-red-600" />
              </div>
              <h3 className="text-xl font-semibold">Delete Property</h3>
            </div>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this property? This action cannot be undone.
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onDelete();
                  setShowDeleteModal(false);
                }}
                disabled={isDeleting}
                className="flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
              >
                {isDeleting ? (
                  <>
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
                    Deleting...
                  </>
                ) : (
                  <>
                    <Trash2 className="w-4 h-4" />
                    Delete Property
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}