import { atom } from 'jotai';
import type { Property } from '../types';
import type { AuthState } from '@/types/auth';

// Initialize auth state from localStorage
const getInitialAuthState = (): AuthState => {
  const token = localStorage.getItem('token');
  const userStr = localStorage.getItem('user');
  const verificationEmail = localStorage.getItem('verificationEmail');

  let user = null;
  try {
    if (userStr) {
      user = JSON.parse(userStr);
    }
  } catch (error) {
    console.error('Failed to parse user data:', error);
    localStorage.removeItem('user');
  }

  // Ensure token and user are in sync
  if ((!token && user) || (token && !user)) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    return {
      user: null,
      isAuthenticated: false,
      token: null,
      verificationEmail: null,
      referralCode: null // Add this
    };
  }

  return {
    user,
    isAuthenticated: !!token && !!user,
    token,
    verificationEmail,
    referralCode: null // Add this
  };
};

export const authAtom = atom<AuthState>(getInitialAuthState());
export const watchlistAtom = atom<Property[]>([]);
export const loadingAtom = atom<boolean>(false);
export const walletModalAtom = atom<boolean>(false);