import React, { useState } from 'react';
import { Mail, Phone, Calendar, Clock } from 'lucide-react';
import { agentService } from '@/services/api/agents';
import type { Agent } from '@/types/agent';
import toast from 'react-hot-toast';

interface ContactFormProps {
  agent: Agent;
  onClose: () => void;
}

export function ContactForm({ agent, onClose }: ContactFormProps) {
  const [message, setMessage] = useState('');
  const [preferredTime, setPreferredTime] = useState('');
  const [contactMethod, setContactMethod] = useState<'email' | 'phone'>('email');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Get current date/time in ISO format for min attribute
  const now = new Date();
  now.setMinutes(now.getMinutes() + 30); // Add 30 minutes buffer
  const minDateTime = now.toISOString().slice(0, 16);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate selected time is not in the past
    const selectedTime = new Date(preferredTime);
    if (selectedTime < now) {
      toast.error('Please select a future date and time');
      return;
    }

    setIsSubmitting(true);
    
    try {
      await agentService.scheduleCall({
        agentId: agent.id,
        contactMethod,
        preferredTime,
        message
      });

      toast.success('Contact request sent successfully');
      onClose();
    } catch (error) {
      console.error('Failed to send contact request:', error);
      toast.error('Failed to send contact request');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 animate-fade-in">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Preferred Contact Method
        </label>
        <div className="grid grid-cols-2 gap-2">
          <button
            type="button"
            onClick={() => setContactMethod('email')}
            className={`p-2 rounded-lg border ${
              contactMethod === 'email'
                ? 'border-primary-500 bg-primary-50 text-primary-700'
                : 'border-gray-200'
            }`}
          >
            <Mail className="w-4 h-4 mx-auto mb-1" />
            <span className="text-sm">Email</span>
          </button>
          <button
            type="button"
            onClick={() => setContactMethod('phone')}
            className={`p-2 rounded-lg border ${
              contactMethod === 'phone'
                ? 'border-primary-500 bg-primary-50 text-primary-700'
                : 'border-gray-200'
            }`}
          >
            <Phone className="w-4 h-4 mx-auto mb-1" />
            <span className="text-sm">Phone</span>
          </button>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Preferred Time
        </label>
        <div className="relative">
          <Calendar className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
          <input
            type="datetime-local"
            value={preferredTime}
            onChange={(e) => setPreferredTime(e.target.value)}
            min={minDateTime}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            required
          />
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Message
        </label>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Tell us what you'd like to discuss..."
          className="w-full h-32 px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          required
        />
      </div>

      <div className="flex gap-4">
        <button
          type="submit"
          disabled={isSubmitting}
          className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
        >
          {isSubmitting ? (
            <>
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
              Scheduling...
            </>
          ) : (
            'Schedule Call'
          )}
        </button>
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 border border-gray-200 rounded-lg hover:bg-gray-50"
        >
          Cancel
        </button>
      </div>
    </form>
  );
}