import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { User, Shield, Gift, FileText, MessageSquare, Star, Wallet } from 'lucide-react';
import { CurrencySettings } from '../components/settings/CurrencySettings';
import { EmailPreferences } from '../components/settings/EmailPreferences';
import { IdentityVerification } from '../components/settings/IdentityVerification';
import { ReferralProgram } from '../components/settings/ReferralProgram';
import { ResourcesSection } from '../components/settings/ResourcesSection';
import { FeedbackSection } from '../components/settings/FeedbackSection';
import { PaymentPreferences } from '../components/settings/PaymentPreferences';
import { settingsService } from '@/services/api/settings';
import type { Settings } from '@/types/settings';
import toast from 'react-hot-toast';

export function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const [settings, setSettings] = useState<Settings | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchSettings = useCallback(async () => {
    try {
      const response = await settingsService.getSettings();
      if (response.success && response.data) {
        setSettings(response.data);
      } else {
        throw new Error(response.message || 'Failed to load settings');
      }
    } catch (error) {
      toast.error('Failed to load settings');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    if (location.pathname === '/settings' || location.pathname === '/settings/') {
      navigate('/settings/profile/currency', { replace: true });
    }
  }, [location.pathname, navigate]);

  const handleSettingsUpdate = useCallback((updatedSettings: Partial<Settings>) => {
    setSettings((prev): Settings => {
      if (!prev) return updatedSettings as Settings;
      return { ...prev, ...updatedSettings };
    });
  }, []);

  const sections = [
    {
      id: 'profile',
      title: 'Profile Settings',
      description: 'Manage your personal information and preferences',
      icon: User,
      items: [
        { label: 'Currency Settings', href: '/settings/profile/currency' },
        { label: 'Email Preferences', href: '/settings/profile/email' }
      ]
    },
    {
      id: 'payments',
      title: 'Payment Settings',
      description: 'Manage your dividend payment preferences',
      icon: Wallet,
      items: [
        { label: 'Payment Preferences', href: '/settings/payments' }
      ]
    },
    {
      id: 'security',
      title: 'Security',
      description: 'Protect your account and investments',
      icon: Shield,
      items: [
        { label: 'Identity Verification', href: '/settings/security/verify' },
        { label: 'Change Password', href: '/change-password' }
      ]
    },
    {
      id: 'rewards',
      title: 'Rewards & Referrals',
      description: 'Earn rewards and invite friends',
      icon: Gift,
      items: [
        { label: 'Referral Program', href: '/settings/referrals' }
      ]
    },
    {
      id: 'resources',
      title: 'Resources',
      description: 'Helpful guides and documentation',
      icon: FileText,
      items: [
        { label: 'Learning Resources', href: '/settings/resources' }
      ]
    },
    {
      id: 'feedback',
      title: 'Feedback',
      description: 'Help us improve',
      icon: MessageSquare,
      items: [
        { label: 'Submit Feedback', href: '/settings/feedback' }
      ]
    }
  ];

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="animate-pulse flex flex-col lg:flex-row gap-8">
          <div className="lg:w-80">
            <div className="h-[600px] bg-gray-200 rounded-xl"></div>
          </div>
          <div className="flex-1">
            <div className="h-[600px] bg-gray-200 rounded-xl"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Sidebar */}
        <div className="lg:w-80">
          <div className="bg-white rounded-xl border border-gray-200 overflow-hidden sticky top-24">
            <div className="p-6">
              <div className="flex items-center gap-3 mb-6">
                <Star className="w-6 h-6 text-primary-600" />
                <h2 className="text-xl font-semibold">Settings</h2>
              </div>
              <nav className="space-y-8">
                {sections.map((section) => (
                  <div key={section.id}>
                    <div className="mb-2">
                      <div className="flex items-center gap-2 text-gray-900">
                        <section.icon className="w-5 h-5 text-primary-600" />
                        <h3 className="font-medium">{section.title}</h3>
                      </div>
                      <p className="text-sm text-gray-600 mt-1">{section.description}</p>
                    </div>
                    <div className="space-y-1">
                      {section.items.map((item) => (
                        <button
                          key={item.href}
                          onClick={() => navigate(item.href)}
                          className={`w-full text-left px-4 py-2 rounded-lg text-sm transition-colors ${
                            location.pathname === item.href
                              ? 'bg-primary-50 text-primary-700 font-medium'
                              : 'text-gray-600 hover:bg-gray-50'
                          }`}
                        >
                          {item.label}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1">
          <div className="bg-white rounded-xl border border-gray-200 p-6">
            <Routes>
              <Route path="/" element={<Navigate to="/settings/profile/currency" replace />} />
              <Route 
                path="/profile/currency" 
                element={<CurrencySettings settings={settings} onUpdate={handleSettingsUpdate} />} 
              />
              <Route 
                path="/profile/email" 
                element={<EmailPreferences settings={settings} onUpdate={handleSettingsUpdate} />} 
              />
              <Route 
                path="/payments" 
                element={<PaymentPreferences settings={settings} onUpdate={handleSettingsUpdate} />} 
              />
              <Route path="/security/verify" element={<IdentityVerification />} />
              <Route path="/referrals" element={<ReferralProgram />} />
              <Route path="/resources" element={<ResourcesSection />} />
              <Route path="/feedback" element={<FeedbackSection />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}