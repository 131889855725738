import React from 'react';
import { ArrowRight, TrendingUp, Users, Clock } from 'lucide-react';
import type { Property } from '@/types/property';

interface StickyCTAProps {
  property: Pick<Property, 'targetAmount' | 'raisedAmount' | 'investors' | 'expectedReturn' | 'minInvestment' | 'daysLeft'>;
  progress: number;
  onInvest: () => void;
}

export function StickyCTA({ property, progress, onInvest }: StickyCTAProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg transform translate-y-0 transition-transform duration-300 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
        <div className="flex items-center justify-between">
          <div className="hidden md:flex items-center gap-8">
            <div>
              <div className="text-sm text-gray-600 mb-1">Progress</div>
              <div className="flex items-center gap-2">
                <div className="w-24 h-2 bg-gray-100 rounded-full overflow-hidden">
                  <div 
                    className="h-full bg-gradient-to-r from-primary-500 to-primary-600 rounded-full"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
                <span className="text-primary-600 font-semibold">{progress.toFixed(1)}%</span>
              </div>
            </div>
            <div className="flex items-center gap-6">
              <div>
                <div className="flex items-center gap-1 text-gray-600">
                  <Users className="w-4 h-4" />
                  <span className="text-sm">Investors</span>
                </div>
                <div className="font-semibold">{property.investors}</div>
              </div>
              <div>
                <div className="flex items-center gap-1 text-gray-600">
                  <TrendingUp className="w-4 h-4" />
                  <span className="text-sm">Returns</span>
                </div>
                <div className="font-semibold text-primary-600">{property.expectedReturn}%</div>
              </div>
              {property.daysLeft !== undefined && (
                <div>
                  <div className="flex items-center gap-1 text-gray-600">
                    <Clock className="w-4 h-4" />
                    <span className="text-sm">Time Left</span>
                  </div>
                  <div className="font-semibold">{property.daysLeft} days</div>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-right">
              <div className="text-sm text-gray-600">Min. Investment</div>
              <div className="font-semibold">${property.minInvestment.toLocaleString()}</div>
            </div>
            <button
              onClick={onInvest}
              className="flex items-center justify-center gap-2 px-6 py-3 bg-primary-600 text-white rounded-lg font-medium hover:bg-primary-700 transition-all shadow-lg shadow-primary-600/20 hover:shadow-xl hover:shadow-primary-600/30"
            >
              Invest Now
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}