import api from './axios';
import type { Investment } from '@/types/investment';
import type { ApiResponse } from '@/types/response';

export const investmentService = {
  async getInvestments() {
    try {
      const { data } = await api.get<ApiResponse<Investment[]>>('/investments');
      return {
        success: true,
        data: data.data || [] // Ensure we always return an array
      };
    } catch (error) {
      console.error('Get investments error:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Failed to load investments',
        data: [] // Return empty array on error
      };
    }
  },

  async createInvestment(propertyId: string, amount: number, paymentMethod: string) {
    try {
      const { data } = await api.post<ApiResponse<Investment>>('/investments', {
        propertyId,
        amount,
        paymentMethod
      });
      return data;
    } catch (error) {
      console.error('Create investment error:', error);
      throw error;
    }
  },

  async completeInvestment(
    investmentId: string,
    transactionHash?: string
  ): Promise<ApiResponse<Investment>> {
    try {
      console.log('Completing investment:', { investmentId, transactionHash });
      
      const { data } = await api.post<ApiResponse<Investment>>(
        `/investments/${investmentId}/complete`,
        { transactionHash }
      );
  
      if (!data.success) {
        throw new Error(data.message || 'Failed to complete investment');
      }
  
      return data;
    } catch (error) {
      console.error('Complete investment error:', error);
      throw error;
    }
  },

  async cancelInvestment(id: string) {
    try {
      const { data } = await api.post<ApiResponse<void>>(`/investments/${id}/cancel`);
      return data;
    } catch (error) {
      console.error('Cancel investment error:', error);
      throw error;
    }
  },

  async updateInvestment(
    id: string,
    updates: {
      nftTokenId?: string;
      transactionHash?: string;
    }
  ): Promise<ApiResponse<Investment>> {
    try {
      // Use PUT instead of PATCH for better compatibility
      const { data } = await api.put<ApiResponse<Investment>>(`/investments/${id}`, updates);
      return data;
    } catch (error) {
      console.error('Update investment error:', error);
      throw error;
    }
  }
};