import React, { useState } from 'react';
import { Plus, X, Building2 } from 'lucide-react';

interface PropertyFeaturesProps {
  formData: any;
  onChange: (data: any) => void;
  errors?: Record<string, string>;
}

export function PropertyFeatures({ formData, onChange, errors }: PropertyFeaturesProps) {
  const [newFeature, setNewFeature] = useState('');
  const [newAmenity, setNewAmenity] = useState('');

  const addFeature = () => {
    if (newFeature.trim()) {
      onChange({
        ...formData,
        features: [...(formData.features || []), newFeature.trim()]
      });
      setNewFeature('');
    }
  };

  const removeFeature = (index: number) => {
    const newFeatures = [...(formData.features || [])];
    newFeatures.splice(index, 1);
    onChange({ ...formData, features: newFeatures });
  };

  const addAmenity = () => {
    if (newAmenity.trim()) {
      onChange({
        ...formData,
        amenities: [...(formData.amenities || []), newAmenity.trim()]
      });
      setNewAmenity('');
    }
  };

  const removeAmenity = (index: number) => {
    const newAmenities = [...(formData.amenities || [])];
    newAmenities.splice(index, 1);
    onChange({ ...formData, amenities: newAmenities });
  };

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <Building2 className="w-5 h-5 text-primary-600" />
        Features & Amenities
      </h2>

      <div className="space-y-6">
        {/* Features */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Features
          </label>
          <div className="flex gap-2 mb-4">
            <input
              type="text"
              value={newFeature}
              onChange={(e) => setNewFeature(e.target.value)}
              placeholder="Add a feature..."
              className="flex-1 px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            <button
              type="button"
              onClick={addFeature}
              className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
            >
              <Plus className="w-5 h-5" />
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {formData.features?.map((feature: string, index: number) => (
              <div
                key={index}
                className="flex items-center gap-2 px-3 py-1 bg-primary-50 text-primary-700 rounded-full"
              >
                <span>{feature}</span>
                <button
                  type="button"
                  onClick={() => removeFeature(index)}
                  className="text-primary-600 hover:text-primary-700"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
          {errors?.features && (
            <p className="mt-1 text-sm text-red-600">{errors.features}</p>
          )}
        </div>

        {/* Amenities */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Amenities
          </label>
          <div className="flex gap-2 mb-4">
            <input
              type="text"
              value={newAmenity}
              onChange={(e) => setNewAmenity(e.target.value)}
              placeholder="Add an amenity..."
              className="flex-1 px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            <button
              type="button"
              onClick={addAmenity}
              className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
            >
              <Plus className="w-5 h-5" />
            </button>
          </div>
          <div className="flex flex-wrap gap-2">
            {formData.amenities?.map((amenity: string, index: number) => (
              <div
                key={index}
                className="flex items-center gap-2 px-3 py-1 bg-green-50 text-green-700 rounded-full"
              >
                <span>{amenity}</span>
                <button
                  type="button"
                  onClick={() => removeAmenity(index)}
                  className="text-green-600 hover:text-green-700"
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
            ))}
          </div>
          {errors?.amenities && (
            <p className="mt-1 text-sm text-red-600">{errors.amenities}</p>
          )}
        </div>
      </div>
    </div>
  );
}