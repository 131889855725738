// Network-specific contract addresses
export const REWARDS_CONTRACT_ADDRESSES: Record<number, string> = {
  137: import.meta.env.VITE_POLYGON_REWARDS_CONTRACT || '', // Polygon
  56: import.meta.env.VITE_BSC_REWARDS_CONTRACT || ''      // BSC
};

// Reward token addresses per network
export const REWARD_TOKENS: Record<number, string[]> = {
  137: [import.meta.env.VITE_POLYGON_REWARD_TOKEN || ''], // USDC on Polygon
  56: [import.meta.env.VITE_BSC_REWARD_TOKEN || '']      // BUSD on BSC
};

// Updated ABI to match the actual contract
export const REWARDS_ABI = [
  {
    "inputs": [
      {
        "internalType": "address[]",
        "name": "_tokens",
        "type": "address[]"
      },
      {
        "internalType": "uint256",
        "name": "_rewardAmount",
        "type": "uint256"
      }
    ],
    "name": "initialize",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_tokenAddress",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "_to",
        "type": "address"
      }
    ],
    "name": "sendReward",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_tokens",
        "type": "address"
      }
    ],
    "name": "setEnableRewardToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_rewardAmount",
        "type": "uint256"
      }
    ],
    "name": "setRewardAmount",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_tokenAddress",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_amount",
        "type": "uint256"
      },
      {
        "internalType": "address",
        "name": "_to",
        "type": "address"
      }
    ],
    "name": "sendRewardAmount",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "rewardAmount",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

// Admin wallet configuration
export const ADMIN_WALLET = {
  PRIVATE_KEY: import.meta.env.VITE_ADMIN_PRIVATE_KEY || '',
  RPC_URLS: {
    137: import.meta.env.VITE_POLYGON_RPC_URL || '',  // Polygon RPC
    56: import.meta.env.VITE_BSC_RPC_URL || ''       // BSC RPC
  }
};