import React from 'react';
import { Globe } from 'lucide-react';
import type { BankDetails } from './types';

interface BankTransferFormProps {
  bankDetails: BankDetails;
  selectedCountry: string;
  onBankDetailsChange: (field: keyof BankDetails, value: string) => void;
  onCountryChange: (country: string) => void;
}

export function BankTransferForm({
  bankDetails,
  selectedCountry,
  onBankDetailsChange,
  onCountryChange
}: BankTransferFormProps) {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Country
        </label>
        <div className="relative">
          <Globe className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
          <select
            value={selectedCountry}
            onChange={(e) => onCountryChange(e.target.value)}
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          >
            <option value="US">United States</option>
            <option value="GB">United Kingdom</option>
            <option value="EU">European Union</option>
            <option value="AU">Australia</option>
            <option value="OTHER">Other Countries</option>
          </select>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Account Holder Name
        </label>
        <input
          type="text"
          value={bankDetails.accountName}
          onChange={(e) => onBankDetailsChange('accountName', e.target.value)}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Account Number
        </label>
        <input
          type="text"
          value={bankDetails.accountNumber}
          onChange={(e) => onBankDetailsChange('accountNumber', e.target.value)}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Bank Name
        </label>
        <input
          type="text"
          value={bankDetails.bankName}
          onChange={(e) => onBankDetailsChange('bankName', e.target.value)}
          className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          required
        />
      </div>

      {/* Country-specific fields */}
      {selectedCountry === 'US' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Routing Number (ACH)
          </label>
          <input
            type="text"
            value={bankDetails.routingNumber || ''}
            onChange={(e) => onBankDetailsChange('routingNumber', e.target.value)}
            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            required
          />
        </div>
      )}

      {selectedCountry === 'GB' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Sort Code
          </label>
          <input
            type="text"
            value={bankDetails.sortCode || ''}
            onChange={(e) => onBankDetailsChange('sortCode', e.target.value)}
            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            required
          />
        </div>
      )}

      {selectedCountry === 'EU' && (
        <>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              IBAN
            </label>
            <input
              type="text"
              value={bankDetails.iban || ''}
              onChange={(e) => onBankDetailsChange('iban', e.target.value)}
              className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              BIC/SWIFT
            </label>
            <input
              type="text"
              value={bankDetails.bic || ''}
              onChange={(e) => onBankDetailsChange('bic', e.target.value)}
              className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              required
            />
          </div>
        </>
      )}

      {selectedCountry === 'AU' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            BSB Number
          </label>
          <input
            type="text"
            value={bankDetails.bsb || ''}
            onChange={(e) => onBankDetailsChange('bsb', e.target.value)}
            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            required
          />
        </div>
      )}

      {selectedCountry === 'OTHER' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            SWIFT/BIC Code
          </label>
          <input
            type="text"
            value={bankDetails.swiftCode || ''}
            onChange={(e) => onBankDetailsChange('swiftCode', e.target.value)}
            className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            required
          />
        </div>
      )}
    </div>
  );
}