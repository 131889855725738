import { nftContract } from './contract';
import { generateNFTMetadata } from './metadata';
import { uploadToIPFS } from './ipfs';
import type { UserProfile } from '@/types/user';
import type { Investment } from '@/types/investment';

export async function mintInvestmentNFT(
  user: UserProfile,
  investment: Investment
): Promise<{ tokenId: string; ipfsHash: string }> {
  try {
    console.log('Starting NFT minting process:', { user, investment });

    // Generate metadata
    const metadata = generateNFTMetadata(user, investment);
    console.log('Generated NFT metadata:', metadata);
    
    // Upload metadata to IPFS
    const tokenURI = await uploadToIPFS(metadata);
    const ipfsHash = tokenURI.replace('ipfs://', '');
    console.log('Uploaded to IPFS:', tokenURI);

    // Check if user has a wallet
    if (!window.ethereum || !user.walletAddress) {
      console.log('No crypto wallet available - skipping blockchain mint');
      return { tokenId: '0', ipfsHash };
    }

    // Get wallet address
    const walletAddress = user.walletAddress || await window.ethereum.request({ 
      method: 'eth_requestAccounts' 
    }).then((accounts: string[]) => accounts[0]);

    if (!walletAddress) {
      console.log('No wallet address available - skipping blockchain mint');
      return { tokenId: '0', ipfsHash };
    }

    console.log('Minting NFT for wallet:', walletAddress);

    // Mint NFT
    const tokenId = await nftContract.safeMint(walletAddress, tokenURI);
    console.log('NFT minted with token ID:', tokenId);

    return { tokenId, ipfsHash };
  } catch (error) {
    console.error('NFT minting error:', error);
    return { tokenId: '0', ipfsHash: '' };
  }
}