import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { getSignedUrl } from '@/utils/s3';

export function useS3Image(url: string | undefined | null, publicAccess = false) {
  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const fetchSignedUrl = async () => {
      if (!url) {
        setSignedUrl(null);
        setIsLoading(false);
        return;
      }

      // If not an S3 URL, use original URL
      if (!url.includes('amazonaws.com')) {
        setSignedUrl(url);
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const signed = await getSignedUrl(url, publicAccess);
        
        if (isMounted) {
          setSignedUrl(signed);
          setError(null);
        }
      } catch (err) {
        console.error('Failed to get signed URL:', err);
        if (isMounted) {
          setError(err instanceof Error ? err : new Error('Failed to load image'));
          setSignedUrl(url); // Fallback to original URL
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchSignedUrl();

    return () => {
      isMounted = false;
    };
  }, [url, isAuthenticated, publicAccess]);

  return { signedUrl, isLoading, error };
}