import { ethers } from 'ethers';
import { validateChainAndContract } from './validation';
import { NFT_CONTRACT_ABI } from '../config';
import type { SupportedChainId } from '../types';

export async function initializeContract() {
  if (!window.ethereum) {
    throw new Error('No crypto wallet found. Please install MetaMask.');
  }

  // Request account access
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  
  // Initialize provider and signer
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  // Get and validate network
  const network = await provider.getNetwork();
  const chainId = network.chainId as SupportedChainId;

  // Validate chain and get contract address
  const contractAddress = await validateChainAndContract(chainId, signer);

  // Create contract instance
  const contract = new ethers.Contract(
    contractAddress,
    NFT_CONTRACT_ABI,
    signer
  );

  return { contract, signer, chainId };
}