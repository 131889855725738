import React, { useEffect, useState } from 'react';
import { Trophy, Target, TrendingUp, Users, DollarSign } from 'lucide-react';
import { achievementService } from '@/services/api/achievements';
import toast from 'react-hot-toast';
import type { Achievement } from '@/types/achievement';

const achievementIcons = {
  first_investment: Trophy,
  diversification_master: Target,
  high_performer: TrendingUp,
  referral_champion: Users
} as const;

export function AchievementsSection() {
  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAchievements = async () => {
      try {
        const response = await achievementService.getAchievements();
        if (!response.success || !response.data) {
          throw new Error(response.message || 'Failed to load achievements');
        }
        setAchievements(response.data);
      } catch (error) {
        console.error('Failed to load achievements:', error);
        toast.error('Failed to load achievements');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAchievements();
  }, []);

  const formatTitle = (type: string): string => {
    return type
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (isLoading) {
    return (
      <div className="p-6">
        <div className="animate-pulse space-y-8">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="h-48 bg-gray-200 rounded-xl"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Achievements</h3>
        <p className="text-gray-600">
          Complete achievements to earn rewards and unlock special perks.
        </p>
      </div>

      <div className="grid gap-4">
        {achievements.map((achievement) => {
          const Icon = achievementIcons[achievement.type] || Trophy;
          
          return (
            <div
              key={achievement.id}
              className={`p-6 rounded-xl border ${
                achievement.isUnlocked
                  ? 'border-primary-200 bg-primary-50/50'
                  : 'border-gray-200'
              }`}
            >
              <div className="flex items-start gap-4">
                <div className={`p-3 rounded-xl ${
                  achievement.isUnlocked
                    ? 'bg-primary-100 text-primary-600'
                    : 'bg-gray-100 text-gray-600'
                }`}>
                  <Icon className="w-6 h-6" />
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-2">
                    <h4 className="font-semibold">{formatTitle(achievement.type)}</h4>
                    {achievement.isUnlocked ? (
                      <span className="px-3 py-1 bg-primary-100 text-primary-700 rounded-full text-sm font-medium">
                        Completed
                      </span>
                    ) : (
                      <span className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm font-medium">
                        {achievement.progress}% Complete
                      </span>
                    )}
                  </div>
                  <p className="text-gray-600 text-sm mb-4">{achievement.description}</p>
                  
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center gap-2 text-primary-600">
                      <DollarSign className="w-4 h-4" />
                      <span>{achievement.reward}</span>
                    </div>
                    {!achievement.isUnlocked && achievement.current !== undefined && (
                      <span className="text-gray-600">
                        {achievement.current}/{achievement.target}
                      </span>
                    )}
                  </div>

                  {!achievement.isUnlocked && (
                    <div className="mt-3">
                      <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-primary-600 rounded-full transition-all duration-500"
                          style={{ width: `${Math.min(Math.max(achievement.progress, 0), 100)}%` }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}