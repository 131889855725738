import React from 'react';
import type { Property } from '@/types/property';

interface PropertyStatsProps {
  property: Property;
}

export function PropertyStats({ property }: PropertyStatsProps) {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
      <div>
        <div className="text-sm text-gray-600 mb-1">Target Raise</div>
        <div className="font-semibold">${property.targetAmount.toLocaleString()}</div>
      </div>
      <div>
        <div className="text-sm text-gray-600 mb-1">Investors</div>
        <div className="font-semibold">{property.investors}</div>
      </div>
      <div>
        <div className="text-sm text-gray-600 mb-1">Expected Return</div>
        <div className="font-semibold text-primary-600">{property.expectedReturn}%</div>
      </div>
      <div>
        <div className="text-sm text-gray-600 mb-1">Min Investment</div>
        <div className="font-semibold">${property.minInvestment.toLocaleString()}</div>
      </div>
    </div>
  );
}