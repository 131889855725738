import React, { useEffect, useState } from 'react';
import { Users, Building2, TrendingUp, DollarSign } from 'lucide-react';
import { adminService } from '@/services/api/admin';
import toast from 'react-hot-toast';

interface Stats {
  totalUsers: number;
  totalProperties: number;
  totalInvestments: number;
  totalValue: number;
  recentSignups: Array<{
    id: string;
    name: string;
    email: string;
    date: string;
  }>;
  recentInvestments: Array<{
    id: string;
    userId: string;
    userName: string;
    propertyTitle: string;
    amount: number;
    date: string;
  }>;
}

export function Overview() {
  const [stats, setStats] = useState<Stats | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await adminService.getStats();
        setStats(response.data || null);
      } catch (error) {
        toast.error('Failed to load statistics');
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="grid grid-cols-4 gap-6">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="h-32 bg-gray-200 rounded-xl"></div>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-6">
          {[1, 2].map((i) => (
            <div key={i} className="h-96 bg-gray-200 rounded-xl"></div>
          ))}
        </div>
      </div>
    );
  }

  if (!stats) return null;

  return (
    <div className="space-y-8">
      <h1 className="text-2xl font-bold">Dashboard Overview</h1>

      <div className="grid grid-cols-4 gap-6">
        <div className="bg-white p-6 rounded-xl border border-gray-200">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-primary-50 rounded-xl">
              <Users className="w-6 h-6 text-primary-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Users</div>
              <div className="text-2xl font-bold">{stats.totalUsers}</div>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl border border-gray-200">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-green-50 rounded-xl">
              <Building2 className="w-6 h-6 text-green-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Properties</div>
              <div className="text-2xl font-bold">{stats.totalProperties}</div>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl border border-gray-200">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-amber-50 rounded-xl">
              <TrendingUp className="w-6 h-6 text-amber-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Investments</div>
              <div className="text-2xl font-bold">{stats.totalInvestments}</div>
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-xl border border-gray-200">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-purple-50 rounded-xl">
              <DollarSign className="w-6 h-6 text-purple-600" />
            </div>
            <div>
              <div className="text-sm text-gray-600">Total Value</div>
              <div className="text-2xl font-bold">
                ${stats.totalValue.toLocaleString()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-6">
        {/* Recent Signups */}
        <div className="bg-white rounded-xl border border-gray-200">
          <div className="p-6 border-b border-gray-200">
            <h2 className="font-semibold">Recent Signups</h2>
          </div>
          <div className="divide-y divide-gray-200">
            {stats.recentSignups.map((user) => (
              <div key={user.id} className="p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="font-medium">{user.name}</div>
                    <div className="text-sm text-gray-600">{user.email}</div>
                  </div>
                  <div className="text-sm text-gray-500">
                    {new Date(user.date).toLocaleDateString()}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Recent Investments */}
        <div className="bg-white rounded-xl border border-gray-200">
          <div className="p-6 border-b border-gray-200">
            <h2 className="font-semibold">Recent Investments</h2>
          </div>
          <div className="divide-y divide-gray-200">
            {stats.recentInvestments.map((investment) => (
              <div key={investment.id} className="p-4">
                <div className="flex items-center justify-between">
                  <div>
                    <div className="font-medium">{investment.propertyTitle}</div>
                    <div className="text-sm text-gray-600">by {investment.userName}</div>
                  </div>
                  <div className="text-right">
                    <div className="font-medium text-primary-600">
                      ${investment.amount.toLocaleString()}
                    </div>
                    <div className="text-sm text-gray-500">
                      {new Date(investment.date).toLocaleDateString()}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}