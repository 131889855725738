import React from 'react';
import { MapPin } from 'lucide-react';

interface PropertyLocationProps {
  formData: any;
  onChange: (data: any) => void;
  errors?: Record<string, string>;
}

export function PropertyLocation({ formData, onChange, errors }: PropertyLocationProps) {
  const handleLocationChange = (field: string, value: string) => {
    onChange({
      ...formData,
      location: {
        ...formData.location,
        [field]: value
      }
    });
  };

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <MapPin className="w-5 h-5 text-primary-600" />
        Location Details
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Street Address*
          </label>
          <input
            type="text"
            value={formData.location?.address}
            onChange={(e) => handleLocationChange('address', e.target.value)}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.['location.address'] ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            placeholder="Enter street address"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            City*
          </label>
          <input
            type="text"
            value={formData.location?.city}
            onChange={(e) => handleLocationChange('city', e.target.value)}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.['location.city'] ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            placeholder="Enter city"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            State/Province*
          </label>
          <input
            type="text"
            value={formData.location?.state}
            onChange={(e) => handleLocationChange('state', e.target.value)}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.['location.state'] ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            placeholder="Enter state/province"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            ZIP/Postal Code*
          </label>
          <input
            type="text"
            value={formData.location?.zipCode}
            onChange={(e) => handleLocationChange('zipCode', e.target.value)}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.['location.zipCode'] ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            placeholder="Enter ZIP/postal code"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Country*
          </label>
          <input
            type="text"
            value={formData.location?.country}
            onChange={(e) => handleLocationChange('country', e.target.value)}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.['location.country'] ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            placeholder="Enter country"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Coordinates
          </label>
          <div className="grid grid-cols-2 gap-2">
            <input
              type="number"
              value={formData.location?.coordinates?.latitude}
              onChange={(e) => onChange({
                ...formData,
                location: {
                  ...formData.location,
                  coordinates: {
                    ...formData.location?.coordinates,
                    latitude: parseFloat(e.target.value)
                  }
                }
              })}
              className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Latitude"
              step="any"
            />
            <input
              type="number"
              value={formData.location?.coordinates?.longitude}
              onChange={(e) => onChange({
                ...formData,
                location: {
                  ...formData.location,
                  coordinates: {
                    ...formData.location?.coordinates,
                    longitude: parseFloat(e.target.value)
                  }
                }
              })}
              className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Longitude"
              step="any"
            />
          </div>
        </div>
      </div>
    </div>
  );
}