import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Building2, MapPin, Heart } from 'lucide-react';
import { PropertyImageCarousel } from '../PropertyImageCarousel';
import { PropertyProgress } from './PropertyProgress';
import { PropertyStats } from './PropertyStats';
import { InvestmentModal } from '@/components/investment';
import { useWatchlist } from '@/hooks/useWatchlist';
import { propertyService } from '@/services/api/properties';
import { useAuth } from '@/hooks/useAuth';
import toast from 'react-hot-toast';
import type { Property } from '@/types/property';

interface PropertyCardProps {
  property: Property;
  onInvestmentComplete?: () => void;
}

export function PropertyCard({ property, onInvestmentComplete }: PropertyCardProps) {
  const { isWatchlisted, toggleWatchlist } = useWatchlist();
  const { isAuthenticated } = useAuth();
  const [showInvestModal, setShowInvestModal] = useState(false);
  const isInWatchlist = isWatchlisted(property.id);

  const handleWatchlistClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (!isAuthenticated) {
      toast.error('Please log in to save properties');
      return;
    }

    try {
      if (isInWatchlist) {
        await propertyService.removeFromWatchlist(property.id);
      } else {
        await propertyService.saveProperty(property.id);
      }
      toggleWatchlist(property);
      toast.success(isInWatchlist ? 'Removed from watchlist' : 'Added to watchlist');
    } catch (error) {
      toast.error('Failed to update watchlist');
    }
  };

  const handleInvestClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!isAuthenticated) {
      toast.error('Please log in to invest');
      return;
    }
    setShowInvestModal(true);
  };

  const handleInvest = async (amount: number, method: 'stripe' | 'crypto') => {
    try {
      await propertyService.submitInvestment(property.id, amount, method);
      toast.success('Investment submitted successfully');
      setShowInvestModal(false);
      if (onInvestmentComplete) {
        onInvestmentComplete();
      }
    } catch (error) {
      toast.error('Failed to submit investment');
    }
  };

  return (
    <>
      <div className="bg-white rounded-xl border border-gray-200 hover:border-primary-300 transition-all">
        <div className="relative">
          <PropertyImageCarousel
            images={property.images}
            title={property.title}
            className="aspect-video"
            publicAccess={true}
            linkTo={`/properties/${property.id}`}
          />
          <button
            onClick={handleWatchlistClick}
            className="absolute top-4 right-4 p-2 bg-white/90 backdrop-blur-sm rounded-lg hover:bg-white transition-colors"
          >
            <Heart 
              className={`w-5 h-5 ${
                isInWatchlist ? 'fill-primary-600 text-primary-600' : 'text-gray-600'
              }`} 
            />
          </button>
        </div>

        <div className="p-6">
          <Link to={`/properties/${property.id}`} className="block group">
            <div className="flex items-start justify-between gap-4 mb-4">
              <div>
                <h3 className="font-semibold text-lg group-hover:text-primary-600 transition-colors">
                  {property.title}
                </h3>
                <div className="flex items-center gap-4 text-gray-600 mt-1">
                  <div className="flex items-center gap-1">
                    <MapPin className="w-4 h-4" />
                    <span className="text-sm">{property.location.address}</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Building2 className="w-4 h-4" />
                    <span className="text-sm capitalize">{property.type.replace('_', ' ')}</span>
                  </div>
                </div>
              </div>
            </div>

            <PropertyProgress
              raisedAmount={property.raisedAmount}
              targetAmount={property.targetAmount}
            />

            <div className="mt-6">
              <PropertyStats
                minInvestment={property.minInvestment}
                investors={property.investors}
                expectedReturn={property.expectedReturn}
              />
            </div>

            <button
              onClick={handleInvestClick}
              className="w-full mt-6 bg-primary-600 text-white py-3 rounded-lg font-medium hover:bg-primary-700 transition-colors"
            >
              Invest Now
            </button>
          </Link>
        </div>
      </div>

      {showInvestModal && (
        <InvestmentModal
          isOpen={showInvestModal}
          onClose={() => setShowInvestModal(false)}
          property={property}
          onInvest={handleInvest}
        />
      )}
    </>
  );
}