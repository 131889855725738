import React from 'react';
import { FileText, X } from 'lucide-react';
import type { PropertyDocument, DocumentFormData } from '@/types/document';

interface DocumentListProps {
  documents: Array<PropertyDocument | DocumentFormData>;
  onRemove: (index: number) => void;
}

export function DocumentList({ documents, onRemove }: DocumentListProps) {
  if (documents.length === 0) {
    return (
      <div className="text-center p-6 bg-gray-50 rounded-lg">
        <FileText className="w-8 h-8 text-gray-400 mx-auto mb-2" />
        <p className="text-gray-600">No documents added yet</p>
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {documents.map((doc, index) => (
        <div
          key={index}
          className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
        >
          <div className="flex items-center gap-3">
            <FileText className="w-5 h-5 text-primary-600" />
            <div>
              <div className="font-medium">{doc.title}</div>
              <div className="text-sm text-gray-600">
                {doc.type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
                {doc.isPublic && (
                  <span className="ml-2 text-green-600">(Public)</span>
                )}
              </div>
            </div>
          </div>
          <button
            onClick={() => onRemove(index)}
            className="p-1 text-gray-400 hover:text-red-600 rounded-full hover:bg-red-50"
          >
            <X className="w-5 h-5" />
          </button>
        </div>
      ))}
    </div>
  );
}