import React, { useState, useEffect } from 'react';
import { Trophy, Star, Award, Shield, Gift } from 'lucide-react';
import { badgeService } from '@/services/api/badges';
import { calculateBadgeProgress } from '@/utils/badge-calculations';
import { investmentService } from '@/services/api/investments';
import { referralService } from '@/services/api/referrals';
import toast from 'react-hot-toast';

export function BadgesSection() {
  const [badgesProgress, setBadgesProgress] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

 useEffect(() => {
  const fetchBadgesProgress = async () => {
    try {
      // Fetch all required data
      const [investmentsResponse, referralResponse] = await Promise.all([
        investmentService.getInvestments(),
        referralService.getReferralHistory()
      ]);

      if (!investmentsResponse.success || !referralResponse.success) {
        throw new Error('Failed to fetch required data');
      }

      // Calculate badge progress with safe access to completed referrals
      const progress = calculateBadgeProgress(
        investmentsResponse.data,
        referralResponse.data?.completed || 0 // Provide default value of 0 if data or completed is undefined
      );

      setBadgesProgress(progress);
    } catch (error) {
      console.error('Failed to load badges progress:', error);
      toast.error('Failed to load badges progress');
    } finally {
      setIsLoading(false);
    }
  };

  fetchBadgesProgress();
}, []);


  if (isLoading) {
    return (
      <div className="p-6">
        <div className="animate-pulse space-y-8">
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-48 bg-gray-200 rounded-xl"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Investor Badges</h3>
        <p className="text-gray-600">
          Earn badges by maintaining consistent investment activity and growing your portfolio.
        </p>
      </div>

      <div className="grid gap-8">
        {badgesProgress.map((badge) => (
          <div
            key={badge.type}
            className={`rounded-xl border ${
              badge.isUnlocked
                ? 'border-primary-200 bg-primary-50/50'
                : 'border-gray-200'
            }`}
          >
            <div className="p-6">
              <div className="flex items-start gap-4">
                <div className={`p-3 rounded-xl ${
                  badge.type === 'platinum' ? 'bg-purple-100 text-purple-600' :
                  badge.type === 'gold' ? 'bg-amber-100 text-amber-600' :
                  'bg-gray-100 text-gray-600'
                }`}>
                  {badge.type === 'platinum' ? <Award className="w-8 h-8" /> :
                   badge.type === 'gold' ? <Star className="w-8 h-8" /> :
                   <Trophy className="w-8 h-8" />}
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-2">
                    <h4 className="text-lg font-semibold capitalize">{badge.type} Investor</h4>
                    {badge.isUnlocked ? (
                      <span className="px-3 py-1 bg-primary-100 text-primary-700 rounded-full text-sm font-medium">
                        Unlocked
                      </span>
                    ) : (
                      <span className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm font-medium">
                        {badge.progress}% Complete
                      </span>
                    )}
                  </div>

                  <div className="grid md:grid-cols-2 gap-6">
                    {/* Requirements */}
                    <div>
                      <h5 className="font-medium mb-3 flex items-center gap-2">
                        <Shield className="w-4 h-4 text-primary-600" />
                        Requirements
                      </h5>
                      <ul className="space-y-2">
                        <li className={`flex items-center gap-2 text-sm ${
                          badge.requirementsMet.includes('portfolio_value') ? 'text-primary-600' : 'text-gray-600'
                        }`}>
                          <div className={`w-1.5 h-1.5 rounded-full ${
                            badge.requirementsMet.includes('portfolio_value') ? 'bg-primary-600' : 'bg-gray-400'
                          }`} />
                          Portfolio Value: ${badge.requirements.minPortfolioValue.toLocaleString()}
                        </li>
                        <li className={`flex items-center gap-2 text-sm ${
                          badge.requirementsMet.includes('monthly_investments') ? 'text-primary-600' : 'text-gray-600'
                        }`}>
                          <div className={`w-1.5 h-1.5 rounded-full ${
                            badge.requirementsMet.includes('monthly_investments') ? 'bg-primary-600' : 'bg-gray-400'
                          }`} />
                          {badge.requirements.monthlyInvestments} Monthly Investments
                        </li>
                        {badge.requirements.referrals && (
                          <li className={`flex items-center gap-2 text-sm ${
                            badge.requirementsMet.includes('referrals') ? 'text-primary-600' : 'text-gray-600'
                          }`}>
                            <div className={`w-1.5 h-1.5 rounded-full ${
                              badge.requirementsMet.includes('referrals') ? 'bg-primary-600' : 'bg-gray-400'
                            }`} />
                            {badge.requirements.referrals} Successful Referrals
                          </li>
                        )}
                        {badge.requirements.minReturn && (
                          <li className={`flex items-center gap-2 text-sm ${
                            badge.requirementsMet.includes('min_return') ? 'text-primary-600' : 'text-gray-600'
                          }`}>
                            <div className={`w-1.5 h-1.5 rounded-full ${
                              badge.requirementsMet.includes('min_return') ? 'bg-primary-600' : 'bg-gray-400'
                            }`} />
                            {badge.requirements.minReturn}% Portfolio Returns
                          </li>
                        )}
                      </ul>
                    </div>

                    {/* Benefits */}
                    <div>
                      <h5 className="font-medium mb-3 flex items-center gap-2">
                        <Gift className="w-4 h-4 text-primary-600" />
                        Benefits
                      </h5>
                      <ul className="space-y-2">
                        {badge.type === 'silver' && [
                          'Early access to new properties',
                          'Reduced platform fees',
                          'Monthly investment insights'
                        ].map((benefit, index) => (
                          <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <div className="w-1.5 h-1.5 rounded-full bg-primary-600" />
                            {benefit}
                          </li>
                        ))}
                        {badge.type === 'gold' && [
                          'Priority investment access',
                          'Exclusive webinars',
                          'Dedicated investment advisor',
                          'Higher referral rewards'
                        ].map((benefit, index) => (
                          <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <div className="w-1.5 h-1.5 rounded-full bg-primary-600" />
                            {benefit}
                          </li>
                        ))}
                        {badge.type === 'platinum' && [
                          'VIP investment opportunities',
                          'Zero platform fees',
                          'Quarterly strategy sessions',
                          'Exclusive networking events',
                          'Custom portfolio analysis'
                        ].map((benefit, index) => (
                          <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <div className="w-1.5 h-1.5 rounded-full bg-primary-600" />
                            {benefit}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {!badge.isUnlocked && (
                    <div className="mt-6">
                      <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-primary-600 rounded-full transition-all duration-500"
                          style={{ width: `${Math.min(Math.max(badge.progress, 0), 100)}%` }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}