import api from './axios';
import type { ApiResponse } from '@/types/response';
import type { Achievement } from '@/types/achievement';

export const achievementService = {
  async getAchievements(): Promise<ApiResponse<Achievement[]>> {
    try {
      const { data } = await api.get<ApiResponse<Achievement[]>>('/achievements');
      
      if (!data.success) {
        throw new Error(data.message || 'Failed to fetch achievements');
      }

      // Ensure all achievements have valid progress values
      const validatedAchievements = data.data?.map(achievement => ({
        ...achievement,
        progress: Math.min(Math.max(achievement.progress, 0), 100),
        current: achievement.current || 0,
        target: achievement.target || 0
      })) || [];

      return {
        success: true,
        data: validatedAchievements
      };
    } catch (error) {
      console.error('Get achievements error:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Failed to load achievements',
        data: []
      };
    }
  }
};