import React, { forwardRef } from 'react';
import { Award, Calendar, DollarSign, MapPin, User, Link } from 'lucide-react';
import type { Investment } from '@/types/investment';
import type { UserProfile } from '@/types/user';
import { formatLocationString } from '@/utils/property';

interface InvestmentCertificateProps {
  investment: Investment;
  user: UserProfile;
  showNFTDetails?: boolean;
}

export const InvestmentCertificate = forwardRef<HTMLDivElement, InvestmentCertificateProps>(
    ({ investment, user, showNFTDetails }, ref) => {
        return (
            <div ref={ref} className="relative w-full max-w-3xl mx-auto">
                {/* Certificate Border */}
                <div className="absolute inset-0 border-[12px] border-primary-100 rounded-3xl"></div>
                
                {/* Certificate Content */}
                <div className="relative p-12 bg-white rounded-2xl">
                    {/* Header */}
                    <div className="text-center mb-8">
                        <div className="flex justify-center mb-4">
                            <Award className="w-16 h-16 text-primary-600" />
                        </div>
                        <h2 className="text-3xl font-bold text-gray-900 mb-2">Investment Certificate</h2>
                        {showNFTDetails && investment.nftTokenId && (
                            <div className="text-sm text-gray-600">
                                NFT Token ID: {investment.nftTokenId}
                            </div>
                        )}
                    </div>

                    {/* Main Content */}
                    <div className="space-y-6">
                        {/* Investor Details */}
                        <div className="flex items-center gap-4 p-4 bg-gray-50 rounded-xl">
                            <User className="w-6 h-6 text-primary-600" />
                            <div>
                                <div className="text-sm text-gray-600">Investor</div>
                                <div className="font-medium">{user.name}</div>
                            </div>
                        </div>

                        {/* Investment Details */}
                        <div className="grid grid-cols-2 gap-4">
                            <div className="p-4 bg-gray-50 rounded-xl">
                                <div className="flex items-center gap-3 mb-2">
                                    <DollarSign className="w-5 h-5 text-primary-600" />
                                    <div className="text-sm text-gray-600">Investment Amount</div>
                                </div>
                                <div className="font-semibold">${investment.amount.toLocaleString()}</div>
                            </div>

                            <div className="p-4 bg-gray-50 rounded-xl">
                                <div className="flex items-center gap-3 mb-2">
                                    <Calendar className="w-5 h-5 text-primary-600" />
                                    <div className="text-sm text-gray-600">Investment Date</div>
                                </div>
                                <div className="font-semibold">
                                    {new Date(investment.createdAt).toLocaleDateString()}
                                </div>
                            </div>
                        </div>

                        {/* Property Details */}
                        <div className="p-4 bg-gray-50 rounded-xl">
                            <div className="flex items-center gap-3 mb-2">
                                <MapPin className="w-5 h-5 text-primary-600" />
                                <div className="text-sm text-gray-600">Property</div>
                            </div>
                            <div className="font-semibold">{investment.property?.title}</div>
                            <div className="text-sm text-gray-600 mt-1">
                            {investment.property?.location ? formatLocationString(investment.property.location) : 'Unknown Location'}
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="mt-8 pt-8 border-t border-gray-200">
                        <div className="text-center text-sm text-gray-600">
                            <p>This certificate verifies ownership of investment in the above property.</p>
                            {investment.transactionHash && (
                                <p className="mt-1">
                                    <span className="font-medium">Transaction Hash:</span>{' '}
                                    {investment.transactionHash}
                                </p>
                            )}
                            {showNFTDetails && investment.nftTokenId && (
                                <div className="mt-4 space-y-2">
                                    <p>
                                        <span className="font-medium">NFT Token ID:</span>{' '}
                                        {investment.nftTokenId}
                                    </p>
                                    <div className="flex items-center justify-center gap-2 text-primary-600">
                                        <Link className="w-4 h-4" />
                                        <span className="font-medium">IPFS Hash:</span>{' '}
                                        <a 
                                            href={`https://gateway.pinata.cloud/ipfs/${investment.transactionHash?.replace('ipfs://', '')}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="hover:text-primary-700"
                                        >
                                            {investment.transactionHash?.replace('ipfs://', '')}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

InvestmentCertificate.displayName = 'InvestmentCertificate';