import { adminApi } from '../client';
import type { ApiResponse } from '@/types/response';
import type { AdminSettings } from '@/types/admin';

export const settingsService = {
  async getSettings() {
    try {
      const { data } = await adminApi.get<ApiResponse<AdminSettings>>('/settings');
      return data;
    } catch (error) {
      console.error('Get admin settings error:', error);
      throw error;
    }
  },

  async updateSettings(settings: Partial<AdminSettings>) {
    try {
      const { data } = await adminApi.put<ApiResponse<AdminSettings>>('/settings', settings);
      return data;
    } catch (error) {
      console.error('Update admin settings error:', error);
      throw error;
    }
  },

  async testEmailSettings(emailSettings: AdminSettings['email']) {
    try {
      const { data } = await adminApi.post<ApiResponse<void>>('/settings/email/test', emailSettings);
      return data;
    } catch (error) {
      console.error('Test email settings error:', error);
      throw error;
    }
  }
};