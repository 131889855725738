import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MapPin, Users, TrendingUp, ArrowRight, Building2, Heart, Bed } from 'lucide-react';
import { adminService } from '@/services/api/admin';
import { documentService } from '@/services/api/properties/documents';
import { PropertyHeader } from './PropertyHeader';
import { PropertyProgress } from './PropertyProgress';
import { PropertyStats } from './PropertyStats';
import { PropertyDescription } from './PropertyDescription';
import { PropertyDetailsGallery } from '@/components/PropertyDetailsGallery';
import { PropertyDocuments } from '@/components/PropertyDocuments';
import { PropertyMap } from '@/components/PropertyMap';
import { InvestmentCalculator } from '@/components/InvestmentCalculator';
import { InvestmentModal } from '@/components/investment/InvestmentModal';
import { StickyCTA } from '@/components/StickyCTA';
import { SideInvestmentCard } from '@/components/SideInvestmentCard';
import { AgentProfile } from '@/components/AgentProfile';
import toast from 'react-hot-toast';
import type { Property } from '@/types/property';
import type { Document } from '@/types/document';

const agent = {
  name: "Sarah Chen",
  title: "Senior Investment Advisor",
  image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=150&q=80",
  phone: "(555) 123-4567",
  email: "sarah.chen@example.com",
  experience: 8,
  deals: 150,
  rating: 4.9,
  bio: "Specializing in luxury residential properties with 8+ years of experience in real estate investment advisory."
};

export function PropertyDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState<Property | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showInvestModal, setShowInvestModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchPropertyData = async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      const [propertyResponse, documentsResponse] = await Promise.all([
        adminService.getProperty(id),
        documentService.getDocuments(id)
      ]);

      if (propertyResponse.success && propertyResponse.data) {
        setProperty(propertyResponse.data);
      }

      if (documentsResponse.success && documentsResponse.data) {
        setDocuments(documentsResponse.data);
      }
    } catch (error) {
      console.error('Failed to load property:', error);
      toast.error('Failed to load property details');
      navigate('/admin/properties');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, [id]);

  const handleDelete = async () => {
    if (!id) return;
  
    setIsDeleting(true);
    try {
      await adminService.deleteProperty(id);
      toast.success('Property deleted successfully');
      navigate('/admin/properties');
    } catch (error) {
      console.error('Failed to delete property:', error);
      toast.error('Failed to delete property');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDocumentDelete = async (documentId: string) => {
    if (!id) return;
    
    try {
      const response = await documentService.deleteDocument(id, documentId);
      if (response.success) {
        setDocuments(prev => prev.filter(doc => doc.id !== documentId));
        toast.success('Document deleted successfully');
      }
    } catch (error) {
      toast.error('Failed to delete document');
    }
  };

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
        <div className="h-96 bg-gray-200 rounded-xl"></div>
      </div>
    );
  }

  if (!property) {
    return null;
  }

  const progress = (property.raisedAmount / property.targetAmount) * 100;

  return (
    <div className="space-y-6">
      <PropertyHeader 
        property={property}
        onDelete={handleDelete}
        isDeleting={isDeleting}
      />

      <div className="bg-white p-6 rounded-xl border border-gray-200">
        <PropertyDetailsGallery
          images={property.images}
          title={property.title}
          className="mb-6"
        />

        <PropertyProgress property={property} progress={progress} />
        <PropertyStats property={property} />
      </div>

      <PropertyDescription property={property} />

      <div className="bg-white p-6 rounded-xl border border-gray-200">
        <h2 className="text-xl font-semibold mb-6">Property Documents</h2>
        <PropertyDocuments
          propertyId={id!}  // Use non-null assertion since we know id exists here
          documents={documents}
          onDelete={handleDocumentDelete}
          isAdmin
        />
      </div>

      <InvestmentCalculator
        expectedReturn={property.expectedReturn}
        minInvestment={property.minInvestment}
      />

      <div className="mt-8">
        <PropertyMap
          latitude={property.location.coordinates.latitude}
          longitude={property.location.coordinates.longitude}
          address={property.location.address}
        />
      </div>
    </div>
  );
}