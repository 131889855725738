import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Shield, ArrowRight, RefreshCw } from 'lucide-react';
import { useAuth } from '@/hooks/useAuth';
import { OTPInput } from '@/components/auth/OTPInput';
import { verificationService } from '@/services/api/auth/verification';
import toast from 'react-hot-toast';

export function VerifyOTP() {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const navigate = useNavigate();
  const { verificationEmail, verifyEmail } = useAuth();

  useEffect(() => {
    if (!verificationEmail) {
      navigate('/login', { replace: true });
    }
  }, [verificationEmail, navigate]);

  useEffect(() => {
    const timer = timeLeft > 0 && setInterval(() => setTimeLeft(time => time - 1), 1000);
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [timeLeft]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);
    
    const otpValue = otp.join('');
    if (otpValue.length !== 6) {
      setError('Please enter all 6 digits');
      setIsLoading(false);
      return;
    }

    try {
      await verifyEmail(otpValue);
      // Navigation is handled in verifyEmail
    } catch (error) {
      const err = error as Error;
      setError(err.message);
      setOtp(['', '', '', '', '', '']);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    if (isResending || timeLeft > 0 || !verificationEmail) return;
    
    setIsResending(true);
    try {
      await verificationService.resendOTP(verificationEmail);
      setTimeLeft(300); // Reset timer to 5 minutes
      toast.success('New verification code sent!');
    } catch (error) {
      const err = error as Error;
      toast.error(err.message || 'Failed to resend verification code');
    } finally {
      setIsResending(false);
    }
  };

  if (!verificationEmail) {
    return null;
  }

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="w-full max-w-md">
        <div className="bg-white p-8 rounded-2xl shadow-sm border border-gray-200">
          <div className="text-center mb-8">
            <div className="w-16 h-16 bg-primary-100 rounded-2xl flex items-center justify-center mx-auto mb-4">
              <Shield className="w-8 h-8 text-primary-600" />
            </div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">Verify your email</h1>
            <p className="text-gray-600">
              Enter the 6-digit code sent to<br />
              <span className="font-medium">{verificationEmail}</span>
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3 text-center">
                Verification Code
              </label>
              <OTPInput
                value={otp}
                onChange={setOtp}
                disabled={isLoading}
              />
              {error && (
                <p className="mt-2 text-sm text-red-600 text-center">{error}</p>
              )}
            </div>

            <div className="text-center">
              <p className="text-sm text-gray-600 mb-2">
                Didn't receive the code?
              </p>
              <button
                type="button"
                onClick={handleResendOTP}
                disabled={isResending || timeLeft > 0}
                className="inline-flex items-center gap-2 text-primary-600 hover:text-primary-700 disabled:text-gray-400"
              >
                <RefreshCw className={`w-4 h-4 ${isResending ? 'animate-spin' : ''}`} />
                <span>
                  {timeLeft > 0
                    ? `Resend in ${formatTime(timeLeft)}`
                    : 'Resend Code'}
                </span>
              </button>
            </div>

            <button
              type="submit"
              disabled={isLoading || otp.some(digit => !digit)}
              className="w-full bg-primary-600 text-white py-3 rounded-xl font-medium hover:bg-primary-700 transition-colors flex items-center justify-center gap-2 disabled:opacity-50"
            >
              {isLoading ? (
                <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin"></div>
              ) : (
                <>
                  Verify Email
                  <ArrowRight className="w-5 h-5" />
                </>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}