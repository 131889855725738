import type { Property } from '@/types/property/base';
import type { PropertyFormData } from '@/types/property/form';
import type { PropertyFinancials } from '@/types/property/financials';

export function transformPropertyToFormData(property: Property): PropertyFormData {
  // Extract financials data if it exists
  const financials: PropertyFinancials | undefined = property.financials ? {
    purchasePrice: property.purchasePrice || 0, // Provide default value
    currentValue: property.currentValue,
    expectedValue: property.financials.expectedValue,
    rentalIncome: {
      monthly: property.financials.rentalIncome.monthly,
      occupancyRate: property.financials.rentalIncome.occupancyRate,
      netOperatingIncome: property.financials.rentalIncome.netOperatingIncome
    },
    expenses: property.financials.expenses || []
  } : undefined;

  return {
    title: property.title,
    description: {
      summary: property.description.summary,
      full: property.description.full,
      highlights: property.description.highlights
    },
    location: {
      address: property.location.address,
      city: property.location.city,
      state: property.location.state,
      country: property.location.country,
      zipCode: property.location.zipCode,
      coordinates: {
        latitude: property.location.coordinates.latitude,
        longitude: property.location.coordinates.longitude
      }
    },
    type: property.type,
    status: property.status,
    targetAmount: property.targetAmount,
    raisedAmount: property.raisedAmount,
    minInvestment: property.minInvestment,
    maxInvestment: property.maxInvestment,
    investors: property.investors,
    expectedReturn: property.expectedReturn,
    features: property.features,
    amenities: property.amenities,
    images: property.images,
    purchasePrice: property.purchasePrice || 0, // Provide default value
    currentValue: property.currentValue,
    financials
  };
}
