import React, { useState, useEffect } from 'react';
import { Mail, Bell, FileText } from 'lucide-react';
import { settingsService } from '@/services/api/settings';
import type { Settings } from '@/types/settings';
import toast from 'react-hot-toast';

interface EmailPreferencesProps {
  settings: Settings | null;
  onUpdate: (settings: Partial<Settings>) => void;
}

const emailPreferences = [
  {
    id: 'marketing',
    title: 'Marketing Emails',
    description: 'Receive updates about new investment opportunities and promotions',
    icon: Mail
  },
  {
    id: 'newsletter',
    title: 'Newsletter',
    description: 'Weekly digest of market insights and investment tips',
    icon: FileText
  },
  {
    id: 'updates',
    title: 'Portfolio Updates',
    description: 'Get notified about important changes to your investments',
    icon: Bell
  }
];

export function EmailPreferences({ settings, onUpdate }: EmailPreferencesProps) {
  const [preferences, setPreferences] = useState(settings?.emailPreferences || {
    marketing: true,
    newsletter: true,
    updates: true
  });
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (settings?.emailPreferences) {
      setPreferences(settings.emailPreferences);
    }
  }, [settings]);

  const handlePreferenceChange = async (prefId: keyof typeof preferences) => {
    setIsUpdating(true);
    const updatedPreferences = {
      ...preferences,
      [prefId]: !preferences[prefId]
    };

    try {
      const response = await settingsService.updateEmailPreferences(updatedPreferences);
      if (response.success) {
        setPreferences(updatedPreferences);
        onUpdate({ emailPreferences: updatedPreferences }); // Update parent state
        toast.success('Email preferences updated');
      } else {
        throw new Error(response.message || 'Failed to update preferences');
      }
    } catch (error) {
      toast.error('Failed to update preferences');
      // Revert on error
      setPreferences(prev => ({ ...prev }));
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium">Email Preferences</h3>
      <div className="grid gap-4">
        {emailPreferences.map((pref) => {
          const Icon = pref.icon;
          return (
            <label
              key={pref.id}
              className={`flex items-center justify-between p-4 rounded-lg border ${
                isUpdating ? 'opacity-50 cursor-not-allowed' : 'hover:border-primary-300 cursor-pointer'
              } ${
                preferences[pref.id as keyof typeof preferences] ? 'border-primary-500 bg-primary-50' : 'border-gray-200'
              } transition-colors`}
            >
              <div className="flex items-center gap-3">
                <Icon className="w-5 h-5 text-primary-600" />
                <div>
                  <div className="font-medium">{pref.title}</div>
                  <div className="text-sm text-gray-600">{pref.description}</div>
                </div>
              </div>
              <input
                type="checkbox"
                checked={preferences[pref.id as keyof typeof preferences]}
                onChange={() => handlePreferenceChange(pref.id as keyof typeof preferences)}
                disabled={isUpdating}
                className="h-4 w-4 rounded text-primary-600 focus:ring-primary-500 disabled:opacity-50"
              />
            </label>
          );
        })}
      </div>
    </div>
  );
}