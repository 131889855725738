import React from 'react';
import { Users, Gift, TrendingUp } from 'lucide-react';

interface ReferralStatsProps {
  stats: {
    total: number;
    completed: number;
    pending: number;
    totalRewards: number;
  };
}

export function ReferralStats({ stats }: ReferralStatsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
      <div className="bg-white p-6 rounded-lg border border-gray-200">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-primary-50 rounded-lg">
            <Users className="w-5 h-5 text-primary-600" />
          </div>
          <div>
            <div className="text-sm text-gray-600">Total Referrals</div>
            <div className="text-xl font-semibold">{stats.total}</div>
            <div className="text-sm text-gray-500">
              {stats.completed} completed · {stats.pending} pending
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg border border-gray-200">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-green-50 rounded-lg">
            <Gift className="w-5 h-5 text-green-600" />
          </div>
          <div>
            <div className="text-sm text-gray-600">Total Rewards</div>
            <div className="text-xl font-semibold text-green-600">
              ${stats.totalRewards.toLocaleString()}
            </div>
            <div className="text-sm text-gray-500">
              ${(stats.totalRewards / stats.completed || 0).toFixed(2)} per referral
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-6 rounded-lg border border-gray-200">
        <div className="flex items-center gap-3">
          <div className="p-2 bg-amber-50 rounded-lg">
            <TrendingUp className="w-5 h-5 text-amber-600" />
          </div>
          <div>
            <div className="text-sm text-gray-600">Conversion Rate</div>
            <div className="text-xl font-semibold text-amber-600">
              {((stats.completed / stats.total || 0) * 100).toFixed(1)}%
            </div>
            <div className="text-sm text-gray-500">
              {stats.completed} of {stats.total} converted
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}