import axios from 'axios';

const PINATA_API_KEY = import.meta.env.VITE_PINATA_API_KEY;
const PINATA_SECRET_KEY = import.meta.env.VITE_PINATA_SECRET_KEY;

export async function uploadToIPFS(data: any): Promise<string> {
  try {
    console.log('Uploading to IPFS:', { data });
    
    if (!PINATA_API_KEY || !PINATA_SECRET_KEY) {
      throw new Error('Pinata API keys not configured');
    }

    const response = await axios.post(
      'https://api.pinata.cloud/pinning/pinJSONToIPFS',
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          pinata_api_key: PINATA_API_KEY,
          pinata_secret_api_key: PINATA_SECRET_KEY
        }
      }
    );

    console.log('IPFS upload response:', response.data);
    return `ipfs://${response.data.IpfsHash}`;
  } catch (error) {
    console.error('IPFS upload error:', error);
    throw new Error('Failed to upload metadata to IPFS');
  }
}