import React from 'react';
import { Link } from 'react-router-dom';
import { Building2 } from 'lucide-react';

export function EmptyState() {
  return (
    <div className="text-center py-12 bg-white rounded-xl border border-gray-200">
      <Building2 className="w-12 h-12 text-gray-400 mx-auto mb-4" />
      <h3 className="text-lg font-medium text-gray-900 mb-2">No Investments Yet</h3>
      <p className="text-gray-600 mb-6">
        Start building your real estate portfolio today
      </p>
      <Link
        to="/properties"
        className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700"
      >
        Browse Properties
      </Link>
    </div>
  );
}