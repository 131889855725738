import api from '../axios';
import type { ResetPasswordData, ChangePasswordData, AuthResponse } from './types';

export const passwordService = {
  async forgotPassword(email: string): Promise<AuthResponse> {
    try {
      const response = await api.post<AuthResponse>('/auth/forgot-password', { email });
      return response.data;
    } catch (error: any) {
      console.error('Forgot Password API Error:', error);
      throw error;
    }
  },

  async resetPassword(resetData: ResetPasswordData): Promise<AuthResponse> {
    try {
      const response = await api.post<AuthResponse>('/auth/reset-password', resetData);
      return response.data;
    } catch (error: any) {
      console.error('Reset Password API Error:', error);
      throw error;
    }
  },

  async changePassword(changeData: ChangePasswordData): Promise<AuthResponse> {
    try {
      const response = await api.put<AuthResponse>('/auth/change-password', changeData);
      return response.data;
    } catch (error: any) {
      console.error('Change Password API Error:', error);
      throw error;
    }
  }
};