import { ethers } from 'ethers';
import { initializeRewardsContract } from './contract/initialization';
import { calculateGasConfig } from './contract/gas';
import { REWARD_TOKENS } from './config';
import type { SupportedChainId } from './types';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000; // 2 seconds

export class RewardsContract {
  private contract: ethers.Contract | null = null;
  private signer: ethers.providers.JsonRpcSigner | null = null;
  private chainId: SupportedChainId | null = null;

  async checkBalance(tokenAddress: string, amount: string): Promise<boolean> {
    try {
      if (!this.contract) throw new Error('Contract not initialized');

      // Get token decimals
      const tokenContract = new ethers.Contract(
        tokenAddress,
        ['function decimals() view returns (uint8)'],
        this.signer!
      );
      const decimals = await tokenContract.decimals();

      // Convert amount to token units
      const amountInTokens = ethers.utils.parseUnits(amount, decimals);

      // Get contract's token balance
      const tokenContract2 = new ethers.Contract(
        tokenAddress,
        ['function balanceOf(address) view returns (uint256)'],
        this.signer!
      );
      const balance = await tokenContract2.balanceOf(this.contract.address);

      return balance.gte(amountInTokens);
    } catch (error) {
      console.error('Check balance error:', error);
      return false;
    }
  }

  async sendReward(
    recipientAddress: string, 
    amount: string,
    chainId: SupportedChainId
  ): Promise<string> {
    try {
      // Initialize contract
      const { contract, signer } = await initializeRewardsContract(chainId);
      this.contract = contract;
      this.signer = signer;
      this.chainId = chainId;

      // Validate recipient address
      if (!ethers.utils.isAddress(recipientAddress)) {
        throw new Error('Invalid recipient address');
      }

      // Get reward token for this chain
      const rewardToken = REWARD_TOKENS[chainId][0];
      if (!rewardToken) {
        throw new Error(`No reward token configured for chain ${chainId}`);
      }

      // Check contract balance
      const hasBalance = await this.checkBalance(rewardToken, amount);
      if (!hasBalance) {
        throw new Error('Insufficient contract balance to send reward');
      }

      console.log('Sending reward:', {
        to: recipientAddress,
        amount,
        chainId,
        token: rewardToken
      });

      let lastError: Error | null = null;

      for (let attempt = 0; attempt <= MAX_RETRIES; attempt++) {
        try {
          if (attempt > 0) {
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
          }

          // Get fresh nonce
          const nonce = await this.signer.getTransactionCount();

          // Get gas configuration with retry attempt
          const gasEstimate = await this.contract.estimateGas.sendReward(
            rewardToken,
            recipientAddress,
            {
              from: await this.signer.getAddress()
            }
          );

          const gasConfig = await calculateGasConfig(
            this.signer.provider!,
            this.chainId,
            gasEstimate,
            attempt
          );

          console.log('Gas config:', {
            nonce,
            gasLimit: gasConfig.gasLimit.toString(),
            maxFeePerGas: ethers.utils.formatUnits(gasConfig.maxFeePerGas, 'gwei'),
            maxPriorityFeePerGas: ethers.utils.formatUnits(gasConfig.maxPriorityFeePerGas, 'gwei')
          });

          // Send transaction
          const tx = await this.contract.sendReward(
            rewardToken,
            recipientAddress,
            {
              ...gasConfig,
              nonce
            }
          );

          console.log('Transaction sent:', tx.hash);

          // Wait for confirmations
          const receipt = await tx.wait(3);
          
          if (receipt.status !== 1) {
            throw new Error('Transaction failed');
          }

          return receipt.transactionHash;
        } catch (error: any) {
          lastError = error;
          console.warn(`Attempt ${attempt + 1} failed:`, error.message);

          if (error.code === 4001) { // User rejected
            throw new Error('Transaction rejected by user');
          }

          // Only retry on specific errors
          if (!error.message.toLowerCase().includes('gas') &&
              !error.message.toLowerCase().includes('nonce') &&
              !error.message.toLowerCase().includes('underpriced')) {
            break;
          }
        }
      }

      throw lastError || new Error('Failed to send reward after retries');
    } catch (error: any) {
      console.error('Send reward error:', error);
      throw error;
    }
  }
}

export const rewardsContract = new RewardsContract();