import React from 'react';
import { Eye, Check, X } from 'lucide-react';
import type { VerificationRequest } from '@/types/verification';

interface VerificationListProps {
  requests: VerificationRequest[];
  onView: (request: VerificationRequest) => void;
  onApprove: (userId: string) => void;
  onReject: (request: VerificationRequest) => void;
  isSubmitting: boolean;
}

export function VerificationList({ 
  requests, 
  onView, 
  onApprove, 
  onReject,
  isSubmitting 
}: VerificationListProps) {
  return (
    <table className="w-full">
      <thead>
        <tr className="bg-gray-50 border-b border-gray-200">
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">User</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Status</th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-500">Submitted</th>
          <th className="px-6 py-3 text-right text-sm font-medium text-gray-500">Actions</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {requests.map((request) => (
          <tr key={request.id} className="hover:bg-gray-50">
            <td className="px-6 py-4">
              <div className="font-medium">{request.userName}</div>
              <div className="text-sm text-gray-600">{request.userEmail}</div>
            </td>
            <td className="px-6 py-4">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                request.status === 'approved' ? 'bg-green-100 text-green-800' :
                request.status === 'rejected' ? 'bg-red-100 text-red-800' :
                'bg-yellow-100 text-yellow-800'
              }`}>
                {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
              </span>
            </td>
            <td className="px-6 py-4 text-gray-600">
              {new Date(request.submittedAt).toLocaleDateString()}
            </td>
            <td className="px-6 py-4 text-right space-x-2">
              <button
                onClick={() => onView(request)}
                className="text-primary-600 hover:text-primary-700"
              >
                <Eye className="w-5 h-5" />
              </button>
              {request.status === 'pending' && (
                <>
                  <button
                    onClick={() => onApprove(request.userId)}
                    disabled={isSubmitting}
                    className="text-green-600 hover:text-green-700 disabled:opacity-50"
                  >
                    <Check className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => onReject(request)}
                    disabled={isSubmitting}
                    className="text-red-600 hover:text-red-700 disabled:opacity-50"
                  >
                    <X className="w-5 h-5" />
                  </button>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}