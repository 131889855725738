import React from 'react';
import { DollarSign, TrendingUp, Users } from 'lucide-react';

interface PropertyFinancialsProps {
  formData: any;
  onChange: (data: any) => void;
  errors?: Record<string, string>;
}

export function PropertyFinancials({ formData, onChange, errors }: PropertyFinancialsProps) {
  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <DollarSign className="w-5 h-5 text-primary-600" />
        Investment Details
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Target Amount*
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              value={formData.targetAmount}
              onChange={(e) => onChange({ ...formData, targetAmount: Number(e.target.value) })}
              className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                errors?.targetAmount ? 'border-red-300' : 'border-gray-200'
              } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
              placeholder="Enter target amount"
              min="1"
              step="1"
              required
            />
          </div>
          {errors?.targetAmount && (
            <p className="mt-1 text-sm text-red-600">{errors.targetAmount}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Expected Return (%)*
          </label>
          <div className="relative">
            <TrendingUp className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              value={formData.expectedReturn}
              onChange={(e) => onChange({ ...formData, expectedReturn: Number(e.target.value) })}
              className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                errors?.expectedReturn ? 'border-red-300' : 'border-gray-200'
              } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
              placeholder="Enter expected return"
              min="0"
              max="100"
              step="0.1"
              required
            />
          </div>
          {errors?.expectedReturn && (
            <p className="mt-1 text-sm text-red-600">{errors.expectedReturn}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Minimum Investment*
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              value={formData.minInvestment}
              onChange={(e) => onChange({ ...formData, minInvestment: Number(e.target.value) })}
              className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                errors?.minInvestment ? 'border-red-300' : 'border-gray-200'
              } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
              placeholder="Enter minimum investment"
              min="1"
              step="1"
              required
            />
          </div>
          {errors?.minInvestment && (
            <p className="mt-1 text-sm text-red-600">{errors.minInvestment}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Maximum Investment*
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              value={formData.maxInvestment}
              onChange={(e) => onChange({ ...formData, maxInvestment: Number(e.target.value) })}
              className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
                errors?.maxInvestment ? 'border-red-300' : 'border-gray-200'
              } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
              placeholder="Enter maximum investment"
              min="1"
              step="1"
              required
            />
          </div>
          {errors?.maxInvestment && (
            <p className="mt-1 text-sm text-red-600">{errors.maxInvestment}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Current Investors
          </label>
          <div className="relative">
            <Users className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              value={formData.investors}
              onChange={(e) => onChange({ ...formData, investors: Number(e.target.value) })}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Number of current investors"
              min="0"
              step="1"
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Raised Amount
          </label>
          <div className="relative">
            <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
            <input
              type="number"
              value={formData.raisedAmount}
              onChange={(e) => onChange({ ...formData, raisedAmount: Number(e.target.value) })}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
              placeholder="Amount raised so far"
              min="0"
              step="1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}