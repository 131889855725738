import React, { useState } from 'react';
import { MessageSquare, Star, ExternalLink } from 'lucide-react';
import { SubmitFeedback } from './feedback/SubmitFeedback';
import { RateUs } from './feedback/RateUs';
import { PlatformRating } from './feedback/PlatformRating';

type FeedbackTab = 'submit' | 'platform' | 'google';

export function FeedbackSection() {
  const [activeTab, setActiveTab] = useState<FeedbackTab>('submit');

  const tabs = [
    { id: 'submit', label: 'Submit Feedback', icon: MessageSquare },
    { id: 'platform', label: 'Rate Platform', icon: Star },
    { id: 'google', label: 'Google Review', icon: ExternalLink }
  ] as const;

  return (
    <div className="space-y-6">
      {/* Tab Navigation */}
      <div className="flex space-x-2">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
              activeTab === tab.id
                ? 'bg-primary-600 text-white'
                : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
            }`}
          >
            <tab.icon className="w-4 h-4" />
            <span>{tab.label}</span>
          </button>
        ))}
      </div>

      {/* Tab Content */}
      <div className="bg-white rounded-xl border border-gray-200">
        {activeTab === 'submit' && <SubmitFeedback />}
        {activeTab === 'platform' && <PlatformRating />}
        {activeTab === 'google' && <RateUs />}
      </div>
    </div>
  );
}