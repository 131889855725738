import api from '../axios';
import type { ApiResponse } from '@/types/response';
import type { Agent, ScheduleCallRequest } from '@/types/agent';

export const agentService = {
  async getAgent(id: string): Promise<ApiResponse<Agent>> {
    try {
      const { data } = await api.get<ApiResponse<Agent>>(`/agents/${id}`);
      return data;
    } catch (error) {
      console.error('Get agent error:', error);
      throw error;
    }
  },

  async scheduleCall(params: ScheduleCallRequest): Promise<ApiResponse<void>> {
    try {
      const { data } = await api.post<ApiResponse<void>>('/agents/schedule-call', params);
      return data;
    } catch (error) {
      console.error('Schedule call error:', error);
      throw error;
    }
  },

  async getAvailability(agentId: string, date: string): Promise<ApiResponse<string[]>> {
    try {
      const { data } = await api.get<ApiResponse<string[]>>(`/agents/${agentId}/availability`, {
        params: { date }
      });
      return data;
    } catch (error) {
      console.error('Get availability error:', error);
      throw error;
    }
  }
};