import { getSignedUrl } from './s3';

export async function downloadFile(url: string, filename: string) {
  try {
    // Get signed URL if it's an S3 URL
    const downloadUrl = url.includes('amazonaws.com') ? await getSignedUrl(url) : url;
    
    const response = await fetch(downloadUrl);
    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);
    
    const link = document.createElement('a');
    link.href = objectUrl;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(objectUrl);
  } catch (error) {
    console.error('Download file error:', error);
    throw error;
  }
}

export function getFileExtension(mimeType: string): string {
  const mimeToExt: Record<string, string> = {
    'application/pdf': '.pdf',
    'application/msword': '.doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '.docx',
    'image/jpeg': '.jpg',
    'image/png': '.png',
    'image/gif': '.gif'
  };
  return mimeToExt[mimeType] || '';
}

export function sanitizeFilename(filename: string): string {
  // Remove invalid characters and limit length
  return filename
    .replace(/[^a-zA-Z0-9-_\.]/g, '_')
    .replace(/_{2,}/g, '_')
    .slice(0, 255);
}