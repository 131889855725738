import api from './axios';
import type { ApiResponse } from '@/types/response';
import type { Feedback } from '@/types/feedback';

export const feedbackService = {
  async submitFeedback(feedback: string, category?: string) {
    try {
      const { data } = await api.post<ApiResponse<Feedback>>('/feedback', { feedback, category });
      return data;
    } catch (error) {
      console.error('Submit feedback error:', error);
      throw error;
    }
  },

  async submitRating(rating: number, review?: string) {
    try {
      const { data } = await api.post<ApiResponse<Feedback>>('/feedback/rating', { rating, review });
      return data;
    } catch (error) {
      console.error('Submit rating error:', error);
      throw error;
    }
  },

  async getFeedbackHistory() {
    try {
      const { data } = await api.get<ApiResponse<Feedback[]>>('/feedback/history');
      return data;
    } catch (error) {
      console.error('Get feedback history error:', error);
      throw error;
    }
  }
};