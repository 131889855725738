import { ethers } from 'ethers';
import { REWARDS_CONTRACT_ADDRESSES, REWARDS_ABI, REWARD_TOKENS } from '../config';
import type { SupportedChainId } from '../types';

async function checkContractBalance(
  contract: ethers.Contract,
  tokenAddress: string,
  signer: ethers.providers.JsonRpcSigner
): Promise<boolean> {
  try {
    // Get token contract
    const tokenContract = new ethers.Contract(
      tokenAddress,
      ['function balanceOf(address) view returns (uint256)', 'function decimals() view returns (uint8)'],
      signer
    );

    // Get token decimals
    const decimals = await tokenContract.decimals();

    // Get contract's token balance
    const balance = await tokenContract.balanceOf(contract.address);
    
    // Get minimum required balance (1000 tokens)
    const minBalance = ethers.utils.parseUnits('1000', decimals);

    return balance.gte(minBalance);
  } catch (error) {
    console.error('Check contract balance error:', error);
    return false;
  }
}

async function checkTokenEnabled(
  contract: ethers.Contract,
  tokenAddress: string
): Promise<boolean> {
  try {
    // Call sendReward with 0 amount to check if token is enabled
    await contract.callStatic.sendReward(tokenAddress, ethers.constants.AddressZero);
    return true;
  } catch (error: any) {
    if (error.message.includes('Token is not enable')) {
      return false;
    }
    throw error;
  }
}

async function enableRewardToken(
  contract: ethers.Contract,
  tokenAddress: string,
  signer: ethers.providers.JsonRpcSigner
) {
  try {
    console.log('Enabling reward token:', tokenAddress);
    
    // Check if token is already enabled
    const isEnabled = await checkTokenEnabled(contract, tokenAddress);
    if (isEnabled) {
      console.log('Token already enabled:', tokenAddress);
      return;
    }

    // Check contract balance first
    const hasBalance = await checkContractBalance(contract, tokenAddress, signer);
    if (!hasBalance) {
      throw new Error('Insufficient contract balance');
    }

    // Get gas estimate
    const gasEstimate = await contract.estimateGas.setEnableRewardToken(tokenAddress);
    const gasLimit = gasEstimate.mul(120).div(100); // Add 20% buffer

    // Enable token
    const tx = await contract.setEnableRewardToken(tokenAddress, {
      gasLimit
    });

    // Wait for confirmation
    const receipt = await tx.wait(3);
    
    if (receipt.status !== 1) {
      throw new Error('Failed to enable reward token');
    }

    console.log('Token enabled successfully');
  } catch (error: any) {
    console.error('Enable reward token error:', error);
    throw error;
  }
}

export async function initializeRewardsContract(chainId: SupportedChainId) {
  if (!window.ethereum) {
    throw new Error('No crypto wallet found. Please install MetaMask.');
  }

  // Request account access
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  
  // Initialize provider and signer
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();

  // Get contract address for chain
  const contractAddress = REWARDS_CONTRACT_ADDRESSES[chainId];
  if (!contractAddress) {
    throw new Error(`No rewards contract address for chain ${chainId}`);
  }

  // Get reward token for chain
  const rewardToken = REWARD_TOKENS[chainId][0];
  if (!rewardToken) {
    throw new Error(`No reward token configured for chain ${chainId}`);
  }

  // Create contract instance
  const contract = new ethers.Contract(
    contractAddress,
    REWARDS_ABI,
    signer
  );

  // Check contract balance and enable token if needed
  const hasBalance = await checkContractBalance(contract, rewardToken, signer);
  if (!hasBalance) {
    throw new Error('Insufficient contract balance');
  }

  await enableRewardToken(contract, rewardToken, signer);

  return { contract, signer, chainId };
}