import api from './axios';
import type { ApiResponse } from '@/types/response';
import type { MilestoneCategory } from '@/types/milestone';

export const milestoneService = {
  async getMilestones(): Promise<ApiResponse<MilestoneCategory[]>> {
    try {
      const { data } = await api.get<ApiResponse<MilestoneCategory[]>>('/milestones');
      
      if (!data.success) {
        throw new Error(data.message || 'Failed to fetch milestones');
      }

      return {
        success: true,
        data: data.data || []
      };
    } catch (error) {
      console.error('Get milestones error:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Failed to load milestones',
        data: []
      };
    }
  }
};