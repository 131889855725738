import React from 'react';
import type { ChartInvestment } from '@/types/investment/chart';

interface LegendProps {
  investments: ChartInvestment[];
  colors: string[];
  onSelect: (index: number) => void;
  selectedIndex: number | null;
}

export function ChartLegend({ investments, colors, onSelect, selectedIndex }: LegendProps) {
  return (
    <div className="grid grid-cols-2 gap-4">
      {investments.map((investment, index) => (
        <button
          key={index}
          onClick={() => onSelect(index)}
          className={`p-4 rounded-lg text-left transition-all ${
            selectedIndex === index
              ? 'border-primary-500 bg-primary-50'
              : 'border-gray-200 hover:border-primary-300'
          } border`}
        >
          <div className="flex items-center gap-2">
            <div 
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: colors[index] }}
            />
            <span className="font-medium">{investment.allocation}%</span>
          </div>
          <div className="text-sm text-gray-600 mt-1">{investment.title}</div>
        </button>
      ))}
    </div>
  );
}