import React from 'react';
import { DollarSign, MapPin, TrendingUp } from 'lucide-react';
import { S3Image } from '@/components/S3Image';
import type { ChartInvestment } from '@/types/investment/chart';

interface DetailsProps {
  investment: ChartInvestment;
}

export function InvestmentDetails({ investment }: DetailsProps) {
  // Format location string based on type
  const locationString = typeof investment.location === 'string'
    ? investment.location
    : `${investment.location.city}, ${investment.location.state}`;

  return (
    <div className="p-4 bg-gray-50 rounded-xl border border-gray-200">
      <div className="flex items-start gap-4">
        <div className="w-16 h-16 rounded-lg overflow-hidden flex-shrink-0">
          <S3Image
            src={investment.image}
            alt={investment.title}
            className="w-full h-full object-cover"
            fallback={
              <div className="w-full h-full bg-gray-100 flex items-center justify-center">
                <span className="text-gray-400">No Image</span>
              </div>
            }
          />
        </div>
        <div>
          <h4 className="font-medium">{investment.title}</h4>
          <p className="text-sm text-gray-600">{locationString}</p>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 mt-4">
        <div>
          <div className="flex items-center gap-1 text-sm text-gray-600 mb-1">
            <DollarSign className="w-4 h-4" />
            <span>Value</span>
          </div>
          <div className="font-semibold">${investment.currentValue.toLocaleString()}</div>
        </div>
        <div>
          <div className="flex items-center gap-1 text-sm text-gray-600 mb-1">
            <MapPin className="w-4 h-4" />
            <span>Allocation</span>
          </div>
          <div className="font-semibold">{investment.allocation}%</div>
        </div>
        <div>
          <div className="flex items-center gap-1 text-sm text-gray-600 mb-1">
            <TrendingUp className="w-4 h-4" />
            <span>Return</span>
          </div>
          <div className="font-semibold text-green-600">+{investment.returnToDate}%</div>
        </div>
      </div>
    </div>
  );
}