import api from './axios';
import type { ApiResponse } from '@/types/response';

interface ReferralStats {
  referralCode: string;
  total: number;
  completed: number;
  pending: number;
  totalRewards: number;
  recentReferrals: Array<{
    id: string;
    name: string;
    date: string;
    status: 'pending' | 'completed' | 'expired';
    reward: number;
  }>;
}

export const referralService = {
  async getReferralHistory() {
    try {
      const { data } = await api.get<ApiResponse<ReferralStats>>('/referrals/history');
      return data;
    } catch (error) {
      console.error('Get referral history error:', error);
      return {
        success: false,
        message: error instanceof Error ? error.message : 'Failed to load referral data'
      };
    }
  },

  async applyReferralCode(code: string) {
    try {
      const { data } = await api.post<ApiResponse<void>>('/referrals/apply', { code });
      return data;
    } catch (error) {
      console.error('Apply referral code error:', error);
      throw error;
    }
  },

  async trackReferralSignup(referralCode: string, userId: string) {
    try {
      const { data } = await api.post<ApiResponse<void>>('/referrals/track-signup', {
        referralCode,
        userId
      });
      return data;
    } catch (error) {
      // Handle 401/404 errors gracefully without breaking the signup flow
      console.error('Track referral signup error:', error);
      return {
        success: false,
        message: 'Failed to track referral'
      };
    }
  }
};