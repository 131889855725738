import { useAtom } from 'jotai';
import { watchlistAtom } from '../store';
import { Property } from '../types';
import { useEffect } from 'react';

const WATCHLIST_KEY = 'property_watchlist';

export function useWatchlist() {
  const [watchlist, setWatchlist] = useAtom(watchlistAtom);

  useEffect(() => {
    const saved = localStorage.getItem(WATCHLIST_KEY);
    if (saved) {
      setWatchlist(JSON.parse(saved));
    }
  }, [setWatchlist]);

  useEffect(() => {
    if (watchlist.length > 0) {
      localStorage.setItem(WATCHLIST_KEY, JSON.stringify(watchlist));
    }
  }, [watchlist]);

  const isWatchlisted = (propertyId: string) => {
    return watchlist.some(item => item.id === propertyId);
  };

  const toggleWatchlist = (property: Property) => {
    setWatchlist(current => {
      if (isWatchlisted(property.id)) {
        const updated = current.filter(item => item.id !== property.id);
        localStorage.setItem(WATCHLIST_KEY, JSON.stringify(updated));
        return updated;
      }
      const updated = [...current, property];
      localStorage.setItem(WATCHLIST_KEY, JSON.stringify(updated));
      return updated;
    });
  };

  return {
    watchlist,
    isWatchlisted,
    toggleWatchlist
  };
}