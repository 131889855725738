import { loginService } from './auth/login';
import { signupService } from './auth/signup';
import { verificationService } from './auth/verification';
import { passwordService } from './auth/password';
import type { AuthServiceType } from './auth/types';

// Export a combined service with all methods
export const authService: AuthServiceType = {
  // Auth methods
  login: loginService.login,
  signup: signupService.signup,
  
  // Verification methods 
  verifyOTP: verificationService.verifyOTP,
  resendOTP: verificationService.resendOTP,
  
  // Password methods
  forgotPassword: passwordService.forgotPassword,
  resetPassword: passwordService.resetPassword,
  changePassword: passwordService.changePassword,
  
  // Logout utility
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('verificationEmail');
  }
};

// Export types
export * from './auth/types';