import { adminApi } from '../client';
import type { ApiResponse } from '@/types/response';
import type { Property } from '@/types/property';
import type { Investment } from '@/types/investment';

export const propertyService = {
  async getProperties() {
    try {
      const { data } = await adminApi.get<ApiResponse<Property[]>>('/properties');
      return {
        success: true,
        data: data.data || [] // Return empty array if no data
      };
    } catch (error) {
      console.error('Get properties error:', error);
      throw error;
    }
  },  

  async getProperty(id: string) {
    try {
      const { data } = await adminApi.get<ApiResponse<Property>>(`/properties/${id}`);
      return {
        success: true,
        data: data.data
      };
    } catch (error) {
      console.error('Get property error:', error);
      throw error;
    }
  },

  async createProperty(propertyData: FormData) {
    try {
      const { data } = await adminApi.post<ApiResponse<Property>>('/properties', propertyData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return {
        success: true,
        data: data.data
      };
    } catch (error) {
      console.error('Create property error:', error);
      throw error;
    }
  },

  async updateProperty(id: string, propertyData: FormData) {
    try {
      const { data } = await adminApi.put<ApiResponse<Property>>(`/properties/${id}`, propertyData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return {
        success: true,
        data: data.data
      };
    } catch (error) {
      console.error('Update property error:', error);
      throw error;
    }
  },

  async deleteProperty(id: string) {
    try {
      await adminApi.delete<ApiResponse<void>>(`/properties/${id}`);
      return {
        success: true,
        message: 'Property deleted successfully'
      };
    } catch (error) {
      console.error('Delete property error:', error);
      throw error;
    }
  },

  async getPropertyInvestments(id: string) {
    try {
      const { data } = await adminApi.get<ApiResponse<Investment[]>>(`/properties/${id}/investments`);
      return {
        success: true,
        data: data.data || []
      };
    } catch (error) {
      console.error('Get property investments error:', error);
      throw error;
    }
  }
};