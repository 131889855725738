import React from 'react';
import { MapPin } from 'lucide-react';

interface PropertyMapProps {
  latitude: number;
  longitude: number;
  address: string;
}

export function PropertyMap({ latitude, longitude, address }: PropertyMapProps) {
  const googleMapsUrl = `https://www.google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_KEY&q=${latitude},${longitude}&zoom=15`;

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <div className="flex items-center gap-2 mb-6">
        <MapPin className="w-6 h-6 text-primary-600" />
        <h3 className="text-xl font-semibold">Location</h3>
      </div>
      
      <div className="aspect-video w-full rounded-lg overflow-hidden bg-gray-100">
        <div className="w-full h-full flex items-center justify-center">
          <div className="text-center p-8">
            <MapPin className="w-8 h-8 text-primary-600 mx-auto mb-4" />
            <p className="text-gray-600">{address}</p>
            <a 
              href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center gap-2 mt-4 text-primary-600 hover:text-primary-700"
            >
              View on Google Maps
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
              </svg>
            </a>
          </div>
        </div>
      </div>
      
      <div className="mt-4 p-4 bg-gray-50 rounded-lg">
        <div className="flex items-start gap-3">
          <MapPin className="w-5 h-5 text-primary-600 mt-1" />
          <div>
            <h4 className="font-medium mb-1">Property Address</h4>
            <p className="text-gray-600">{address}</p>
          </div>
        </div>
      </div>
    </div>
  );
}