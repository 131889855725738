import api from '../axios';
import type { SignupData, AuthResponse } from './types';

export const signupService = {
  async signup(userData: SignupData): Promise<AuthResponse> {
    try {
      const response = await api.post<AuthResponse>('/auth/register', userData);
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Registration failed');
      }

      // Store email for verification
      localStorage.setItem('verificationEmail', userData.email);

      return {
        success: true,
        message: 'Registration successful. Please verify your email.'
      };
    } catch (error: any) {
      console.error('Signup error:', error);
      if (error.response?.status === 409) {
        throw new Error('Email already registered');
      }
      throw new Error(error.response?.data?.message || 'Registration failed');
    }
  }
};