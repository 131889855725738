import React from 'react';
import { Clock, Check, Clock4 } from 'lucide-react';

interface Referral {
  id: string;
  name: string;
  date: string;
  status: 'pending' | 'completed' | 'expired';
  reward: number;
}

interface RecentReferralsProps {
  referrals: Referral[];
}

export function RecentReferrals({ referrals }: RecentReferralsProps) {
  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'completed':
        return <Check className="w-4 h-4 text-green-500" />;
      case 'pending':
        return <Clock className="w-4 h-4 text-amber-500" />;
      default:
        return <Clock4 className="w-4 h-4 text-gray-400" />;
    }
  };

  const getStatusClass = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-green-50 text-green-700';
      case 'pending':
        return 'bg-amber-50 text-amber-700';
      default:
        return 'bg-gray-50 text-gray-700';
    }
  };

  return (
    <div className="bg-white rounded-lg border border-gray-200">
      <div className="p-4 border-b border-gray-200">
        <h3 className="font-medium">Recent Referrals</h3>
      </div>
      <div className="divide-y divide-gray-200">
        {referrals.length > 0 ? (
          referrals.map((referral) => (
            <div key={referral.id} className="p-4 flex items-center justify-between">
              <div>
                <div className="font-medium">{referral.name}</div>
                <div className="text-sm text-gray-500">
                  {new Date(referral.date).toLocaleDateString()}
                </div>
              </div>
              <div className="flex items-center gap-4">
                <div className="text-right">
                  <div className="font-medium">${referral.reward}</div>
                  <div className={`inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium ${getStatusClass(referral.status)}`}>
                    {getStatusIcon(referral.status)}
                    {referral.status.charAt(0).toUpperCase() + referral.status.slice(1)}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="p-8 text-center text-gray-500">
            No referrals yet
          </div>
        )}
      </div>
    </div>
  );
}