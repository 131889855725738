import React from 'react';
import { Building2 } from 'lucide-react';

interface PropertyBasicInfoProps {
  formData: any;
  onChange: (data: any) => void;
  errors?: Record<string, string>;
}

export function PropertyBasicInfo({ formData, onChange, errors }: PropertyBasicInfoProps) {
  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <Building2 className="w-5 h-5 text-primary-600" />
        Basic Information
      </h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Property Title*
          </label>
          <input
            type="text"
            value={formData.title}
            onChange={(e) => onChange({ ...formData, title: e.target.value })}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.title ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            placeholder="Enter property title"
            required
          />
          {errors?.title && (
            <p className="mt-1 text-sm text-red-600">{errors.title}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Summary*
          </label>
          <textarea
            value={formData.description?.summary}
            onChange={(e) => onChange({
              ...formData,
              description: { ...formData.description, summary: e.target.value }
            })}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.['description.summary'] ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            rows={2}
            placeholder="Brief property description"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Full Description*
          </label>
          <textarea
            value={formData.description?.full}
            onChange={(e) => onChange({
              ...formData,
              description: { ...formData.description, full: e.target.value }
            })}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.['description.full'] ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            rows={4}
            placeholder="Detailed property description"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Property Type*
          </label>
          <select
            value={formData.type}
            onChange={(e) => onChange({ ...formData, type: e.target.value })}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.type ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            required
          >
            <option value="">Select property type</option>
            <option value="residential">Residential</option>
            <option value="commercial">Commercial</option>
            <option value="mixed_use">Mixed Use</option>
            <option value="industrial">Industrial</option>
          </select>
          {errors?.type && (
            <p className="mt-1 text-sm text-red-600">{errors.type}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status*
          </label>
          <select
            value={formData.status}
            onChange={(e) => onChange({ ...formData, status: e.target.value })}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors?.status ? 'border-red-300' : 'border-gray-200'
            } focus:ring-2 focus:ring-primary-500 focus:border-primary-500`}
            required
          >
            <option value="">Select status</option>
            <option value="active">Active</option>
            <option value="pending">Pending</option>
            <option value="closed">Closed</option>
          </select>
          {errors?.status && (
            <p className="mt-1 text-sm text-red-600">{errors.status}</p>
          )}
        </div>
      </div>
    </div>
  );
}