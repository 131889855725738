import { ethers } from 'ethers';
import { getChainConfig } from '@/config/web3';

const PRICE_CACHE_DURATION = 60000; // 1 minute
const priceCache = new Map<string, { price: number; timestamp: number }>();

export async function getTokenPrice(chainId: number): Promise<number> {
  try {
    const chain = getChainConfig(chainId);
    const cacheKey = chain.symbol;
    const cached = priceCache.get(cacheKey);

    if (cached && Date.now() - cached.timestamp < PRICE_CACHE_DURATION) {
      return cached.price;
    }

    const response = await fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${chain.coingeckoId}&vs_currencies=usd`
    );

    if (!response.ok) {
      throw new Error('Failed to fetch token price');
    }

    const data = await response.json();
    const price = data[chain.coingeckoId]?.usd;

    if (!price) {
      throw new Error('Invalid price data received');
    }

    priceCache.set(cacheKey, {
      price,
      timestamp: Date.now()
    });

    return price;
  } catch (error) {
    console.error('Failed to fetch token price:', error);
    throw new Error('Failed to get token price');
  }
}

export async function convertUsdToTokens(usdAmount: number, chainId: number): Promise<string> {
  try {
    const tokenPrice = await getTokenPrice(chainId);
    if (!tokenPrice) {
      throw new Error('Invalid token price');
    }

    const tokenAmount = usdAmount / tokenPrice;
    if (isNaN(tokenAmount) || tokenAmount <= 0) {
      throw new Error('Invalid token amount calculated');
    }

    // Convert to Wei/smallest unit with 18 decimals precision
    return ethers.utils.parseUnits(tokenAmount.toFixed(18), 18).toString();
  } catch (error) {
    console.error('Convert USD to tokens error:', error);
    throw new Error('Failed to calculate token amount');
  }
}

export async function checkWalletBalance(
  walletAddress: string,
  requiredUsdAmount: number,
  chainId: number
): Promise<boolean> {
  try {
    if (!window.ethereum) {
      throw new Error('No crypto wallet found');
    }

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const balance = await provider.getBalance(walletAddress);
    const tokenPrice = await getTokenPrice(chainId);

    if (!tokenPrice) {
      throw new Error('Failed to get token price');
    }

    const balanceInUsd = parseFloat(ethers.utils.formatEther(balance)) * tokenPrice;
    return balanceInUsd >= requiredUsdAmount;
  } catch (error) {
    console.error('Check wallet balance error:', error);
    throw error;
  }
}