import api from './axios';
import type { ApiResponse } from '@/types/response';

interface SupportRequest {
  type: 'live_chat' | 'phone_call' | 'video_call';
  agentId?: string;
  preferredTime?: string;
  message: string;
  contactMethod: 'email' | 'phone';
}

export const supportService = {
  async submitRequest(request: SupportRequest): Promise<ApiResponse<void>> {
    try {
      const { data } = await api.post<ApiResponse<void>>('/support/request', request);
      return data;
    } catch (error) {
      console.error('Submit support request error:', error);
      throw error;
    }
  }
};