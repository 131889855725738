import { adminApi } from '../client';
import type { ApiResponse } from '@/types/response';
import type { User } from '@/types/user';

export const userService = {
  async getUsers() {
    try {
      const { data } = await adminApi.get<ApiResponse<User[]>>('/users');
      if (!data.success) {
        throw new Error(data.message || 'Failed to fetch users');
      }
      return data;
    } catch (error) {
      console.error('Get users error:', error);
      throw error;
    }
  },

  async getUser(id: string) {
    try {
      const { data } = await adminApi.get<ApiResponse<User>>(`/users/${id}`);
      if (!data.success) {
        throw new Error(data.message || 'Failed to fetch user');
      }
      return data;
    } catch (error) {
      console.error('Get user error:', error);
      throw error;
    }
  },

  async updateUser(userId: string, userData: Partial<User>) {
    try {
      const { data } = await adminApi.put<ApiResponse<User>>(`/users/${userId}`, userData);
      if (!data.success) {
        throw new Error(data.message || 'Failed to update user');
      }
      return data;
    } catch (error) {
      console.error('Update user error:', error);
      throw error;
    }
  }
};