import React, { useEffect } from 'react';
import { Upload, X, Image as ImageIcon } from 'lucide-react';
import { S3Image } from '@/components/S3Image';
import toast from 'react-hot-toast';

interface PropertyImagesProps {
  images: File[];
  imageUrls: string[];
  onImagesChange: (images: File[]) => void;
  errors?: Record<string, string>;
}

export function PropertyImages({ images, imageUrls, onImagesChange, errors }: PropertyImagesProps) {
  // Create object URLs for preview of new images
  const previewUrls = images.map(file => URL.createObjectURL(file));
  
  // Cleanup object URLs on unmount or when images change
  useEffect(() => {
    return () => {
      previewUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [previewUrls]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    
    // Validate files
    const validFiles = files.filter(file => {
      const isValidType = ['image/jpeg', 'image/png', 'image/gif'].includes(file.type);
      const isValidSize = file.size <= 5 * 1024 * 1024; // 5MB
      
      if (!isValidType) {
        toast.error(`Invalid file type: ${file.name}. Only JPEG, PNG and GIF are allowed.`);
        return false;
      }
      if (!isValidSize) {
        toast.error(`File too large: ${file.name}. Maximum size is 5MB.`);
        return false;
      }
      
      return true;
    });

    if (validFiles.length > 0) {
      onImagesChange([...images, ...validFiles]);
    }
  };

  const removeImage = (index: number) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    onImagesChange(newImages);
  };

  const removeExistingImage = (index: number) => {
    const newUrls = [...imageUrls];
    newUrls.splice(index, 1);
    // Update parent component with new URLs
    // This would typically update the formData.images array
  };

  return (
    <div className="bg-white p-6 rounded-xl border border-gray-200">
      <h2 className="text-lg font-semibold mb-4 flex items-center gap-2">
        <ImageIcon className="w-5 h-5 text-primary-600" />
        Property Images
      </h2>
      <div className="space-y-4">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {/* Existing S3 Images */}
          {imageUrls.map((url, index) => (
            <div key={`existing-${index}`} className="relative aspect-video">
              <S3Image
                src={url}
                alt={`Property ${index + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                type="button"
                onClick={() => removeExistingImage(index)}
                className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ))}

          {/* New Image Previews */}
          {previewUrls.map((url, index) => (
            <div key={`new-${index}`} className="relative aspect-video">
              <img
                src={url}
                alt={`New Property ${index + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
              <button
                type="button"
                onClick={() => removeImage(index)}
                className="absolute top-2 right-2 p-1 bg-red-600 text-white rounded-full hover:bg-red-700"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ))}

          {/* Upload Button */}
          <label className="flex flex-col items-center justify-center aspect-video border-2 border-dashed border-gray-300 rounded-lg hover:border-primary-500 cursor-pointer transition-colors">
            <Upload className="w-8 h-8 text-gray-400 mb-2" />
            <span className="text-sm text-gray-600">Add Image</span>
            <input
              type="file"
              accept="image/jpeg,image/png,image/gif"
              multiple
              onChange={handleImageUpload}
              className="hidden"
            />
          </label>
        </div>

        {errors?.images && (
          <p className="text-sm text-red-600">{errors.images}</p>
        )}
        
        <div className="text-sm text-gray-500 space-y-1">
          <p>Supported formats: JPG, PNG, GIF</p>
          <p>Maximum file size: 5MB per image</p>
          <p>Recommended dimensions: 1200x800 pixels</p>
        </div>
      </div>
    </div>
  );
}