import React from 'react';
import { Link } from 'react-router-dom';
import { Plus } from 'lucide-react';

export function DashboardHeader() {
  return (
    <div className="flex justify-between items-center mb-6">
      <h2 className="text-2xl font-bold">Your Dashboard</h2>
      <Link
        to="/properties"
        className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
      >
        <Plus className="w-5 h-5" />
        Browse Properties
      </Link>
    </div>
  );
}