import React from 'react';
import { supportedChains } from '@/config/web3';

interface NetworkSelectorProps {
  selectedChain: number;
  onChange: (chainId: number) => void;
  disabled?: boolean;
}

export function NetworkSelector({
  selectedChain,
  onChange,
  disabled = false
}: NetworkSelectorProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        Select Network
      </label>
      <select
        value={selectedChain}
        onChange={(e) => onChange(Number(e.target.value))}
        disabled={disabled}
        className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {Object.values(supportedChains).map((chain) => (
          <option key={chain.id} value={chain.id}>
            {chain.name}
          </option>
        ))}
      </select>
    </div>
  );
}