import React, { useState } from 'react';
import { Copy, Check } from 'lucide-react';
import toast from 'react-hot-toast';

interface ReferralCodeProps {
  code: string;
}

export function ReferralCode({ code }: ReferralCodeProps) {
  const [copied, setCopied] = useState(false);

  const handleCopyCode = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      toast.success('Referral code copied!');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy code');
    }
  };

  return (
    <div className="bg-primary-50 p-6 rounded-lg">
      <div className="flex items-center justify-between mb-4">
        <div>
          <div className="font-medium text-primary-900">Your Referral Code</div>
          <div className="text-sm text-primary-700">Share this code with friends</div>
        </div>
        <button
          onClick={handleCopyCode}
          className="inline-flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
        >
          {copied ? (
            <>
              <Check className="w-4 h-4" />
              Copied!
            </>
          ) : (
            <>
              <Copy className="w-4 h-4" />
              Copy Code
            </>
          )}
        </button>
      </div>
      <div className="flex items-center justify-center p-4 bg-white rounded-lg border-2 border-primary-200 font-mono text-xl">
        {code}
      </div>
    </div>
  );
}