import api from './axios';
import type { UserProfile, UserPreferences } from '@/types/user';
import type { ApiResponse } from '@/types/response';

export const userService = {
  async getProfile() {
    try {
      const { data } = await api.get<ApiResponse<UserProfile>>('/users/profile');
      return data;
    } catch (error) {
      console.error('Get profile error:', error);
      throw error;
    }
  },

  async updateProfile(profileData: Partial<UserProfile>) {
    try {
      const { data } = await api.put<ApiResponse<UserProfile>>('/users/profile', profileData);
      return data;
    } catch (error) {
      console.error('Update profile error:', error);
      throw error;
    }
  },

  async updateAvatar(formData: FormData) {
    try {
      const { data } = await api.put<ApiResponse<{ avatar: string }>>('/users/profile/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data;
    } catch (error) {
      console.error('Update avatar error:', error);
      throw error;
    }
  },

  async updatePassword(currentPassword: string, newPassword: string) {
    try {
      const { data } = await api.put<ApiResponse<void>>('/users/password', {
        currentPassword,
        newPassword
      });
      return data;
    } catch (error) {
      console.error('Update password error:', error);
      throw error;
    }
  },

  async updatePreferences(preferences: Partial<UserPreferences>) {
    try {
      const { data } = await api.put<ApiResponse<UserPreferences>>('/settings/preferences', preferences);
      return data;
    } catch (error) {
      console.error('Update preferences error:', error);
      throw error;
    }
  }
};