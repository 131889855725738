import React, { useState, useEffect } from 'react';
import { milestoneService } from '@/services/api/milestones';
import { MilestoneCard } from './milestones/MilestoneCard';
import { investmentService } from '@/services/api/investments';
import {
  calculateInvestmentValue,
  calculatePortfolioReturns,
  calculateInvestmentDuration,
  calculateInvestmentDiversity,
  calculateMilestoneProgress
} from '@/utils/milestone-calculations';
import toast from 'react-hot-toast';
import type { MilestoneCategory } from '@/types/milestone';
import type { Investment } from '@/types/investment';

type CategoryCalculation = {
  [K in MilestoneCategory['category']]: (investments: Investment[]) => number;
};

const categoryCalculations: CategoryCalculation = {
  investment_value: calculateInvestmentValue,
  portfolio_returns: calculatePortfolioReturns,
  investment_duration: calculateInvestmentDuration,
  investment_diversity: calculateInvestmentDiversity
};

export function MilestonesSection() {
  const [milestones, setMilestones] = useState<MilestoneCategory[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [milestonesResponse, investmentsResponse] = await Promise.all([
          milestoneService.getMilestones(),
          investmentService.getInvestments()
        ]);

        if (!milestonesResponse.success || !milestonesResponse.data) {
          throw new Error('Failed to fetch milestones data');
        }

        if (!investmentsResponse.success || !investmentsResponse.data) {
          throw new Error('Failed to fetch investments data');
        }

        const investments = investmentsResponse.data;
        
        // Calculate current values based on actual investment data
        const updatedMilestones = milestonesResponse.data.map(category => {
          const calculator = categoryCalculations[category.category];
          if (!calculator) {
            console.error(`No calculator found for category: ${category.category}`);
            return category;
          }

          const currentValue = calculator(investments);
          
          // Calculate progress using the new helper function
          const progress = calculateMilestoneProgress(currentValue, category.milestones);
          
          // Find next milestone
          const sortedMilestones = [...category.milestones].sort((a, b) => a.value - b.value);
          const nextMilestone = sortedMilestones.find(m => m.value > currentValue)?.value || null;

          // Update milestone reached status
          const updatedMilestones = sortedMilestones.map(milestone => ({
            ...milestone,
            reached: currentValue >= milestone.value
          }));

          return {
            ...category,
            currentValue,
            nextMilestone,
            progress,
            milestones: updatedMilestones
          };
        });

        setMilestones(updatedMilestones);
      } catch (error) {
        console.error('Failed to fetch milestone data:', error);
        toast.error('Failed to load milestones');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return (
      <div className="p-6">
        <div className="animate-pulse space-y-8">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="h-48 bg-gray-200 rounded-xl"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Investment Milestones</h3>
        <p className="text-gray-600">
          Track your investment journey and unlock rewards at each milestone.
        </p>
      </div>

      <div className="space-y-8">
        {milestones.map((category) => (
          <MilestoneCard 
            key={category.category} 
            category={category}
          />
        ))}
      </div>
    </div>
  );
}