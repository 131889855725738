import React from 'react';
import { Camera, FileText } from 'lucide-react';

interface DocumentUploadProps {
  id: string;
  label: string;
  description: string;
  file?: File;
  preview?: string;
  isPDF?: boolean;
  onChange: (file: File) => void;
  icon?: 'camera' | 'file';
}

export function DocumentUpload({
  id,
  label,
  description,
  file,
  preview,
  isPDF,
  onChange,
  icon = 'camera'
}: DocumentUploadProps) {
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 mb-2">
        {label}
      </label>
      <div className="relative">
        <input
          type="file"
          accept="image/jpeg,image/png,image/jpg,application/pdf"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) onChange(file);
          }}
          className="hidden"
          id={id}
        />
        <label
          htmlFor={id}
          className={`flex flex-col items-center justify-center p-6 border-2 ${
            file ? 'border-primary-200 bg-primary-50' : 'border-dashed border-gray-300'
          } rounded-lg hover:border-primary-500 cursor-pointer transition-colors`}
        >
          {file ? (
            isPDF ? (
              <div className="flex flex-col items-center">
                <FileText className="w-12 h-12 text-primary-600 mb-2" />
                <div className="flex flex-col items-center">
                  <span className="text-sm font-medium text-gray-900">{file.name}</span>
                  <span className="text-xs text-gray-500 mt-1">
                    {(file.size / (1024 * 1024)).toFixed(2)} MB
                  </span>
                </div>
              </div>
            ) : (
              preview && (
                <div className="relative w-full">
                  <img
                    src={preview}
                    alt={`${label} Preview`}
                    className="max-h-48 mx-auto object-contain rounded-lg"
                  />
                  <div className="absolute inset-0 bg-black/5 pointer-events-none rounded-lg"></div>
                </div>
              )
            )
          ) : (
            <>
              {icon === 'camera' ? (
                <Camera className="w-8 h-8 text-gray-400 mb-2" />
              ) : (
                <FileText className="w-8 h-8 text-gray-400 mb-2" />
              )}
              <div className="text-center">
                <span className="text-sm font-medium text-gray-700">Upload {label}</span>
                <p className="text-xs text-gray-500 mt-1">{description}</p>
                <p className="text-xs text-gray-400 mt-2">
                  JPG, PNG, or PDF up to 5MB
                </p>
              </div>
            </>
          )}
        </label>
      </div>
    </div>
  );
}